import PropTypes from "prop-types";
import React from "react";
import TaMap from "../../containers/TravelAdvisory/TaMap";
import CountryFilter from "./countryFilter";
import CountryList from "./countryList";
// import TravelAdvisoryInfo from './travelAdvisoryInfo';
// import TravelAdvisoryWhererTravelInfo from './travelAdvisoryWhereTravelInfo';
import TravelAdvisoryPopup from "./travelAdvisoryPopup";
import LoadingIcon from "../../components/common/loadingIcon";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom"
import config from "../../config/index";
import { withRouter } from 'react-router-dom';
import UserLayoutComponent from '../../../src/components/activeTravelers/TravelAdvisorUserDetailsLayout';

const headerHeight = config.layout.headerHeight;

export class TravelAdvisoryRoute extends React.Component {
  static propTypes = {
    appSettingsDimensions: PropTypes.object.isRequired,
    activeCountry: PropTypes.object,
    countryTravelers: PropTypes.object.isRequired,
    countries: PropTypes.array.isRequired,
    filteredCountries: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    filterCountries: PropTypes.func.isRequired,
    setActiveCountry: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.state = {
      open: false,
      filterString: null,
    };
  }

  onclose = () => {
    this.props.history.push('/travel-advisories/')
  }

  render() {
    // return null in case if we don't have window height
    if (!this.props.appSettingsDimensions.height) return null;
    const childProps = {
      ...this.props
    };
    // set height style for components
    // Commented to remove the dynamic height
    const containerStyle = {
      // height: this.props.appSettingsDimensions.height - headerHeight
    };
    let loadTA = null;

    let userLayout = false;
    console.log(this.props.match);
    if (this.props.match.path.includes("/travelerad/:id")) {
      userLayout = true;
    }



    return (



      <Switch>
        <Route exact path='/travel-advisories/:country' render={(props) => <TravelAdvisoryPopup  country={this.props.activeCountry} onClose={this.onclose} user={this.props.currentUser} /> } />
        <Route  path='/travel-advisories' render={(props) => <TaMap onCountrySelect={this.props.showCountry} />} />
        <Route  path='/travelerad/:id' render={(props) => <UserLayoutComponent uid={this.props.match.params.id} />} />
      </Switch>


    );
  }
}
export default withRouter(TravelAdvisoryRoute);
