import PropTypes from 'prop-types';
import React from 'react';
import Select from '../../components/common/select/Select';
// import find from 'lodash/find';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import { sprintf } from '../../i18n/utils';
import { isCompanyAdminOrUser } from '../../helpers/user';
import { CollapseHolder, CollapseOpener, CollapseBlock } from '../../components/common/collapse';
import $, { type } from 'jquery';
import { sessionStorage } from '../../helpers/localStorage';
import 'react-datepicker/dist/react-datepicker.css';
import SettingsView from '../../views/SettingsView/SettingsView';
import FiltersActive from '../../styles/images/FiltersActive.svg';
import Filter from '../../styles/images/Filter.svg';
import Close from '../../styles/images/Close.svg';
import HamburgerIcon from '../../styles/images/HamburgerIcon.svg';
import Restart from '../../styles/images/Reset-Filters.svg';
import Draggable from 'react-draggable';
import '../../styles/_filterBlock.scss';
import alarm from '../../styles/images/Status-Red.svg';
import warning from '../../styles/images/Status-Yellow.svg';
import ok from '../../styles/images/Status-Green.svg';
import '../../styles/_flight_board_filter.scss';
import { isThisQuarter } from 'date-fns';

export class TravelersFilterBlock extends React.Component {
  static contextTypes = {
    i18n: PropTypes.object
  };

  static propTypes = {
    travelers: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
    filteredTravelers: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
    companyList: PropTypes.array.isRequired,
    userRole: PropTypes.string.isRequired,
    costCenterList: PropTypes.array,
    carrierList: PropTypes.array,
    airportList: PropTypes.array,
    flightStatusList: PropTypes.array,
    setTravelersFilter: PropTypes.func.isRequired,
    travelersFilter: PropTypes.object.isRequired,
    clearTravelersFilter: PropTypes.func.isRequired,
    hideStatusFilter: PropTypes.any,
    title: PropTypes.any,
    exportToFile: PropTypes.func
  };

  constructor (props) {
    super(props);
    this.state = {
      isActive: false,
      vip: false,
      alarm: false,
    };
    this._vipRef = React.createRef();
    this._alarmRef = React.createRef();
    this._warningRef = React.createRef();
    this._okRef = React.createRef();
    this._nonHelpedRef = React.createRef();
    this._dateRangeTypeOptions = ['Custom'];
    this._selectDefault = [];
    this._dateRangeType = this.props.travelersFilter.dateRangeType;
    this._dateRangeStart = this.props.travelersFilter.dateRangeStart;
    this._dateRangeEnd = this.props.travelersFilter.dateRangeEnd;
    this._company = this.props.travelersFilter.company;
    this._carrier = this.props.travelersFilter.carrier;
    this._airport = this.props.travelersFilter.airport;
    this._fstatus = this.props.travelersFilter.fstatus;
    this._costcenter_id = this.props.travelersFilter.costcenter_id;
    let status = this.props.travelersFilter.status;
    this._currentStatus = Object.keys(status).filter( (key) => status[key]).join(',');
    this._additionalFilter = '';
    if (this.props.travelersFilter.vip) this._additionalFilter = 'vip';
  }

  clear = (e) => {
    e.preventDefault();
    this.props.clearTravelersFilter();
    this._company = this._selectDefault;
    this._carrier = this._selectDefault;
    this._airport = this._selectDefault;
    this._fstatus = this._selectDefault;
    this._costcenter_id = this._selectDefault;
    this._additionalFilter = this._selectDefault;
    this._currentStatus = 'alarm,warning,ok';
  }
  selectChange = (name) => {
    console.log(name)
    return (selected) => {
      if (Object.prototype.hasOwnProperty.call(selected, 'value')) {
        this['_' + name] = selected.value;
      } else if (selected) {
        this['_' + name] = selected;
      } else {
        this['_' + name] = [];
      }
      setTimeout(this.setFilter);
    };
  }


  setFilter = (e) => {
    const filters = { ...this.props.travelersFilter };
    console.log(filters)
    //Setting status
    let status = filters.status;
    Object.keys(status).forEach( (key) => status[key] = false);

    if (this._currentStatus.length) {
      let statuses = this._currentStatus.split(',');
      statuses.forEach( (status) => filters.status[status] = true);
    }
    //Setting VIP
    if (this._additionalFilter === 'vip') {
      filters.vip = true;
    } else {
      filters.vip = false;
    }
    // filters.vip = this._additionalFilters ? true : false;
    // console.log(this._additionalFilters,filters.vip)
    //Setting Company
    if (this._company.length) {
      filters.company = this._company.split(',');
    } else {
      filters.company = [];
    }
    //Setting CostCenterId
    if (this._costcenter_id.length) {
      filters.costcenter_id = this._costcenter_id.split(',');
    } else {
      filters.costcenter_id = [];
    }
    this.props.setTravelersFilter(filters);
  };

  statusChange = (name) => {
    return (value) => {
      this['_' + name] = value;
      console.log(value);
      setTimeout(this.setFilter);
    }
  }
  callChildFunction = () => {
    this.setState({isActive:!this.state.isActive});
    this.child.close();
  }
  render () {
    const { l, ngettext } = this.context.i18n;
    const {
      travelers, filteredTravelers, companyList,
      carrierList, airportList, travelersFilter, userRole,perPage,
      hideStatusFilter, title,costCenterList
    } = this.props;

    const { dateRangeType, company, carrier, airport, status, currentStatus } = travelersFilter;

    // this._currentStatus = Object.keys(status).filter( (key) => status[key]).join(',');
    this._company = company.join(',');
    // this._additionalFilters = travelersFilter.vip ? 'vip' : [];

    const flightStatus = [
      {
        classValue: 'alarm',
        label: 'Red',
        value: 'alarm'
      },
      {
        classValue: 'warning',
        label: 'Yellow',
        value: 'warning'
      },
      {
        classValue: 'ok',
        label: 'Green',
        value: 'ok'
      }
    ];
    let sortIDSelect = null;
    if(isCompanyAdminOrUser(this.props.userRole)){
      sortIDSelect = (
        <Select
          value={this._costcenter_id}
          options={costCenterList}
          searchable
          multi
          simpleValue
          clearable={false}
          className='disruption-block-select'
          placeholder={l('All Sort Level IDs')}
          onChange={this.selectChange('costcenter_id')}
          arrowRenderer = {() => (<i className="fa fa-angle-down fa-lg" aria-hidden="true"></i> )}
          optionRenderer={({ label, classValue }) => <div className="Dicon"><i className={'icon ' + classValue} /><div className="Dlabel">{label}</div></div>}/>
      )
    }
    let disruptionStatus = null;
    if (status) {
      disruptionStatus = (<Select
        clearable={false}
        value={this._currentStatus}
        options={flightStatus}
        onSelectAll = {"All Disruption"}
        searchable
        multi
        simpleValue
        className='disruption-block-select'
        placeholder={l('All Disruption')}
        arrowRenderer = {() => (<i className="fa fa-angle-down fa-lg" aria-hidden="true"></i> )}
        optionRenderer={({ label, classValue }) => {
          if(classValue === 'ok'){
            return(<div className="Dicon">
              <img className='disruption-icon dis-align' src= {ok}/>
          <div className="Dlabel">Green</div></div>)
          }else
           if(classValue === 'warning'){
           return( <div className="Dicon">
              <img className='disruption-icon dis-align' src= {warning}/>
          <div className="Dlabel">Yellow</div></div>)
          }else{
           return (<div className="Dicon">
              <img className='disruption-icon dis-align' src= {alarm}/>
          <div className="Dlabel" style={{right:'10px'}}>Red</div></div>)
          }
        }}
        onChange={this.selectChange('currentStatus')} />)
    }
    let companySelect = null;
    if (!isCompanyAdminOrUser(userRole) && companyList && company) {
      companySelect = (
        <Select
          value={this._company}
          options={companyList}
          searchable
          multi
          simpleValue
          clearable={false}
          className='disruption-block-select'
          placeholder={l('All Companies')}
          onChange={this.selectChange('company')}
          arrowRenderer = {() => (<i className="fa fa-angle-down fa-lg" aria-hidden="true"></i> )}
          optionRenderer={({ label, classValue }) => <div className="Dicon"><i className={'icon ' + classValue} /><div className="Dlabel">{label}</div></div>}/>
      );
    }



    let additionalFiltersList = [
       {
        value: 'vip',
        label: 'VIPs Only'
      },
  ]
    let additionalFilters = null;
    if (additionalFiltersList) {
      additionalFilters = (
        <Select
          clearable
          value={this._additionalFilter}
          options={additionalFiltersList}
          className='disruption-block-select xs'
          searchable
          multi
          simpleValue
          placeholder={l('Additional Filters')}
          arrowRenderer = {() => (<i className="fa fa-angle-down fa-lg" aria-hidden="true"></i> )}
          optionRenderer={({ label, classValue }) => <div className="Dicon"><i className={'icon ' + classValue} /><div className="Dlabel">{label}</div></div>}
          onChange={this.selectChange('additionalFilter')}/>
      );
    }
    let info;
    if (travelers && filteredTravelers) {
      let trLength = filteredTravelers;
      let totalLength = travelers;
      if (typeof travelers === 'object') {
        trLength = filter(filteredTravelers, { status: 'active' }).length;
      }
      if (typeof travelers === 'object') {
        if (!$('#map-flights').hasClass('active')) {
          totalLength = filter(travelers, { status: 'active' }).length;
        } else {
          totalLength = sessionStorage.get('TravelerCount');
        }
      }
      if (title === 'flight') {
        const text = sprintf(ngettext(
          `%d flight of ${totalLength}`,
          `%d flights of ${totalLength}`,
          trLength
        ), trLength);
        info = totalLength ? text : null;
      } else {
        const text = sprintf(ngettext(
          `%d traveler of ${totalLength}`,
          `%d travelers of ${totalLength}`,
          trLength
        ), trLength);
        info = totalLength ? text : null;
      }
    }

    const showStatusFilter = !hideStatusFilter;

    return (
      <>

      <div className='filter-block'>
        <CollapseHolder ref={(cd) => this.child = cd} saveState='activeTravelersFilter' opener='opener' closeByOutsideClick='true'>
              <CollapseOpener>
            <div className='filter-head' onClick={()=>{this.setState({isActive:!this.state.isActive})}}>
              <div>
                <img src={FiltersActive} alt=''  className={`${this.state.isActive?'opener filter-shadow':'opener'}`}
                 />
                {/* <TfilterIcon style={{ color: 'white', backgroundColor: 'blue' }} className='opener'/> */}
                {/* <h3 className='opener'>{l('Filters')} <span className='arrow' /></h3> */}
              </div>
            </div>
            </CollapseOpener>
            <CollapseBlock>
              <Draggable handle='strong'>
              <div className='filter-body'>
              <>
              <div className='filter-header tr-filter'>
                <strong className='cursor' >
                    <img className='filter-drag' src={HamburgerIcon} alt="" width='16px' height='14.4px' />
                </strong>
                <div className='filter-heading'>
                  <p>Filters</p>
                </div>
                <a className='clear filter-close' href='#' onClick={()=>{!showStatusFilter}} >
                <img className='filter-close1' src={Close} alt='close' onClick={this.callChildFunction} />
                </a>
              </div>
              <hr className='filter-line1'/>
              <div className='clear-filter1'><span className='pointer' style={{height:'22px',paddingTop:"5px",paddingRight:"12px"}}  onClick={this.clear}>Clear Filters</span></div>

                  {/* <i className="fa fa-align-justify" style={{ paddingLeft: '60px', opacity: '0.2' }}></i> */}
              <div className='select-holder'>
               <div className='distruptions-block'>
                {disruptionStatus}
               </div>
               <div className='company-select-block'>
                 {sortIDSelect}
               </div>
               <div className='company-select-block'>
                 {companySelect}
               </div>
                <div className='additional-block'>
                  {additionalFilters}
                </div>
              </div>
              </>
            </div>
            </Draggable>
            </CollapseBlock>

      </CollapseHolder>
      </div>
      </>
    );
  }
}
export default TravelersFilterBlock;
