import PropTypes from "prop-types";
import React from "react";
export class CountryListItem extends React.Component {
  static contextTypes = {
    i18n: PropTypes.object
  };

  static propTypes = {
    showLetter: PropTypes.bool.isRequired,
    showCountry: PropTypes.func.isRequired,
    activeCountry: PropTypes.shape({
      country: PropTypes.string,
      travelers: PropTypes.array,
      level: PropTypes.string,
      url: PropTypes.string
    }).isRequired
  };

  onCountryClick = (event, country) => {
    console.log(country);
    this.props.setActiveCountry(country);
  };

  render() {
    let letter = "";
    const { showLetter, activeCountry } = this.props;

    if (showLetter) {
      letter = <em>{activeCountry.country.charAt(0).toUpperCase()}</em>;
    }

    return (
      <div
        className="country-item"
        onClick={event => this.props.showCountry(activeCountry.longCode)}
      >
        <div className="letter">{letter}</div>
        <div className="text">
          <strong className="name">{activeCountry.country}</strong>
          <em className={"level level-" + activeCountry.level}>
            {activeCountry.level}
          </em>
          <span
            className={`country-travelers ${
              !(activeCountry.travelers && activeCountry.travelers.length) ? "no-travelers" : ""
            }`}
          >
            {activeCountry.travelers && activeCountry.travelers.length? activeCountry.travelers.length: 0} <i className="fas fa-user-friends" style={{paddingLeft:"5px",fontSize:'1rem',color:'#595A60'}}></i>
          </span>
        </div>
      </div>
    );
  }
}
export default CountryListItem;
