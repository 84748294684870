import PropTypes from 'prop-types';
import React from 'react';

import CheckboxField from '../../components/forms/checkboxField';
import RadioButtonsGroup from '../../components/forms/radioButtonsGroup';

export default class NotificationsFields extends React.Component {
  static contextTypes = {
    i18n: PropTypes.object
  };

  static propTypes = {
    changeNotificationsProp: PropTypes.func.isRequired,
    toggleArrNotificationsValue: PropTypes.func.isRequired,
    toggleNotificationsValue: PropTypes.func.isRequired,
    assistedTravelers: PropTypes.bool.isRequired,
    newTravelersAll: PropTypes.bool.isRequired,
    newTravelersVIP: PropTypes.bool.isRequired,
    airportStatusChange: PropTypes.bool.isRequired,
    airportStatusChangeAll: PropTypes.bool.isRequired,
    newTravelers: PropTypes.bool.isRequired,
    airportStatusChangeWorse: PropTypes.bool.isRequired,
    flightStatusChange: PropTypes.array.isRequired,
    toggleFlightStatusChange: PropTypes.func.isRequired
  };

  flightStatusChangeHandler = value => {

    this.props.toggleArrNotificationsValue('flightStatusChange', value);
  };

  travelAdvisoryLevelsHandler = value => {
    this.props.toggleArrNotificationsValue('travelAdvisoryLevels', value);
  };

  onChangeNewTravelersRadio = value => {
    this.props.changeNotificationsProp(value, true);
    this.props.changeNotificationsProp(
      value === 'newTravelersAll' ? 'newTravelersVIP' : 'newTravelersAll',
      false
    );
  };

  onAirportStatusChangeRadio = value => {
    this.props.changeNotificationsProp(value, true);
    this.props.changeNotificationsProp(
      value === 'airportStatusChangeAll' ? 'airportStatusChangeWorse' : 'airportStatusChangeAll',
      false
    );
  };

  onChangeTravelAdvisoryStatusChangeRadio = value => {
    this.props.changeNotificationsProp(value, true);
    this.props.changeNotificationsProp(
      value === 'travelAdvisoryStatusChangeAll' ? 'travelAdvisoryStatusChangeWorse' : 'travelAdvisoryStatusChangeAll',
      false
    );
  };


  render () {
    const { l } = this.context.i18n;
    const {
      assistedTravelers,
      newTravelers,
      newTravelersAll,
      newTravelersVIP,
      airportStatusChange,
      airportStatusChangeAll,
      airportStatusChangeWorse,
      flightStatusChange,
      travelAdvisoryLevels,
      travelAdvisoryStatusChangeAll,
      travelAdvisoryStatusChangeWorse,
    } = this.props;

    const newTravelersRadios = [{
      label: l('All Travelers'),
      value: 'newTravelersAll',
      checked: newTravelersAll
    }, {
      label: l('VIPs Only'),
      value: 'newTravelersVIP',
      checked: newTravelersVIP
    }];

    const airportStatusChangeRadios = [{
      label: l('All Status Changes'),
      value: 'airportStatusChangeAll',
      checked: airportStatusChangeAll
    }, {
      label: l('Only where status change gets worse'),
      value: 'airportStatusChangeWorse',
      checked: airportStatusChangeWorse
    }];

    const frequencyRadio = [{
      label: l('All Status Changes'),
      value: 'frequencyStatusChangeAll',
      checked: true
    }, {
      label: l('Only where status change gets worse'),
      value: 'frquencyStatusChangeWorse',
      checked: false
    }];

    const travelAdvisoryFrequencyRadio = [{
      label: l('All Status Changes'),
      value: 'travelAdvisoryStatusChangeAll',
      checked: travelAdvisoryStatusChangeAll
    }, {
      label: l('Only where status change gets worse'),
      value: 'travelAdvisoryStatusChangeWorse',
      checked: travelAdvisoryStatusChangeWorse
    }];

    console.log(flightStatusChange);
    return (
      <div className='notification-container row'>
        <div className='filter-card notification-card col-md-5 col-lg-3'>
          <div>
        <h3 className='global-filter-field-cnt__title'>{l('Show notifications for:')}</h3>
        <div className='checkbox-group checkbox-group_cols'>
          <div className='cust-cb input-size'>
            {/* <CheckboxField checkUpdate
              name='assistedTravelers'
              label={l('Message(s) from Assisted Traveler')}
              defaultValue={assistedTravelers}
              onChangeHandler={() => this.props.toggleNotificationsValue('assistedTravelers')} /> */}

            <CheckboxField checkUpdate
              name='newTravelers'
              label={l('New Traveler in the List')}
              defaultValue={newTravelers}
              onChangeHandler={() => this.props.toggleNotificationsValue('newTravelers')} />
          </div>

          <div className='radio-cnt'>
            <RadioButtonsGroup disabled={!newTravelers} updateChecked
              name='newTravelersRadio'
              fields={newTravelersRadios}
              onRadioGroupChange={this.onChangeNewTravelersRadio} />
          </div>
        </div>
          <div className='checkbox-group checkbox-group_cols'>
          <div className='cust-cb airport-status-head input-size'>
            <CheckboxField checkUpdate
              name='airportStatusChange'
              label={l('Airports Status Change')}
              defaultValue={airportStatusChange}
              onChangeHandler={() => this.props.toggleNotificationsValue('airportStatusChange')} />
          </div>
          <div className='radio-cnt'>
            <RadioButtonsGroup disabled={!airportStatusChange} updateChecked
              name='airportStatusChangeRadio'
              fields={airportStatusChangeRadios}
              onRadioGroupChange={this.onAirportStatusChangeRadio} />
          </div>
        </div>

        <div className='checkbox-group checkbox-group_cols flight-status-align airport-within'>
          <div className='cust-cb cust-cb-align input-size'>
            <div className='flight-status-head'>
              <CheckboxField checkUpdate
                name='flightStatusChange'
                label={l('Flight Status Change')}
                defaultValue={!!flightStatusChange.length}
                onChangeHandler={this.props.toggleFlightStatusChange} />
            </div>
            <div className='sub-checkbox-group'>
              <div className='child-label'>
              <CheckboxField checkUpdate
                name='flightStatusChangeAll'
                label={l('All Status Changes')}
                defaultValue={[ "early", "ontime", "delayGreen", "delayYellow", "delayRed", "cancelled"].every(elem => flightStatusChange.includes(elem))}
                onChangeHandler={this.props.toggleFlightStatusChange} />
                <CheckboxField checkUpdate
                name='flightStatusChangeEarly'
                label={l('Early')}
                defaultValue={flightStatusChange.indexOf('early') > -1}
                onChangeHandler={() => this.flightStatusChangeHandler('early')} />

              <CheckboxField checkUpdate
                name='flightStatusChangeOntime'
                label={l('On Time')}
                defaultValue={flightStatusChange.indexOf('ontime') > -1}
                onChangeHandler={() => this.flightStatusChangeHandler('ontime')} />

              <CheckboxField checkUpdate
                name='flightStatusChangeDelayGreen'
                label={l('Delayed < 15 mins')}
                defaultValue={flightStatusChange.indexOf('delayGreen') > -1}
                onChangeHandler={() => this.flightStatusChangeHandler('delayGreen')} />

              <CheckboxField checkUpdate
                name='flightStatusChangeAllDelayYellow'
                label={l('Delayed 15 - 45 mins')}
                defaultValue={flightStatusChange.indexOf('delayYellow') > -1}
                onChangeHandler={() => this.flightStatusChangeHandler('delayYellow')} />

              <CheckboxField checkUpdate
                name='flightStatusChangeAllDelayRed'
                label={l('Delayed > 45 mins')}
                defaultValue={flightStatusChange.indexOf('delayRed') > -1}
                onChangeHandler={() => this.flightStatusChangeHandler('delayRed')} />

              <CheckboxField checkUpdate
                name='flightStatusChangeAllCancelled'
                label={l('Canceled')}
                defaultValue={flightStatusChange.indexOf('cancelled') > -1}
                onChangeHandler={() => this.flightStatusChangeHandler('cancelled')} />

                {/*<CheckboxField checkUpdate*/}
                               {/*name='flightStatusChangeAllCancelled'*/}
                               {/*label={l('Diverted')}*/}
                               {/*defaultValue={flightStatusChange.indexOf('diverted') > -1}*/}
                               {/*onChangeHandler={() => this.flightStatusChangeHandler('diverted')} />*/}


              </div>
            </div>
          </div>
        </div>
        </div>
        </div>



















        <div className='filter-card notification-card card2 col-md-5 col-lg-4'>
          <div>
          <h3 className='global-filter-field-cnt__title'>{l('Security')}</h3>
          <div className='checkbox-group checkbox-group_cols health-withIn'>
            <div className='cust-cb cust-cb-align input-size'>
              <div className='advisory-header'>
                <CheckboxField checkUpdate
                               name='travelAdvisory'
                               label={l('Travel Advisories')}
                               defaultValue={travelAdvisoryLevels.length}
                               onChangeHandler={this.props.toggleTravelAdvisoryChange}
                />
              </div>
              <div className='sub-checkbox-group'>
                <div className='child-label'>
                  <CheckboxField checkUpdate
                                 name='travelAdvisory1'
                                 label={l('Level 1: Exercise Normal Precautions')}
                                 defaultValue={travelAdvisoryLevels.indexOf(1) > -1}
                                 onChangeHandler={() => this.travelAdvisoryLevelsHandler(1)}
                  />

                  <CheckboxField checkUpdate
                                 name='travelAdvisory2'
                                 label={l('Level 2: Exercise Increased Caution')}
                                 defaultValue={travelAdvisoryLevels.indexOf(2) > -1}
                                 onChangeHandler={() => this.travelAdvisoryLevelsHandler(2)}
                  />

                  <CheckboxField checkUpdate
                                 name='travelAdvisory3'
                                 label={l('Level 3: Reconsider Travel')}
                                 defaultValue={travelAdvisoryLevels.indexOf(3) > -1}
                                 onChangeHandler={() => this.travelAdvisoryLevelsHandler(3)} />

                  <CheckboxField checkUpdate
                                 name='travelAdvisory4'
                                 label={l('Level 4: Do Not Travel')}
                                 defaultValue={travelAdvisoryLevels.indexOf(4) > -1}
                                 onChangeHandler={() => this.travelAdvisoryLevelsHandler(4)}/>

                </div>
              </div>
            </div>
          </div>
          <div className='card3-checkbox checkbox-group checkbox-group_cols frq'>
            <div className='cust-cb input-size'>
              <h4 className='notification-head ' style={{paddingTop:"2rem"}}>Frequency</h4>
            </div>
            <div className='radio-cnt xd'>
              <RadioButtonsGroup updateChecked
                                 name='travelAdvisoryFrequencyStatusChangeRadio'
                                 fields={travelAdvisoryFrequencyRadio}
                                 className={"xd"}
                                 onRadioGroupChange={this.onChangeTravelAdvisoryStatusChangeRadio}/>

            </div>
          </div>

        <h3 className='global-filter-field-cnt__title health-head-align colorgray'>{l('Health')}</h3>
        <div className='checkbox-group checkbox-group_cols health-withIn top-panel'>
          <div className='cust-cb cust-cb-align health-alert-status'>
            <div className='health-header'>
              <CheckboxField checkUpdate
              name='ADASDASD'
              label={l('Health Alerts')}
                             lclsname={"colorgray"}
              // defaultValue={!!flightStatusChange.length}
              // onChangeHandler={this.props.toggleFlightStatusChange}
              disabled={true} />
            </div>
            <div className='sub-checkbox-group'>
              <div className='child-label'>
              <CheckboxField checkUpdate
                name='healthAlert1'
                label={l('Level 1: Practice Usual Precautions')}
                             lclsname={"colorgray"}
                // defaultValue={flightStatusChange.indexOf('all') > -1}
                // onChangeHandler={() => this.flightStatusChangeHandler('all')}
                             disabled={true} />
              <CheckboxField checkUpdate
                name='healthAlert2'
                label={l('Level 2: Practice Enhanced Precautions')}
                             lclsname={"colorgray"}
                // defaultValue={flightStatusChange.indexOf('ontime') > -1}
                // onChangeHandler={() => this.flightStatusChangeHandler('ontime')}
                disabled={true} />
              <CheckboxField checkUpdate
                name='healthAlert3'
                label={l('Level 3: Avoid Nonessential Travel')}
                             lclsname={"colorgray"}
                // defaultValue={flightStatusChange.indexOf('delayGreen') > -1}
                // onChangeHandler={() => this.flightStatusChangeHandler('delayGreen')}
                disabled={true} />
          <div className='checkbox-group checkbox-group_cols'>
          <div className='cust-cb'>
            <h4 className='notification-head colorgray'>Frequency</h4>
          </div>
          <div className='radio-cnt xd freq'>
            <RadioButtonsGroup disabled={!airportStatusChange} updateChecked
              name='frequencyStatusChangeRadio'
              className='xd'
              fields={frequencyRadio} disabled={true} />
          </div>

        </div>
              </div>
            </div>
          </div>
        </div>
          </div>
        </div>

        <div className='filter-card notification-card subs-card col-md-5 col-lg-3'>
          <div>
          <h3 className='global-filter-field-cnt__title colorgray'>{l('Subscription Services')}</h3>
        <div className='checkbox-group checkbox-group_cols health-withIn top-panel'>
          <div className='cust-cb cust-cb-align'>
            <div className='journey-header'>
              <CheckboxField checkUpdate
              name='ADSASDSAD'
              label={l('Journey Updates')}
                             lclsname={"colorgray"}
              // defaultValue={!!flightStatusChange.length}
              // onChangeHandler={this.props.toggleFlightStatusChange}
                             disabled={true} />
            </div>
            <div className='sub-checkbox-group'>
              <div className='child-label'>
              <CheckboxField checkUpdate
                name='journey1'
                             lclsname={"colorgray"}
                label={l('Flight Boarded')}
                // defaultValue={flightStatusChange.indexOf('all') > -1}
                // onChangeHandler={() => this.flightStatusChangeHandler('all')}
                             disabled={true} />

              <CheckboxField checkUpdate
                name='journey2'
                             lclsname={"colorgray"}
                label={l('Flight Change')}
                // defaultValue={flightStatusChange.indexOf('ontime') > -1}
                // onChangeHandler={() => this.flightStatusChangeHandler('ontime')}
                             disabled={true} />

              <CheckboxField checkUpdate
                name='journey3'
                             lclsname={"colorgray"}
                label={l('Flight Not Boarded')}
                // defaultValue={flightStatusChange.indexOf('delayGreen') > -1}
                // onChangeHandler={() => this.flightStatusChangeHandler('delayGreen')}
                             disabled={true} />

                <CheckboxField checkUpdate
                name='journey4'
                               lclsname={"colorgray"}
                label={l('Hotel Check-in')}
                // defaultValue={flightStatusChange.indexOf('delayGreen') > -1}
                // onChangeHandler={() => this.flightStatusChangeHandler('delayGreen')}
                               disabled={true} />

                 <CheckboxField checkUpdate
                name='journey5'
                                lclsname={"colorgray"}
                label={l('Hotel Check-out')}
                // defaultValue={flightStatusChange.indexOf('delayGreen') > -1}
                // onChangeHandler={() => this.flightStatusChangeHandler('delayGreen')}
                                disabled={true}/>
              </div>
            </div>
          </div>
        </div>
        <div style={{marginBottom:'20px'}}></div>
        <div className='checkbox-group checkbox-group_cols health-withIn'>
          <div className='cust-cb cust-cb-align cust-weather'>
            <div className='weather-header'>
              <CheckboxField checkUpdate
              name='ADASDASDASDS'
                             lclsname={"colorgray"}
              label={l('Weather Alerts')}
              // defaultValue={!!flightStatusChange.length}
              // onChangeHandler={this.props.toggleFlightStatusChange}
                             disabled={true} />
            </div>
            <div className='sub-checkbox-group'>
              <div className='child-label'>

               <CheckboxField checkUpdate
                name='weather1'
                              lclsname={"colorgray"}
                label={l('Outlooks: Least Serious')}
                // defaultValue={flightStatusChange.indexOf('all') > -1}
                // onChangeHandler={() => this.flightStatusChangeHandler('all')}
                              disabled={true} />

              <CheckboxField checkUpdate
                name='weather2'
                             lclsname={"colorgray"}
                label={l('Advisory: Less Serious')}
                // defaultValue={flightStatusChange.indexOf('all') > -1}
                // onChangeHandler={() => this.flightStatusChangeHandler('all')}
                             disabled={true} />

              <CheckboxField checkUpdate
                name='weather3'
                             lclsname={"colorgray"}
                label={l('Watch: More Serious')}
                // defaultValue={flightStatusChange.indexOf('ontime') > -1}
                // onChangeHandler={() => this.flightStatusChangeHandler('ontime')}
                             disabled={true} />

              <CheckboxField checkUpdate
                name='weather4'
                             lclsname={"colorgray"}
                label={l('Warning: Most Serious')}
                // defaultValue={flightStatusChange.indexOf('delayGreen') > -1}
                // onChangeHandler={() => this.flightStatusChangeHandler('delayGreen')}
                             disabled={true} />

              </div>
            </div>
          </div>
        </div>
          <div className='checkbox-group checkbox-group_cols'>
            <div className='cust-cb'>
              <h4 className='notification-head colorgray' style={{paddingTop:"2rem",paddingLeft:'24px' }}>Frequency</h4>
            </div>
            <div className='pdgl-35' >
            <div className='radio-cnt xd freq mdx'>
            <RadioButtonsGroup disabled={!airportStatusChange} updateChecked
              name='frequencyStatusChangeRadio'
              className='xd'
              fields={frequencyRadio} disabled={true} />
          </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    );
  }
}
