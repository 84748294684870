import React, { Component } from "react";
import Back from '../../styles/images/Back-Calendar.svg' 
import Forward from '../../styles/images/Forward-Calendar.svg' 
import { format as formatDate, startOfMonth,endOfMonth, eachDayOfInterval,isSameMonth,isSameDay,isToday, lastDayOfMonth,addMonths,getDay,addDays,setDate,eachDay } from 'date-fns'


class DateInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      name:this.props.name,
      date:""
    };
  }

  getFormattedDate = (date) => {
    if (date === ""){
      return " "
    }
    return formatDate(date, this.props.dateformat);
  };

  handleClick = (date="") => {
    if (!this.state.show) {
      // attach/remove event handler
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }

    this.setState((prevState) => ({
      show: !prevState.show,
      date:  ((date === "") ? '' : this.getFormattedDate(date.date))
    }));
  };

  handleOutsideClick = (e) => {
    // ignore clicks on the component itself
    if (this.node.contains(e.target)) {
      return;
    }

    this.handleClick();
  };

  render() {
    return (
      <span
        className="datepickercontainer"
        ref={(node) => {
          this.node = node;
        }}
        style={this.props.spanStyle || " "}
      >
        <input
          type="text"
          value={this.state.date}
          onClick={()=> this.handleClick() }
          style={this.props.inpStyles || " "}
          name = {this.props.name || " "}
          id = {this.props.id || {}}
          autoComplete={"off"}
        />
        <Calendar
          currDate={this.props.currentDate}
          changeCurrentDate={this.props.changeCurrentDate}
          show={this.state.show}
          ref={this.container}
          hanclick={this.handleClick}
          alignCalender={this.props.alignCalender}
        />
      </span>
      // <p style={{ textAlign: "center" }}>
      //   {"Selected Date: " + this.props.formattedDate}
      // </p>
    );
  }
}

function formatDateToDay(date) {
  return formatDate(date, "D");
}

function clone(d) {
  return new Date(d.getTime());
}



function DateList(props) {
  let dateList = props.dates.map((date, index) => {
    return (
      <button
        onClick={(e) => props.setSelectedDate(date, e)}
        className={"dateButton"}
        disabled={!date.isCurrentMonth}
        key={index}
      >
        {formatDateToDay(date.date)}
      </button>
    );
  });
  return <div className={"calendar"}>{dateList}</div>;
}

class DayPickerHeader extends React.Component {
  render() {
    return (
      <header className={"header"}>
        <span>
          {this.props.currentMonthLabel + ", " + this.props.currentYear}
        </span>
        <div className='cal-btn-align'>
          <img src={Back} alt='back' onClick={this.props.previousMonth}/>
          <img src={Forward} alt='fwd' onClick={this.props.nextMonth}/>
        </div>
      </header>
    );
  }
}

class Headings extends React.Component {
  render() {
    return (
      <div className={"headings"}>
        {this.props.dayLabels.map((day, index) => {
          return (
            <span className={"dayLabel"} key={index}>
              {day}
            </span>
          );
        })}
      </div>
    );
  }
}

class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      today: props.currDate || new Date(),
      selectedDate: props.currDate || new Date(),
      currentDateCursor: new Date(),
      dayLabels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      monthLabels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ]
    };
  }

  curMonth = () => {
    return this.state.currentDateCursor.getMonth();
  };

  curYear = () => {
    return this.state.currentDateCursor.getFullYear();
  };

  curMonthLabel = () => {
    return this.state.monthLabels[this.curMonth()];
  };

  selectedMonth = () => {
    return this.state.selectedDate.getMonth();
  };

  selectedMonthLabel = () => {
    return this.state.monthLabels[this.selectedMonth()];
  };

  datesArray = () => {
    const date = this.state.currentDateCursor;
    const startOfMonthh = startOfMonth(date);
    const endOfMonthh = endOfMonth(date);
    const days = eachDay(startOfMonthh, endOfMonthh);
    const dates = days.map((day) => ({
      date: day,
      isCurrentMonth: isSameMonth(
        new Date(this.curYear(), this.curMonth()),
        day
      ),
      isToday: isToday(day),
      isSelected: isSameDay(day, this.state.selectedDate)
    }));

    // get days from last month
    let previousMonthCursor = lastDayOfMonth(
      addMonths(date, -1)
    );
    const begIndex = getDay(days[0]);
    for (let i = begIndex; i > 0; i--) {
      dates.unshift({
        date: previousMonthCursor,
        isCurrentMonth: false,
        isToday: isToday(previousMonthCursor),
        isSelected: isSameDay(
          this.state.selectedDate,
          previousMonthCursor
        )
      });
      previousMonthCursor = addDays(previousMonthCursor, -1);
    }

    // get days from next month
    let daysNeededAtEnd = dates.length % 7 > 0 ? 7 - (dates.length % 7) : 0;
    let nextMonthCursor = addMonths(date, 1);
    nextMonthCursor = setDate(nextMonthCursor, 1);
    for (let i = 1; i <= daysNeededAtEnd; ++i) {
      dates.push({
        date: nextMonthCursor,
        isCurrentMonth: false,
        isToday: isToday(nextMonthCursor),
        isSelected: isSameDay(this.state.selectedDate, nextMonthCursor)
      });
      nextMonthCursor = addDays(nextMonthCursor, 1);
    }

    return dates;
  };

  componentDidMount() {
    if (this.props.startDate) {
      this.setState({
        currentDateCursor: this.props.startDate,
        selectedDate: this.props.startDate
      });
    }
  }

  setSelectedDate = (date, e) => {
    this.props.hanclick(date);
    this.props.changeCurrentDate(this.state.name,date, e);
    this.setState({
      selectedDate: date.date
    });
  };

  nextMonth = () => {
    let date = clone(this.state.currentDateCursor);
    this.setState({
      currentDateCursor: addMonths(date, 1)
    });
  };

  previousMonth = () => {
    let date = clone(this.state.currentDateCursor);
    this.setState({
      currentDateCursor: addMonths(date, -1)
    });
  };
  render() {
    let dates = this.datesArray();
    let cname = this.props.alignCalender;
    if (this.props.show == true) {
      cname = cname+" show";
    } else {
      cname = cname;
    }
    return (
      <div className={cname}>
        <DayPickerHeader
          nextMonth={this.nextMonth}
          previousMonth={this.previousMonth}
          currentMonthLabel={this.curMonthLabel()}
          currentYear={this.curYear()}
        />
        <Headings dayLabels={this.state.dayLabels} />
        <DateList
          setSelectedDate={this.setSelectedDate}
          dates={dates}
        ></DateList>
      </div>
    );
  }
}




// Refer the for custom datepicker usage


// class DayPicker extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       currentDate: getCurrentDate()
//     };
//   }
//
//   getFormattedDate = () => {
//     return DateFns.format(this.state.currentDate, "MMMM/DD/YYYY");
//   };
//
//   changeCurrentDate = (date, e) => {
//     this.setState({
//       currentDate: date.date
//     });
//   };
//   render() {
//     return (
//       <div>
//         <DateInput
//           formattedDate={this.getFormattedDate()}
//           currDate={this.state.currentDate}
//           changeCurrentDate={this.changeCurrentDate}
//         />
//
//         <DateInput
//           formattedDate={this.getFormattedDate()}
//           currDate={this.state.currentDate}
//           changeCurrentDate={this.changeCurrentDate}
//         />
//       </div>
//     );
//   }
// }




export default DateInput;
