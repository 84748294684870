import PropTypes from 'prop-types';
import React from 'react';
// import TravelersFilter from './../common/travelersFilter';
import TravelersListFilter from './travelersListFilter.js';
import TravelersTable from './travelersTable';
import Pager from '../common/pager';
import { sprintf } from '../../i18n/utils';
import LoadingIcon from '../../components/common/loadingIcon';
import { Link } from 'react-router-dom';  
import { isAdmin, isCompanyAdminOrUser, isGlobalAdmin, isUserReadOnly } from '../../helpers/user';
import SearchBar from "../../containers/Header/SearchBar";
// import TfilterIcon from '../common/svgIcon/FilterIcon';
// import FiltersButton from '../../styles/images/Filters Button-Button_ Filters.svg';
import TravelersFilterBlock from './travelersFilterBlock.js';
import config from '../../config/index';
// import SettingsView from '../../views/SettingsView/SettingsView';
// import SettingsTabs from '../common/settingsTabs';
const headerHeight = config.layout.headerHeight;

export class TravelersList extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    travelersList: PropTypes.array.isRequired,
    companies: PropTypes.array.isRequired,
    costCenters: PropTypes.array.isRequired,
    travelersListFilter: PropTypes.object.isRequired,
    travelersListLoading: PropTypes.bool.isRequired,
    travelersListPage: PropTypes.number.isRequired,
    travelersListMetaData: PropTypes.object.isRequired,
    travelersListSortBy: PropTypes.string.isRequired,
    travelersListSortByDirect: PropTypes.bool.isRequired,
    appSettingsDimensions: PropTypes.object.isRequired,
    userRole: PropTypes.string,
    loadTravelersList: PropTypes.func.isRequired,
    switchTravelersListPage: PropTypes.func.isRequired,
    setTravelersListSorter: PropTypes.func.isRequired,
    setTravelersListFilter: PropTypes.func.isRequired,
    clearTravelersListFilter: PropTypes.func.isRequired
  };

  static contextTypes = {
    i18n: PropTypes.object
  };
  render () {
    const { l, ngettext } = this.context.i18n;
    
    const {
      location, travelersListPage, travelersListMetaData, travelersList, switchTravelersListPage,
      companies, travelersListFilter, setTravelersListFilter, clearTravelersListFilter,
      setTravelersListSorter, travelersListSortBy, travelersListSortByDirect, travelersListLoading,
      userRole, costCenters
    } = this.props;
    let travelers = travelersListMetaData._size;
    let filteredTravelers = travelersListMetaData._returned;
     let info;
    if (travelers && filteredTravelers) {
      let trLength = filteredTravelers;
      let totalLength = travelers;
      if (typeof travelers === 'object') {
        trLength = filter(filteredTravelers, { status: 'active' }).length;
      }
      if (typeof travelers === 'object') {
        if (!$('#map-flights').hasClass('active')) {
          totalLength = filter(travelers, { status: 'active' }).length;
        } else {
          totalLength = sessionStorage.get('TravelerCount');
          // totalLength = filter(travelers, function (traveler) {
          //  return (traveler.status === 'active' &&
          //    (traveler.currentJourneyStatus === 'warning' || traveler.currentJourneyStatus === 'alarm'));
          // }).length;
        }
      }
        const text = sprintf(ngettext(
          `%d traveler of ${totalLength}`,
          `%d travelers of ${totalLength}`,
          trLength
        ), trLength);
        info = totalLength ? text : null;
      }
    let travelersData;
    if (travelersList.length) {
      const displayOrgCol = (costCenters.length && isCompanyAdminOrUser(userRole)) || !isCompanyAdminOrUser(userRole);
      travelersData = (
        <TravelersTable
          location={location}
          travelers={travelersList}
          travelersSorter={setTravelersListSorter}
          showOrganizationCol={displayOrgCol}
          sortBy={travelersListSortBy}
          sortByDirect={travelersListSortByDirect}
          userRole={userRole} />
      );
    } else if (!travelersListLoading) {
      travelersData = l('No travelers found');
    }

    let adminButtons;

    if (isAdmin(this.props.userRole) && !isGlobalAdmin(this.props.userRole) && !isUserReadOnly(this.props.userRole)) {
      adminButtons = (
        <div className='buttons-r-list'>
          <Link to='/add/traveler' className='btn btn01 btn-settings'>
            {l('Add Traveler')}
          </Link>
          <Link to='/import/traveler' className='btn btn02 import-travelers-btn'>
            {l('Import Travelers')}
          </Link>
        </div>
      );
    }
    // const containerStyle = {
    //   height: this.props.appSettingsDimensions.height - headerHeight
    // };
    return (
      <div className='scrollable-horizontally travelers-content' >
        <div className='head-row'>
          <h1>{l('Travelers')}</h1>
        </div>
        <div className='travelers-head'>
          <div>     
            {/* <TravelersFilter
            travelers={travelersListMetaData._total_size}
            filteredTravelers={travelersListMetaData._size}
            companyList={companies}
            costCenterList={costCenters}
            travelersFilter={travelersListFilter}
            setTravelersFilter={setTravelersListFilter}
            clearTravelersFilter={clearTravelersListFilter}
            userRole={userRole} />  */}
            { /* <TfilterIcon style={{ color: 'white', backgroundColor: 'blue' }} className='opener'/> */}
            <div className='travelers-search-block'>
              <TravelersListFilter
              travelersListFilter={travelersListFilter}
              clearTravelersListFilter={clearTravelersListFilter}
              setTravelersListFilter={setTravelersListFilter}/>
              {/* <img src={FiltersButton} alt='filter'/> */}
              <TravelersFilterBlock
                travelers={travelersListMetaData._total_size}
                filteredTravelers={travelersListMetaData._size}
                companyList={companies}
                costCenterList={costCenters}
                travelersFilter={travelersListFilter}
                setTravelersFilter={setTravelersListFilter}
                clearTravelersFilter={clearTravelersListFilter}
                userRole={userRole} />
            </div>
            
          </div>
         {adminButtons}
        </div>
        <LoadingIcon loading={travelersListLoading} />
        <span className='tInfo'>{info}</span>
        {travelersData}
        <Pager
          page={travelersListPage}
          pages={travelersListMetaData._total_pages}
          switchPage={switchTravelersListPage} />
      </div>
    );
  }
}
export default TravelersList;
