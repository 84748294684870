import PropTypes from 'prop-types';
import React from 'react';
import Select from '../../components/common/select/Select';
// import find from 'lodash/find';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import { sprintf } from '../../i18n/utils';
import { isCompanyAdminOrUser } from '../../helpers/user';
import $ from 'jquery';
import { localStorage, sessionStorage } from '../../helpers/localStorage';
import DatePicker from 'react-datepicker';
import { CollapseHolder, CollapseOpener, CollapseBlock } from '../../components/common/collapse';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Back from '../../styles/images/Back-Calendar.svg'
import Forward from '../../styles/images/Forward-Calendar.svg'
import FlightBoardImage from '../../styles/images/flightboard/FlightBoard.svg';
import FiltersActive from '../../styles/images/flightboard/filter-flightboard.svg';
import DropdownMenu from 'react-dd-menu';
import 'react-dd-menu/dist/react-dd-menu.css';
import SettingsView from '../../views/SettingsView/SettingsView';
// import TfilterIcon from './svgIcon/TfilterIcon';
// import Main from '../activeTravelers/datePicker';
// import DownlaodIcon from './svgIcon/DownloadIcon';
import Download from '../../styles/images/flightboard/Export.svg';
import DateInput from '../../../src/components/DayPicker/DayPicker';
import Leaf from '../../styles/images/flightboard/CO2-FlightBoard-Leaf.svg';
import Restart from '../../styles/images/Reset-Filters.svg';
import Filter from '../../styles/images/Filter.svg';
import PDF from '../../styles/images/PDF.svg';
import XLS from '../../styles/images/XLS.svg';
import HamburgerIcon from '../../styles/images/HamburgerIcon.svg';
import '../../styles/_flight_board_filter.scss';
import Draggable from 'react-draggable';
import { format as formatDate, startOfMonth,endOfMonth, eachDayOfInterval,isSameMonth,isSameDay,isToday, lastDayOfMonth,addMonths,getDay,addDays,setDate } from 'date-fns'
import alarm from '../../styles/images/Status-Red.svg';
import warning from '../../styles/images/Status-Yellow.svg';
import ok from '../../styles/images/Status-Green.svg';
import Close from '../../styles/images/Close.svg';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
function getCurrentDate() {
  return new Date();
}

let flightStatusDict = {
  "early":"1",
  "ontime":"2",
  "delayGreen":"3",
  "delayYellow":"4",
  "delayRed":"5",
  "cancelled":"6",
  "diverted":"7",
}

export class TravelersFilter extends React.Component {
  static contextTypes = {
    i18n: PropTypes.object
  };

  static propTypes = {
    travelers: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
    filteredTravelers: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
    perPage: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
    companyList: PropTypes.array.isRequired,
    userRole: PropTypes.string.isRequired,
    costCenterList: PropTypes.array,
    carrierList: PropTypes.array,
    airportList: PropTypes.array,
    flightStatusList: PropTypes.array,
    setTravelersFilter: PropTypes.func.isRequired,
    travelersFilter: PropTypes.object.isRequired,
    clearTravelersFilter: PropTypes.func.isRequired,
    hideStatusFilter: PropTypes.any,
    title: PropTypes.any,
    exportToFile: PropTypes.func,
    toggleDownloadShadow: PropTypes.func,
    toggleFilterShadow: PropTypes.func,
    isFilterShadow: PropTypes.bool,
    isDownloadShadow: PropTypes.bool,
    changeView: PropTypes.func.isRequired,
    view: PropTypes.array.isRequired,
    isExportInProgress: PropTypes.bool.isRequired
  };

  getUserFstatus = (userSettings) => {
    let res=[];
    for (const val of userSettings.flightStatus) {
      res.push(flightStatusDict[val]);

    }
    res.join();
    return res.join();
  };






  constructor(props) {
    super(props);



    this.state = {
      alarm: false,
      collapseFilterHidden:true,
      collapseDownloadHidden:false,
      isDownloadDropShown:false,
      isFilterDropShown:false
    };
    this._vipRef = React.createRef();
    this._alarmRef = React.createRef();
    this._warningRef = React.createRef();
    this._okRef = React.createRef();
    this._nonHelpedRef = React.createRef();
    this._dateRangeTypeOptions = ['Custom'];
    this._selectDefault = [];
    this._dateRangeType = this.props.travelersFilter.dateRangeType;

    if (this.props.userSettings.flightsWithin <= 1440) {
      this._dateRangeStart = moment().local().startOf('day').toDate();
      this._dateRangeEnd = moment().local().endOf('day').toDate();
    } else {
      this._dateRangeStart = moment().local().startOf('day').toDate();
      this._dateRangeEnd = moment().local().add(2,'day').endOf('day').toDate();
    }

      //
      // this._dateRangeStart = this.props.travelersFilter.dateRangeStart;
      // this._dateRangeEnd =  this.props.travelersFilter.dateRangeEnd;
    this._company = this.props.travelersFilter.company;
    this._carrier = this.props.travelersFilter.carrier;
    this._airport = this.props.travelersFilter.airport;
    this._fstatus = this.getUserFstatus(this.props.userSettings);
    this._costcenter_id = this.props.travelersFilter.costcenter_id;
    this._additionalFilter = this.props.userSettings.onlyVIP?'vip':'';
    if (this.props.travelersFilter.vip) this._additionalFilter = 'vip';
    let status = this.props.travelersFilter.status;
    this._currentStatus = '';

  }

  clear = (e) => {
    e.preventDefault();
    this.props.clearTravelersFilter();
    this._company = this._selectDefault;
    this._carrier = this._selectDefault;
    this._airport = this._selectDefault;
    this._fstatus = this.getUserFstatus(this.props.userSettings);
    this._costcenter_id = this._selectDefault;
    let status = this.props.travelersFilter.status;
    this._currentStatus = '';
    this._additionalFilter = this.props.userSettings.onlyVIP?'vip':'';
    if (this.props.userSettings.flightsWithin <= 1440) {
      this._dateRangeStart = moment().local().startOf('day').toDate();
      this._dateRangeEnd = moment().local().endOf('day').toDate();
    } else {
      this._dateRangeStart = moment().local().startOf('day').toDate();
      this._dateRangeEnd = moment().local().add(2,'day').endOf('day').toDate();
    }
    this.setFilter();

  };

  componentWillUnmount() {
    this.props.clearTravelersFilter();
    this._company = this._selectDefault;
    this._carrier = this._selectDefault;
    this._airport = this._selectDefault;
    this._fstatus = this.getUserFstatus(this.props.userSettings);
    this._costcenter_id = this._selectDefault;
    let status = this.props.travelersFilter.status;
    this._currentStatus = '';
    this._additionalFilter = this.props.userSettings.onlyVIP?'vip':'';
    if (this.props.userSettings.flightsWithin <= 1440) {
      this._dateRangeStart = moment().local().startOf('day').toDate();
      this._dateRangeEnd = moment().local().endOf('day').toDate();
    } else {
      this._dateRangeStart = moment().local().startOf('day').toDate();
      this._dateRangeEnd = moment().local().add(2,'day').endOf('day').toDate();
    }
    this.setFilter();

  }
  UNSAFE_componentWillReceiveProps (nextProps) {
    if(this.props.userSettings != nextProps.userSettings){
      this._fstatus = this.getUserFstatus(nextProps.userSettings);
      this._additionalFilter = nextProps.userSettings.onlyVIP?'vip':'';
      if (nextProps.userSettings.flightsWithin <= 1440) {
        this._dateRangeStart = moment().local().startOf('day').toDate();
        this._dateRangeEnd = moment().local().endOf('day').toDate();
      } else {
        this._dateRangeStart = moment().local().startOf('day').toDate();
        this._dateRangeEnd = moment().local().add(2,'day').endOf('day').toDate();
      }
      this.setFilter();
    }

  }


  UNSAFE_componentWillMount () {
    console.log(this.props.userSettings);
  }

  componentDidMount() {
    this._fstatus = this.getUserFstatus(this.props.userSettings);
    this._additionalFilter = this.props.userSettings.onlyVIP?'vip':'';
    if (this.props.userSettings.flightsWithin <= 1440) {
      this._dateRangeStart = moment().local().startOf('day').toDate();
      this._dateRangeEnd = moment().local().endOf('day').toDate();
    } else {
      this._dateRangeStart = moment().local().startOf('day').toDate();
      this._dateRangeEnd = moment().local().add(2,'day').endOf('day').toDate();
    }
    setTimeout(this.setFilter,250);
  }


  exportToExcel = (e) => {
    this.props.exportToFile('xlsx');
  }

  exportToPdf = (e) => {
    this.props.exportToFile('pdf');
  }

  // dateRangeChange = (e) => {
  //   return (selected) => {
  //     this._dateRangeType = selected.value;
  //     switch (selected.value) {
  //       // case 'Current':
  //       //   this._dateRangeStart = moment().local().startOf('day');
  //       //   this._dateRangeEnd = moment().local().endOf('day');
  //       //   break;
  //       // case 'Last 7 Days':
  //       //   this._dateRangeStart = moment().local().subtract(7, 'day').startOf('day');
  //       //   this._dateRangeEnd = moment().local().endOf('day');
  //       //   break;
  //       // case 'Last 30 Days':
  //       //   this._dateRangeStart = moment().local().subtract(30, 'day').startOf('day');
  //       //   this._dateRangeEnd = moment().local().endOf('day');
  //       //   break;
  //       case 'Custom':
  //         this._dateRangeStart = moment().local().subtract(1, 'day').startOf('day').toDate();
  //         this._dateRangeEnd = moment().local().endOf('day').toDate();
  //         break;
  //     }

  //     setTimeout(this.setFilter);
  //   };
  // }

  selectChange = (name) => {

    return (selected) => {
      if (Object.prototype.hasOwnProperty.call(selected, 'value')) {
        this['_' + name] = selected.value;
      } else if (selected) {
        this['_' + name] = selected;
      } else {
        this['_' + name] = [];
      }
      setTimeout(this.setFilter);
    };
  }

  statusChange = (name) => {
    return (value) => {
      this['_' + name] = value;
      setTimeout(this.setFilter);
    }
  }

  getFormattedDate = () => {
    return formatDate(this.state.currentDate, 'MM/DD/YYYY');
  };
  changeCurrentDate = (name,date, e) => {
    this['_' + name] = date.date;
  };


  dateChange = (name) => {
    return (value) => {
      this['_' + name] = value;
      console.log(typeof this._dateRangeStart);
      if (this._dateRangeStart && this._dateRangeEnd) {
        setTimeout(this.setFilter);
      }
    };
  }



  setFilter = (e) => {
    const filters = { ...this.props.travelersFilter };
    if (this._vipRef !== undefined && this._vipRef.current !== undefined) {
      if (!Object.prototype.hasOwnProperty.call(this.props, 'hideStatusFilter')) {
        filters.status = {
          alarm: this._alarmRef.current.checked,
          warning: this._warningRef.current.checked,
          ok: this._okRef.current.checked
        };
      }

      // filters.vip = this._vipRef.current.checked;
      // filters.nonHelped = this._nonHelpedRef.current.checked;
    }
    console.log(this._dateRangeStart);
    filters.dateRangeType = this._dateRangeType;
    // if (this._currentStatus && this._currentStatus !== '') {
    //   filters.fstatus = this._currentStatus;
    //   const statusOfFlight = this._currentStatus.split(',')
    //   if (statusOfFlight.indexOf('alarm') > -1) {
    //     filters.status.alarm = true
    //   } else {
    //     filters.status.alarm = false
    //   }
    //   if (statusOfFlight.indexOf('warning') > -1) {
    //     filters.status.warning = true
    //   } else {
    //     filters.status.warning = false
    //   }
    //   if (statusOfFlight.indexOf('ok') > -1) {
    //     filters.status.ok = true
    //   } else {
    //     filters.status.ok = false
    //   }
    // } else {
    //   filters.status = {
    //     alarm: false,
    //     warning: false,
    //     ok: false
    //   };
    // }
    if (filters.dateRangeType !== 'Current' && this._dateRangeStart) {
      filters.dateRangeStart = moment(this._dateRangeStart ).local().format('YYYY-MM-DD');
    } else {
      filters.dateRangeStart = null;
    }
    if (filters.dateRangeType !== 'Current' && this._dateRangeEnd) {
      filters.dateRangeEnd = moment(this._dateRangeEnd ).local().format('YYYY-MM-DD');
    } else {
      filters.dateRangeEnd = null;
    }
    if (filters.company) {
      filters.company = this._company;
    }
    if (filters.carrier) {
      filters.carrier = this._carrier;
    }
    if (filters.airport) {
      filters.airport = this._airport;
    }
    console.log(this._fstatus);
    if (filters.fstatus) {
      filters.fstatus = this._fstatus;
    }
    console.log(filters);
    if (filters.costcenter_id) {
      filters.costcenter_id = this._costcenter_id;
    }
    if (this._additionalFilter === 'vip') {
      filters.vip = 1;
    } else {
      filters.vip = 0;
    }

    this.props.setTravelersFilter(filters);
  };

  callChildFunction = () => {
    this.child.close();
    this.downchild.close();///calling a child function here
  }

  DownloadDroptoggle = () => {
    this.setState({ isDownloadDropShown: !this.state.isDownloadDropShown });
  };

  DownloadDropClose = () => {
    this.setState({ isDownloadDropShown: false });
  };

  FilterDroptoggle = () => {
    this.setState({ isFilterDropShown: !this.state.isFilterDropShown });
  };

  FilterDropClose = () => {
    this.setState({ isFilterDropShown: false });
  };

  render() {
    console.log(this._currentStatus);
    const dateinpStyle = {
      height: '27px',
      width: '81px',
      borderRadius:'6px',
      color: '#595B60',
      fontFamily: 'Open Sans',
      fontSize: '13px',
      border:"1px solid lightgray",
      marginRight:'0px'

    };

    const { l, ngettext } = this.context.i18n;
    const {
      travelers, filteredTravelers, companyList,
      carrierList, airportList, travelersFilter, userRole,perPage,
      hideStatusFilter, title, costCenterList
    } = this.props;
    console.log(travelersFilter);
    const { dateRangeType, company, carrier, airport, status, currentStatus, fstatus } = travelersFilter;
    // let additionalFilter = '';
    // if (vip) additionalFilter = 'vip';
    const costcenterId = travelersFilter.costcenter_id;

    const dateRangeTypeOptions = this._dateRangeTypeOptions.map((i) => { return { value: i, label: l(i) }; });
    if (!this._dateRangeStart) this._dateRangeStart = moment().local().subtract(1, 'day').startOf('day').toDate();
    if (!this._dateRangeEnd ) this._dateRangeEnd = moment().local().endOf('day').toDate();


    let costcenterSelect = null;
    if (isCompanyAdminOrUser(userRole) && costCenterList && costCenterList.length) {
      costcenterSelect = (
        <Select
          value={costcenterId}
          options={costCenterList}
          searchable
          multi
          simpleValue
          clearable
          className='carrier-selectt'
          placeholder={l('All Sort level ID')}
          arrowRenderer = {() => (<i class="fa fa-angle-down fa-lg" aria-hidden="true"></i> )}
          onChange={this.selectChange('costcenter_id')} />
      );
    }

    let companySelect = null;
    if (!isCompanyAdminOrUser(userRole) && companyList && company) {
      // if (!find(companyList, { 'value': 'all' })) {
      //   companyList.unshift({value: 'all', label: l('All Companies')});
      // }

      companySelect = (
        <Select
          value={company}
          options={companyList}
          multi
          simpleValue
          className='company-selectt'
          placeholder={l('All Companies')}
          arrowRenderer = {() => (<i class="fa fa-angle-down fa-lg" aria-hidden="true"></i> )}
          onChange={this.selectChange('company')} />
      );
    }

    let carrierSelect = null;
    if (carrierList && carrier) {
      const carrierSortedList = sortBy(carrierList, 'label');
      // if (!find(carrierSortedList, { 'value': 'all' })) {
      //   carrierSortedList.unshift({value: 'all', label: l('All Carriers')});
      // }

      carrierSelect = (
        <Select
          value={carrier}
          options={carrierSortedList}
          searchable
          multi
          simpleValue
          clearable
          className='carrier-selectt'
          placeholder={l('All Carriers')}
          arrowRenderer = {() => (<i class="fa fa-angle-down fa-lg" aria-hidden="true"></i> )}
          onChange={this.selectChange('carrier')} />
      );
    }
    let airportSelect = null;
    if (airportList && airport) {
      const airportSortedList = sortBy(airportList, 'label');
      // if (!find(airportSortedList, { 'value': 'all' })) {
      //   airportSortedList.unshift({value: 'all', label: l('All Airports')});
      // }

      airportSelect = (
        <Select
          clearable
          value={airport}
          options={airportSortedList}
          searchable
          multi
          simpleValue
          placeholder={l('All Airports')}
          arrowRenderer = {() => (<i class="fa fa-angle-down fa-lg" aria-hidden="true"></i> )}
          onChange={this.selectChange('airport')} />
      );
    }

    let additionalFiltersList = [
      {
        value: 'vip',
        label: 'VIPs Only'
      },

    ];
    let additionalFilters = null;
    if (additionalFiltersList) {
      additionalFilters = (
        <Select
          clearable
          value={this._additionalFilter}
          options={additionalFiltersList}
          searchable
          multi
          simpleValue
          className='xs'
          placeholder={l('Additional Filters')}
          arrowRenderer = {() => (<i class="fa fa-angle-down fa-lg" aria-hidden="true"></i> )}
          onChange={this.selectChange('additionalFilter')} />
      );
    }

    // let flightStatusSelect = null;
    // if (flightStatusList && fstatus) {
    //   flightStatusSelect = (
    //     <Select
    //       clearable
    //       value={fstatus}
    //       options={flightStatusList}
    //       multi
    //       simpleValue
    //       placeholder={l('All Flight Statuses')}
    //       optionRenderer={({ label }) => (<label>{label}</label>)}
    //       onChange={this.selectChange('fstatus')} />
    //   );
    // }

    let info;
    if (filteredTravelers && perPage) {
      let trLength = perPage;
      let totalLength = filteredTravelers;
      if (typeof travelers === 'object') {
        trLength = filter(perPage, { status: 'active' }).length;
      }
      if (typeof travelers === 'object') {
        if (!$('#map-flights').hasClass('active')) {
          totalLength = filter(travelers, { status: 'active' }).length;
        } else {
          totalLength = sessionStorage.get('TravelerCount');
          // totalLength = filter(travelers, function (traveler) {
          //  return (traveler.status === 'active' &&
          //    (traveler.currentJourneyStatus === 'warning' || traveler.currentJourneyStatus === 'alarm'));
          // }).length;
        }
      }
      if (title === 'flight') {
        const text = sprintf(ngettext(
          `%d flight of ${totalLength}`,
          `%d flights of ${totalLength}`,
          trLength
        ), trLength);
        info = totalLength ? text : null;
      } else {
        const text = sprintf(ngettext(
          `%d traveler of ${totalLength}`,
          `%d travelers of ${totalLength}`,
          trLength
        ), trLength);
        info = totalLength ? text : null;
      }
    }

    const showStatusFilter = !hideStatusFilter;
    const flightStatus = [
      {
        classValue: 'alarm',
        label: l('Diverted'),
        value: '7'
      },
      {
        classValue: 'alarm',
        label: l('Canceled'),
        value: '6'
      },
      {
        classValue: 'alarm',
        label: l('Delay > 45 mins'),
        value: '5'
      },
      {
        classValue: 'warning',
        label: l('Delay 16 - 45 mins'),
        value: '4'
      },
      {
        classValue: 'ok',
        label: l('Delay < 15 mins'),
        value: '3'
      },
      {
        classValue: 'ok',
        label: 'On Time',
        value: '2'
      },
      {
        classValue: 'ok',
        label: 'Early',
        value: '1'
      }
    ]
    // if(status.alarm===true && status.warning===true && status.ok===true){
    //   // currentStatus = 'alarm,warning,ok'
    // }
    let disruptionStatus = null;
    if (flightStatus && fstatus) {
      disruptionStatus = (<Select
        clearable
        value={this._fstatus}
        options={flightStatus.reverse()}
        searchable
        multi
        simpleValue
        onSelectAll = {"All Disruption"}
        className='disruption-select  sel'
        placeholder={l('All Disruption')}
        arrowRenderer = {() => (<i class="fa fa-angle-down fa-lg" aria-hidden="true"></i> )}
        optionRenderer={({ label, classValue }) => {
          if(classValue === 'ok'){
            return(<label><div className="Dicon">
              <img className='disruption-icon ico-align' src= {ok}/>
              <div className="Dlabel">{label}</div></div></label>)
          }else
          if(classValue === 'warning'){
            return( <label><div className="Dicon">
              <img className='disruption-icon ico-align' src= {warning}/>
              <div className="Dlabel">{label}</div></div></label>)
          }else{
            return (<label><div className="Dicon">
              <img className='disruption-icon ico-align' src= {alarm}/>
              <div className="Dlabel">{label}</div></div></label>)
          }
        }}
        onChange={this.selectChange('fstatus')} />)
    }
    var vlaue = new Date();



    const downloadDropmenuOptions = {
      isOpen: this.state.isDownloadDropShown,
      close: this.DownloadDropClose,
      toggle: <div className='filter-head'> <div className='opener-div pointer'><img src={Download} alt=""style={{boxShadow:this.props.dShadow}} onClick={this.DownloadDroptoggle}/> </div> </div>,
      align: 'left',
      closeOnInsideClick: false,
      closeOnOutsideClick: true,
    };

    const filterDropmenuOptions = {
      isOpen: this.state.isFilterDropShown,
      close: this.FilterDropClose,
      toggle: <div className='filter-head'><div className='opener-div pointer'> <img src={FiltersActive} alt=''style={{boxShadow:this.props.fShadow}} onClick={this.FilterDroptoggle}/> </div></div>,
      align: 'left',
      closeOnInsideClick: false,
      closeOnOutsideClick: true,
    };

    console.log(info);
    return (
      <>
        <div className='travele'>
          <div className='travele-info'>
            <h1>{this.props.view === 'flights' ? l('FlightBoard') : l('CO2 FlightBoard')}</h1>
            <span className='info'>{info}</span>
          </div>
          <div className='filter-travelers'>
            <div className='co2-footsteps' onClick={this.props.changeView}>
              <img src={this.props.view === 'flights' ? Leaf: FlightBoardImage} alt='co2'/>
            </div>


            <DropdownMenu {...filterDropmenuOptions}>
              <Draggable handle="strong">
                <div className='box no-cursor draggable-filter'>
                  <div className='holder-wrap filter-inner-container'>
                    <div className='holder'>
                      <div className='filter-header'>
                        <strong className='cursor' >
                          <img className='filter-drag' src={HamburgerIcon} alt="" width='16px' height='14.4px' />
                        </strong>
                        <div className='filter-heading'>
                          <p>Filters</p>
                        </div>
                        <img className='filter-close1' src={Close} alt='close' onClick={filterDropmenuOptions.close} />
                        {/* <i className="fa fa-align-justify" style={{ paddingLeft: '60px', opacity: '0.2' }}></i> */}
                      </div>
                      <hr className='filter-line1'/>
                      <div className='clear-filter1'>
                        <div className='clr1' onClick={this.clear}>Clear Filters</div></div>





                      <div className='filter-dateranges-container'>
                        <p className='date-range-title'>Date Range</p>
                        <div className='date-range'>
                          <div className='filter-dateranges'>
                            <DatePicker id='dateRangeStart' name='dateRangeStart' autoComplete='off'
                                        calendarStartDay={1}
                                        className='filter-daterange'
                                        selected={this._dateRangeStart} maxDate={this._dateRangeEnd}
                                        onChange={this.dateChange('dateRangeStart')}
                                        popperPlacement="top"
                                        formatWeekDay={nameOfDay => nameOfDay.substr(0,3)}
                                        renderCustomHeader={ ({date, decreaseMonth,increaseMonth}) => (
                                          <div style={{height : '30px',paddingLeft:'1rem'}}>
                                      <span className={"cal-headername"}>
                                        {moment(date).format('MMMM, YYYY')}
                                      </span>
                                            <img src={Back} alt='back' onClick={decreaseMonth} style={{paddingLeft:'1rem'}} className={"pointer"} />
                                            <img src={Forward} alt='back' onClick={increaseMonth} style={{paddingLeft:'1rem'}} className={"pointer"} />

                                          </div>)}/>

                            <label>to</label>
                            <DatePicker id='dateRangeEnd' name='dateRangeEnd' autoComplete='off'
                                        calendarStartDay={1}
                                        className='filter-daterange'
                                        selected={this._dateRangeEnd}
                                        minDate={this._dateRangeStart}
                                        onChange={this.dateChange('dateRangeEnd')}
                                        popperPlacement="bottom-end"
                                        formatWeekDay={nameOfDay => nameOfDay.substr(0,3)}
                                        renderCustomHeader={ ({date, decreaseMonth,increaseMonth}) => (
                                          <div style={{height : '30px',paddingLeft:'1rem'}}>
                                                 <span className={"cal-headername"}>
                                                  {moment(date).format('MMMM, YYYY')}
                                                </span>
                                            <img src={Back} alt='back' onClick={decreaseMonth} style={{paddingLeft:'1rem'}} className={"pointer"} />
                                            <img src={Forward} alt='back' onClick={increaseMonth} style={{paddingLeft:'1rem'}} className={"pointer"} />

                                          </div>)}
                            />

                          </div>
                        </div>
                      </div>
                      <hr/>
                      <div className='disruption-status-container'>
                        <p>Disruption Status</p>
                        <div className='disruptions'>
                          {disruptionStatus}
                        </div>
                        {/* <ul className='status-cb disruption-status'>
                                <li>
                                  <input id='st01' checked={status && status.alarm}
                                    type='checkbox' ref={this._alarmRef} onChange={this.setFilter} />
                                  <label htmlFor='st01'><i className='icon alarm'>alarm</i></label>
                                </li>
                                <li>
                                  <input id='st02' checked={status && status.warning}
                                    type='checkbox' ref={this._warningRef} onChange={this.setFilter} />
                                  <label htmlFor='st02'><i className='icon warning'>{l('warning')}</i></label>
                                </li>
                                <li>
                                  <input id='st03' checked={status && status.ok}
                                    type='checkbox' ref={this._okRef} onChange={this.setFilter} />
                                  <label htmlFor='st03'><i className='icon ok'>{l('ok')}</i></label>
                                </li>
                              </ul> */}
                      </div>
                    </div>
                    <hr/>
                    <div className='select-holder additional-filters'>
                      <p style={{ fontSize: '16px', fontWeight: 'bold', color: '#154591' }}>Additional Filters</p>
                      <div className='additional label-align'>
                        {/* {flightStatusSelect} */}
                        <div>{companySelect}</div>
                        <div>{costcenterSelect}</div>
                        <div>{carrierSelect}</div>
                        <div>{airportSelect}</div>
                        <div>{additionalFilters}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </Draggable>
            </DropdownMenu>


            <DropdownMenu {...downloadDropmenuOptions}>
              <Draggable handle="strong">
                <div className='exportspx'>
                  <div className='filter-header export'>
                    <strong className='cursor'>
                      <img src={HamburgerIcon} alt=""/>
                    </strong>
                    <div className='filter-heading'>
                      <p>Export</p>
                    </div>
                    <img className='filter-close1' src={Close} alt='close' onClick={downloadDropmenuOptions.close} />
                  </div>
                  <hr className='filter-line1'/>
                  {/* <a className='clear-all' href='/' onClick={this.clear} >{l('Clear Filters')}</a> */}
                  <div className='exports' style={{ width: '100%', height: '100px' }}>
                    {this.props.exportToFile && (
                      // <button className='export-xlsx' onClick={this.exportToExcel} />
                      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                        <img src={XLS} className={this.props.isExportInProgress?'export-xlsx disable-click': 'export-xlsx pointer'} onClick={this.exportToExcel} />
                        <p>XLS</p>
                      </div>
                    )}
                    {this.props.exportToFile && (
                      // <button className='export-pdf' onClick={this.exportToPdf} />
                      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                        <img className={this.props.isExportInProgress?'export-pdf disable-click':'export-pdf pointer'} src={PDF} onClick={this.exportToPdf} alt='' />
                        <p>PDF</p>
                      </div>
                    )}
                  </div>
                  {this.props.isExportInProgress?<span style={{fontSize: '14px',fontWeight: 700,alignSelf: 'center',color:'#707176'}}>Report processing, please wait...</span>:''}
                </div>
              </Draggable>
            </DropdownMenu>

          </div>
        </div>
      </>
    );
  }
}
export default TravelersFilter;
