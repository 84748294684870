
const ROLES = {
  GLOBAL_ADMIN : 'GA',
  TRAVEL_AGENCY_ADMIN  :'TAA',
  TRAVEL_AGENCY_OPS_READONLY  :'OPSVIEW',
  TRAVEL_AGENT : 'TA',
  COMPANY_ADMIN : 'CA',
  COMPANY_USER : 'CU',

}

Object.freeze(ROLES);

export default ROLES;
