import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

function AdvisoryBox (props) {
  const { country } = props;
  const levelText = country.levelText.replace(':', ' -');
  const updateDate = moment(country.updated, 'MMM DD, YYYY').format('MMMM DD, YYYY');

  return (
    <div className='advisory-box'>
      <div className='mainbox'>
        <div className='heads'>
          <p className='countrytitle'>{country.title}</p>

        </div>


        <div className={'level level-' + country.level}>
          <em className={'bglevel level-' + country.level}>{levelText}</em>
          {/*<em className={'level level-' + country.level}></em>*/}

          <ul>
            {
              country.threads && country.threads.map(thr => (
                <li key={thr.ico}>
                  <a data-tip data-for={thr.ico}> {thr.ico + ' '}</a>
                  <ReactTooltip id={thr.ico} aria-haspopup='true' place="bottom" type="light" multiline={true} data-html={true}>
                  <span
                    className='extraClass'
                    dangerouslySetInnerHTML={{ __html: thr.text.replace(/([\w\s]+:)/, '<strong>$1</strong>') }} />
                  </ReactTooltip>
                </li>

              ))
            }
          </ul>
        </div>
        <p className='updatep'>Last update {updateDate}</p>
        <div dangerouslySetInnerHTML={{ __html: country.alertText }} />
      </div>
      <div className='sidebox-holder'>
        <div className='go-to-site'>
          <a href='https://travel.state.gov' target='_black'>
            Go to travel.state.gov
            {/* <i className='fas fa-external-link-alt' /> */}
          </a>
        </div>
        <div className='sidebox'>
          <div dangerouslySetInnerHTML={{ __html: country.notificationsHTML }} />
        </div>
      </div>
    </div>
  );
}

AdvisoryBox.propTypes = {
  country: PropTypes.object
};

export default AdvisoryBox;
