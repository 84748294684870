import React from 'react';
import './Home.css';
// import Message from './Message';
// import dash from '../../styles/images/dashboard.png'

function Home () {
  return (
     <>
    <div className='home'>
        <div className='second'>
 {/*---------------------Removed the message text ----------------------------------*/}
{/* <div className='msg'>
        <Message/>
        </div> */}
        </div>

    </div>
        </>
  );
}

export default Home;
