import PropTypes from 'prop-types';
import React from 'react';
import TravelersBox from './travelersBox';
import AdvisoryBox from './advisoryBox';
import close from '../../styles/images/Close.svg';
import { withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import userSettings from '../../helpers/userSettings';

import { stateAppSettingsDimensions } from '../../redux/modules/appSettings';
import { stateUser as stateCurrentUser } from '../../redux/modules/user';
import { createSelector } from 'reselect/lib/index';



class TravelAdvisoryPopup extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    country: PropTypes.object,
    onClose: PropTypes.func.isRequired
  };

  constructor (props) {
    super(props);
    this._advisory = {
      title: 'Advisory Info',
      isActive: true
    };
    if (props.country && props.country.travelers) {
      this._travelers = {
        title: `Travelers (${Object.keys(props.country.travelers).length})`,
        isActive: false
      };
    } else {
      this._travelers = null;
    }

    this.state = {
      navigation: {
        advisory: { ...this._advisory },
        travelers: { ...this._travelers }
      }
    };
  }

  UNSAFE_componentWillReceiveProps (props) {
    this.setState(() => {
      if (props.country && props.country.travelers) {
        this._travelers = {
          title: `Travelers (${Object.keys(props.country.travelers).length})`,
          isActive: false
        };
      } else {
        this._travelers = null;
      }
      return {
        navigation: {
          advisory: { ...this._advisory, isActive: true },
          travelers: { ...this._travelers }
        }
      };
    });
  }

  handleNav = e => {
    const { navigation } = this.state;
    const id = e.currentTarget.dataset.id;

    Object.keys(navigation).map(key => (navigation[key].isActive = key === id));
    this.setState({ navigation });
  };

  close = () => {
    this.props.onClose();
  };

  render () {
    const { country } = this.props;
    if (!country || !Object.keys(country).length) return null;

    const levelText = country.levelText.replace(/\d:/g, '-');

    const { navigation } = this.state;
    const items = Object.keys(navigation).map(key => (
      <li key={key} data-id={key} onClick={this.handleNav} className={navigation[key].isActive ? 'active' : ''}>
        {navigation[key].title}
      </li>
    ));

    const fromActiveTraveller = this.props.fromActiveTraveller;




    let content = '';
    if (navigation.travelers && navigation.travelers.isActive) {
      content = <TravelersBox country={country} user={this.props.user} />;
    }
    if (navigation.advisory && navigation.advisory.isActive) {
      content = <AdvisoryBox country={country} />;
    }

    let renderContent;

    if (fromActiveTraveller) {

      renderContent = (<div className='popup-travel-advisory' style={{
        border: '1px solid #E2E5E9',
        marginLeft: '-14px',
        marginTop: '1rem'
      }}>
        <div className='popup-travel-advisory-content'>
          <div className='contentbox'>{content}</div>
        </div>
      </div>);

    }
    else {

      renderContent = (
        <div className='popup-travel-advisory cover-screen'>
          <div className='popup-travel-advisory-content'>
            <div className='headbox'>
              <div className='heads'>
                <div className='title'>
                  <p className='country'>
                    {country.country} <em className={'level level-' + country.level}>{country.level}</em>
                  </p>
                  <p>{levelText}</p>
                </div>
                {/* <button className='btn-close' onClick={this.close}>
              </button> */}
                <img className='btn-close' onClick={this.close} src={close} alt="" width='40px' height='40px'/>
              </div>
              <div className='popup-menu'>
                <ul>{items}</ul>
              </div>
            </div>
            <div className='contentbox'>{content}</div>
          </div>
        </div>
      );

    }

    return renderContent
  }
}

export default withRouter(TravelAdvisoryPopup);

