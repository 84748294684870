/* eslint-disable max-len */
import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export default function UsersIcon (props) {
  return (
    <SvgIcon style={{ width: '24px', height: '24px',position:'absolute',top:'3px' }} {...props}>
        <path d="M4.78738607,0 C3.18962274,0 1.88976378,1.40895582 1.88976378,3.14078283 C1.88976378,4.87260983 3.18962274,6.28156566 4.78738607,6.28156566 C6.3851494,6.28156566 7.68503937,4.87260983 7.68503937,3.14078283 C7.68503937,1.40895582 6.38518041,0 4.78738607,0 Z" id="Path"></path>
        <path d="M11.4625098,0.376893939 C11.4558829,0.376893939 11.4491617,0.376893939 11.4425349,0.376956084 C10.763325,0.382517996 10.1289018,0.66623769 9.65616219,1.17591397 C9.19171409,1.67657934 8.93916965,2.33707978 8.94497996,3.03570573 C8.95082168,3.73433169 9.2143586,4.39060632 9.68706677,4.88365901 C10.1635124,5.38053357 10.7946379,5.65340909 11.46631,5.65340909 C11.4730625,5.65340909 11.4798151,5.65340909 11.486599,5.65334695 C12.1658088,5.64778503 12.8002321,5.36403427 13.2729717,4.85438906 C13.7374198,4.35372369 13.9899642,3.69322325 13.9841539,2.99456622 C13.9720622,1.54679102 12.8428201,0.376893939 11.4625098,0.376893939 Z" id="Path"></path>
        <path d="M11.6195956,6.28156566 L11.3772462,6.28156566 C10.2485722,6.28156566 9.21858689,6.70773141 8.44094488,7.40622143 C8.63551154,7.55447996 8.82070901,7.71607557 8.996225,7.89038791 C9.5140284,8.40464032 9.92065085,9.00387776 10.2048493,9.67135134 C10.4609403,10.2724498 10.6070995,10.9058053 10.6411409,11.5580808 L16,11.5580808 L16,10.63162 C16,8.23311946 14.0349705,6.28156566 11.6195956,6.28156566 Z" id="Path"></path>
        <path d="M9.69141919,11.5803089 C9.60304568,10.1530138 8.8813808,8.89759512 7.80434826,8.09300013 C7.02085315,7.50755553 6.04905691,7.16098485 4.99638173,7.16098485 L4.70471795,7.16098485 C2.10628717,7.16098485 0,9.27265535 0,11.8777273 L0,12.4375 L9.7007874,12.4375 L9.7007874,11.8777273 C9.7007874,11.7778573 9.69766466,11.6786135 9.69141919,11.5803089 Z" id="Path"></path>
    </SvgIcon>
  );
};
