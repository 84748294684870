import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createBrowserHistory } from 'history';
import i18n from './i18n';
import cookie from 'cookie';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import axios from 'axios';
import { loggedInUser } from './redux/modules/user';
import { localStorage } from './helpers/localStorage';
import config from './config';
import ReactGA from 'react-ga';
import createRootReducer from './redux/rootReducer';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { Provider } from 'react-redux';
import { routerMiddleware, ConnectedRouter } from 'connected-react-router';
import {getRoutes} from '../Routes'
import './styles/core.scss';
import {BrowserRouter,Route,Switch,Redirect} from 'react-router-dom'
import GuestLayout from './routes/layouts/GuestLayout';
import { isAdmin } from './helpers/user';
import { LoginView } from './views/LoginView/LoginView';
import Terms from './terms';
import Onboarding from './Onboarding';
import { AbilityContext } from './acl/Can';
import { ability } from './acl/ability';
import SetNewPasswordView from './views/SetNewPasswordView/SetNewPasswordView';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const appSettings = localStorage.get('appSettings');
const initialState = {
  appSettings
};

export const history = createBrowserHistory({
  basename: process.env.BASENAME
});
// const composeEnhancers = __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(createRootReducer(history),
  initialState,
  compose(
    applyMiddleware(thunk, routerMiddleware(history)))
);

ReactGA.initialize(config.googleAnalytics.apiKey);
history.listen((state) => {
  ReactGA.pageview(state.pathname);
});

const locale = cookie.parse(document.cookie).locale || config.locale.defaultLocale;

axios.get(`/lang/${locale}.json`).then((response) => {
  if (response.status >= 400) {
    throw new Error('Bad response from server');
  }
  return response.data;
}).then((localeData) => {
  const i18nTools = new i18n.Tools({ localeData, locale });
  // save translations
  localStorage.set('localeData', localeData);

  // check authorized user session
  const user = localStorage.get('user');
  if (user) {
    const sessionTime = new Date(user.sessionExpiration);
    sessionTime.setSeconds(sessionTime.getSeconds() + 300); // 5 min 
    const currentTime = new Date();
    console.log('time check',sessionTime, currentTime, currentTime < sessionTime);
    if (currentTime < sessionTime) {
      store.dispatch(loggedInUser(user));
    } else {
      localStorage.remove('user');
    }
  }

  // Now that we have the Redux store, we can create our routes. We provide
  // the store to the route definitions so that routes have access to it for
  // hooks such as `onEnter`.
  function routeslist(route,i) {
    return (<Route key={i} exact={route.subRoutes.some(r=>r.exact)} path={route.subRoutes.map(r=>r.path)}>
      <route.layout>
        {route.subRoutes.map((subRoute,i) => <Route key={i} {...subRoute} /> )}
      </route.layout>
    </Route>);
  }

  function App() {
    const state = store.getState();
    const { user, loading } = state;

    const admin = isAdmin(user.roleName);
    console.log(user);

    function content () {
      if (!user.isAuthenticated) {
        return (<><Route component={GuestLayout}/>
          {/*<Route path='/set-new-password/:code' component={SetNewPasswordView} />*/}



        </>);
      }
      else if (user.isAuthenticated) {
        if (user.eula && user.eula.status == 0) {

          return (<><Route path='/terms' component={Terms}/>
              <Route path='/onboarding' component={Onboarding}/>
              <Redirect to="/onboarding"/></>
          );
        }
        else {
          let routes = getRoutes(user.roleName)
          let Mappedroutes = routes.map(routeslist);
          Mappedroutes.push( <Redirect key={Mappedroutes.length} to="/not-found"/>);
          return Mappedroutes;
        }

      }
    }

    const RenderContent = content();
    console.log(RenderContent);



    return (
      <>
      <Switch>
        {RenderContent}
      </Switch>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        </>
    );
  }





  // Render the React application to the DOM
  ReactDOM.render(
    <i18n.Provider i18n={i18nTools}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <AbilityContext.Provider value={ability}>
            <App />
          </AbilityContext.Provider>
        </ConnectedRouter>
      </Provider>
    </i18n.Provider>,
    document.getElementById('root')
  );
});
