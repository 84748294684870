export default [
  {
    name: 'namerica',
    title: 'N.America',
    center: [-105.42, 56.49]
  },
  {
    name: 'samerica',
    title: 'S.America',
    center: [-58.84, -20.07]
  },
  {
    name: 'europe',
    title: 'Europe',
    center: [25.31, 54.90]
  },
  {
    name: 'asia',
    title: 'Asia',
    center: [96.08, 52.48]
  },
  {
    name: 'oceania',
    title: 'Oceania',
    center: [133.65, -15.91]
  },
  {
    name: 'africa',
    title: 'Africa',
    center: [26.17, 5.65]
  }
];
