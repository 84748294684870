import PropTypes from "prop-types";

import React from "react";

import { connect } from "react-redux";

import { createSelector } from "reselect";

import { bindActionCreators } from "redux";

import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";

import config from "../../config";
import {
  airportStatusesByTraveler,
  getAvailableAirportStatuses,
  airportStatusesStatusFilter,
  availableAirportTimeframes,
  airportStatusesTimeframeFilter,
  availableStatusesFilter
} from "../../helpers/airportStatusesFilters";
import {
  flightsStatusesByTraveler,
  flightsStatusesTimeframeFilter
} from "../../helpers/flightsStatusesFilters";
import { sessionStorage } from "../../helpers/localStorage";
import {
  drawAirportStatusesHelper,
  drawLocationsHelper,
  drawFlightStatusesArcsHelper,
  popupTabsInit,
  clearMap,
  createMap
} from "../../helpers/map";
import MapControls from "../../components/common/mapControls";
import {
  actions as airportStatusActions,
  stateAirportStatuses,
  stateTdAirportFilterStatus,
  stateTdAirportFilterTimeframe
} from "../../redux/modules/airportStatuses";
import {
  actions as flightStatusActions,
  stateFlightsStatuses,
  stateTdFlightStatusFilterTimeframe
} from "../../redux/modules/flightsStatusMap";
import {
  actions as travelerDetailsActions,
  stateTraveler,
  stateTravelerMapView
} from "../../redux/modules/travelerDetails";
import { stateUserRole } from "../../redux/modules/user";
import mapboxgl from "mapbox-gl/dist/mapbox-gl-csp";
import MapLevels from "../../components/common/mapLevels";
import { stateUserSettings } from '../../redux/modules/userSettings';
import { stateSidebar } from '../../redux/modules/sidebar';

const timeframes = config.map.timeframes;

const getFilterTimeFrame = (timeframe) => {
  return (timeframe === 30 ? 0.5 : timeframe === 90 ? 1.5 : timeframe / 60)
};


const mapStateToProps = createSelector(
  stateTraveler,
  stateTravelerMapView,
  stateAirportStatuses,
  stateTdAirportFilterStatus,
  stateTdAirportFilterTimeframe,
  stateFlightsStatuses,
  stateTdFlightStatusFilterTimeframe,
  stateUserRole,
  stateSidebar,
  stateUserSettings,
  (
    traveler,
    mapView,
    airportStatuses,
    filterAirportStatus,
    filterAirportTimeframe,
    flightsStatuses,
    filterFlightStatusTimeframe,
    userRole,
    sidebar,
    userSettings,
  ) => {
    // airport props
    console.log(
      airportStatuses,
      filterAirportStatus,
      filterAirportTimeframe,
      flightsStatuses,
      filterFlightStatusTimeframe,
      userRole,
      "PROPS"
    );



    if(!filterAirportTimeframe){
      filterAirportTimeframe = getFilterTimeFrame(userSettings.airportsWithin);
    }

    if(!filterFlightStatusTimeframe){
      filterFlightStatusTimeframe = getFilterTimeFrame(userSettings.flightsWithin);
    }


    let travelerAirportStatuses = [];
    // filter by airport status
    let availableAirportTimeframesOptions = timeframes;
    let availableAirportStatuses = [];
    if (mapView === "airportStatus") {
      console.log("INSIDE AIRPORT");
      // filter all status by traveler
      travelerAirportStatuses = airportStatusesByTraveler(
        airportStatuses,
        traveler
      );
      console.log(travelerAirportStatuses, "CONSOE1");
      // get available statuses for select field
      availableAirportStatuses = getAvailableAirportStatuses(
        travelerAirportStatuses
      );
      if (filterAirportStatus && travelerAirportStatuses.length) {
        // filter airports by status
        travelerAirportStatuses = airportStatusesStatusFilter(
          travelerAirportStatuses,
          filterAirportStatus
        );
        availableAirportTimeframesOptions = availableAirportTimeframes(
          timeframes,
          travelerAirportStatuses
        );
      }
      // filter by traveler presence timeframe
      if (filterAirportTimeframe && travelerAirportStatuses.length) {
        // filter airports by timeframe
        travelerAirportStatuses = airportStatusesTimeframeFilter(
          travelerAirportStatuses,
          filterAirportTimeframe
        );
        availableAirportStatuses = availableStatusesFilter(
          availableAirportStatuses,
          travelerAirportStatuses
        );
      }
    }
    const availableFlightStatusTimeframesOptions = timeframes;
    if (mapView === "flightsStatus" && flightsStatuses) {
      if (flightsStatuses.length) {
        flightsStatuses = flightsStatusesByTraveler(flightsStatuses, traveler);
      }
      // filter by traveler presence timeframe
      if (filterFlightStatusTimeframe && flightsStatuses.length) {
        flightsStatuses = flightsStatusesTimeframeFilter(
          flightsStatuses,
          filterFlightStatusTimeframe
        );
      }
    }

    return {
      traveler,
      mapView,
      travelerAirportStatuses,
      availableAirportStatuses,
      availableAirportTimeframes: availableAirportTimeframesOptions,
      filterAirportStatus,
      filterAirportTimeframe,
      flightsStatuses,
      filterFlightStatusTimeframe,
      userRole,
      sidebar,
      availableFlightsStatusesTimeframes: availableFlightStatusTimeframesOptions
    };
  }
);

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(travelerDetailsActions, dispatch),
    ...bindActionCreators(airportStatusActions, dispatch),
    ...bindActionCreators(flightStatusActions, dispatch)
  };
};

class MapContainer extends React.Component {
  static propTypes = {
    traveler: PropTypes.object.isRequired,
    travelerAirportStatuses: PropTypes.array.isRequired,
    availableAirportStatuses: PropTypes.array.isRequired,
    availableAirportTimeframes: PropTypes.array.isRequired,
    filterAirportStatus: PropTypes.string.isRequired,
    filterAirportTimeframe: PropTypes.number.isRequired,
    filterFlightStatusTimeframe: PropTypes.number.isRequired,
    flightsStatuses: PropTypes.array.isRequired,
    availableFlightsStatusesTimeframes: PropTypes.array.isRequired,
    mapView: PropTypes.string.isRequired,
    userRole: PropTypes.string.isRequired,
    travelerSwitchMapView: PropTypes.func.isRequired,
    changeTdAirportStatusFilter: PropTypes.func.isRequired,
    changeTdAirportTimeframeFilter: PropTypes.func.isRequired,
    changeTdFlightStatusTimeframeFilter: PropTypes.func.isRequired
  };

  static contextTypes = {
    i18n: PropTypes.object
  };

  constructor(props) {
    super(props);
    this._map = null;
    this._markerClusterGroup = null;
    this._airportMarkers = [];
    this._flightsArcs = null;
    this.state = {
      pinnedFlightPopup: true
    };
    this._popup = new mapboxgl.Popup({
      closeButton: true,
      closeOnClick: false,
      offset: 25,
      maxWidth:"360px"
    });
  }

  clearMap(from) {
    const result = clearMap(
      this._map,
      this._markerClusterGroup,
      this._airportMarkers,
      this._flightsArcs,
      !!this.state.pinnedFlightPopup,
      from
    );
    this._markerClusterGroup = result.markerClusterGroup;
    this._airportMarkers = result.airportMarkers;
    this._flightsArcs = result.flightsArcs;
    this._popup.remove();
  }

  drawLocations() {
    // draw locations markers
    this._markerClusterGroup = drawLocationsHelper(
      [],
      //replace dummy data with this.props.traveler
      [this.props.traveler],
      this._map,
      this.props.userRole,
      this._popup
    );
  }



  drawAirportStatuses() {
    // draw airportStatus markers
    console.log(this.props.traveler);
    this._airportMarkers = drawAirportStatusesHelper(
      this._airportMarkers,
      this.props.travelerAirportStatuses,
      this._map,
      this.props.filterAirportTimeframe,
      "active-traveler",
      this._popup
    );
  }

  drawFlightsStatuses() {
    // draw airportStatus markers

    this._flightsArcs = drawFlightStatusesArcsHelper(
      this.props.flightsStatuses,
      [this.props.traveler], // filtered travelers
      this._map,
      this.props.userRole,
      this._popup
    );
  }

  populateMap() {
    this.clearMap(this.props.mapView);
    if (this.props.mapView === "flightsStatus") {
      this.drawFlightsStatuses();
    } else if (this.props.mapView === "airportStatus") {
      this.drawAirportStatuses();
    } else {
      this.drawLocations();
    }
  }

  componentDidUpdate() {
    if(this._map.loaded()){
      this.populateMap();
    } else {
      this._map.on('load', () => {
        this.populateMap();
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    let shouldUpdate =  (
      !isEqual(this.props.traveler, nextProps.traveler) ||
      !isEqual(this.props.flightsStatuses, nextProps.flightsStatuses) ||
      !isEqual(
        this.props.travelerAirportStatuses,
        nextProps.travelerAirportStatuses
      ) ||
      !isEqual(
        this.props.availableAirportStatuses,
        nextProps.availableAirportStatuses
      ) ||
      !isEqual(
        this.props.filterAirportTimeframe,
        nextProps.filterAirportTimeframe
      ) ||
      !isEqual(this.props.filterAirportStatus, nextProps.filterAirportStatus) ||
      !isEqual(
        this.props.filterFlightStatusTimeframe,
        nextProps.filterFlightStatusTimeframe
      ) ||
      !isEqual(this.props.mapView, nextProps.mapView)

    );
    if (!shouldUpdate) {
      if (!isEqual(this.props.sidebar, nextProps.sidebar)) {
        if (this._map) {
          // console.log('Now Resizing Two Maps');
          setTimeout(() => {
              this._map.resize();
            },
            250
          );
        }
      }
    }
    return shouldUpdate;

  }

  shouldShowAnotherPopup = () => {
    return !this.state.pinnedFlightPopup;
  };

  pineFlightPopup = (line1, line2) => {
    this.setState({
      pinnedFlightPopup: true
    });
  };

  componentDidMount() {
    this._map = createMap(
      "travelerMap",
      sessionStorage.get("TdLng") || config.map.defaultLon,
      sessionStorage.get("TdLat") || config.map.defaultLat,
      sessionStorage.get("TdZoom") || config.map.minZoom
    );

    this._map.on('load', () => {
      this.populateMap();
    });

    // add debounce for updates to prevent memory leak
    const setCoords = debounce(() => {
      const center = this._map.getCenter();
      sessionStorage.set("TdLat", center.lat);
      sessionStorage.set("TdLng", center.lng);
    }, 300);
    this._map.on("move", () => {
      setCoords();
    });
    this._map.on("click", () => {
      this.setState({
        pinnedFlightPopup: false
      });
    });
    this._map.on(
      "zoomend",
      debounce(() => {
        // TODO: mapbox-gl POPUP
        // this._map.closePopup();
        this.setState({
          pinnedFlightPopup: false
        });
        let nextZoom = this._map.getZoom();
        if (nextZoom > config.map.maxZoom) nextZoom = config.map.maxZoom;
        if (nextZoom < config.map.minZoom) nextZoom = config.map.minZoom;
        sessionStorage.set("TdZoom", nextZoom);
      }, 200)
    );
    popupTabsInit();
  }

  componentWillUnmount() {
    this._map.remove();
    this._map = null;
    this._markerClusterGroup = null;
    this._airportMarkers = [];
    this._flightsArcs = null;
  }

  render() {
    return (
      <div className="travelersMap">
        <MapControls
          airportStatuses={this.props.availableAirportStatuses}
          airportTimeframes={this.props.availableAirportTimeframes}
          flightStatusTimeframes={this.props.availableFlightsStatusesTimeframes}
          filterAirportTimeframe={this.props.filterAirportTimeframe}
          filterAirportStatus={this.props.filterAirportStatus}
          filterFlightStatusTimeframe={this.props.filterFlightStatusTimeframe}
          mapView={this.props.mapView}
          switchMapView={this.props.travelerSwitchMapView}
          changeAirportStatusFilter={this.props.changeTdAirportStatusFilter}
          changeAirportTimeframeFilter={
            this.props.changeTdAirportTimeframeFilter
          }
          changeFlightStatusTimeframeFilter={
            this.props.changeTdFlightStatusTimeframeFilter
          }
        />
        <div id="travelerMap" className="map" />
        <MapLevels from={this.props.mapView}/>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapContainer);
