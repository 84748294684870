export const airport = [
  {
    _id: {
      $oid: "5734d519e4b0cb7a248e232c"
    },
    code: "EWR",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1618172409952
      }
    },
    travelers: [
      {
        _id: {
          $oid: "5ef3b716a95be8b3039641d1"
        },
        departureDateTime: {
          $date: 1618572600000
        },
        arrivalDateTime: {
          $date: 1618576680000
        }
      }
    ],
    geoLocation: {
      latitude: 40.70000076,
      longitude: -74.16666412
    },
    language: [
      {
        type: "en_us",
        name: "Newark Liberty International",
        fullName: "Newark NJ, USA",
        locations: [
          {
            city: "Newark",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "NJ"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc761fe4b05395d65d6d54"
    },
    code: "TRI",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613453710206
      }
    },
    travelers: [
      {
        _id: {
          $oid: "6053f905ec62986255155f68"
        },
        departureDateTime: {
          $date: 1618590000000
        },
        arrivalDateTime: {
          $date: 1618594380000
        }
      }
    ],
    geoLocation: {
      latitude: 36.46666718,
      longitude: -82.40000153
    },
    language: [
      {
        type: "en_us",
        name: "Tri-cities Regional",
        fullName: "Tri Citites Regional, TN, USA",
        locations: [
          {
            city: "Blountville",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "TN"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc761ae4b05395d65d6d14"
    },
    code: "SPS",
    status: {
      reason: "No known delays for this airport.",
      closureBegin: "",
      endTime: "",
      minDelay: "",
      avgDelay: "",
      maxDelay: "",
      closureEnd: "",
      trend: "",
      type: "",
      color: "green",
      createdDateTime: {
        $date: 1459778081023
      }
    },
    travelers: [
      {
        _id: {
          $oid: "604a711a50f3e48514bab111"
        },
        departureDateTime: {
          $date: 1618624140000
        },
        arrivalDateTime: {
          $date: 1618627920000
        }
      }
    ],
    geoLocation: {
      latitude: 33.91370773,
      longitude: -98.49338531
    },
    language: [
      {
        type: "en_us",
        name: "Sheppard AFB",
        fullName: "Wichita Falls TX, USA",
        locations: [
          {
            city: "Wichita Falls",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "TX"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc74a5e4b05395d65d5b9a"
    },
    code: "TVC",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613453710963
      }
    },
    travelers: [
      {
        _id: {
          $oid: "5f591374a95be8b303a9dccf"
        },
        departureDateTime: {
          $date: 1618611600000
        },
        arrivalDateTime: {
          $date: 1618615260000
        }
      }
    ],
    geoLocation: {
      latitude: 44.73333359,
      longitude: -85.58333588
    },
    language: [
      {
        type: "en_us",
        name: "Cherry Capital Airport",
        fullName: "Traverse City MI, USA",
        locations: [
          {
            city: "Traverse City",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "MI"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc7493e4b05395d65d5ab4"
    },
    code: "TPA",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1618160710174
      }
    },
    travelers: [
      {
        _id: {
          $oid: "606bc92348c73cf513ff0e2e"
        },
        departureDateTime: {
          $date: 1618571700000
        },
        arrivalDateTime: {
          $date: 1618580700000
        }
      }
    ],
    geoLocation: {
      latitude: 27.96666718,
      longitude: -82.53333282
    },
    language: [
      {
        type: "en_us",
        name: "Tampa International",
        fullName: "Tampa Intl FL, USA",
        locations: [
          {
            city: "Tampa",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "FL"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc7445e4b05395d65d56bc"
    },
    code: "ATL",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1617289510064
      }
    },
    travelers: [
      {
        _id: {
          $oid: "5e9763f7a95be8b303d5a2cf"
        },
        departureDateTime: {
          $date: 1618618800000
        },
        arrivalDateTime: {
          $date: 1618668600000
        }
      },
      {
        _id: {
          $oid: "6053f905ec62986255155f68"
        },
        departureDateTime: {
          $date: 1618601400000
        },
        arrivalDateTime: {
          $date: 1618611480000
        }
      },
      {
        _id: {
          $oid: "6053f905ec62986255155f68"
        },
        departureDateTime: {
          $date: 1618590000000
        },
        arrivalDateTime: {
          $date: 1618594380000
        }
      }
    ],
    geoLocation: {
      latitude: 33.63333511,
      longitude: -84.43333435
    },
    language: [
      {
        type: "en_us",
        name: "Hartsfield-jackson Atlanta International",
        fullName: "Atlanta GA, USA",
        locations: [
          {
            city: "Atlanta",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "GA"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc738be4b05395d65d5662"
    },
    code: "STL",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613453711903
      }
    },
    travelers: [
      {
        _id: {
          $oid: "5ec7d8f0a95be8b303112a63"
        },
        departureDateTime: {
          $date: 1618608900000
        },
        arrivalDateTime: {
          $date: 1618613100000
        }
      },
      {
        _id: {
          $oid: "5fa970891aeefc68292f27d2"
        },
        departureDateTime: {
          $date: 1618623300000
        },
        arrivalDateTime: {
          $date: 1618629720000
        }
      }
    ],
    geoLocation: {
      latitude: 38.75,
      longitude: -90.3666687
    },
    language: [
      {
        type: "en_us",
        name: "Lambert-St. Louis International",
        fullName: "St Louis Intl MO, USA",
        locations: [
          {
            city: "St Louis",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "MO"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc7374e4b05395d65d5530"
    },
    code: "SNA",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613453712165
      }
    },
    travelers: [
      {
        _id: {
          $oid: "5eea3efba95be8b3036cd1f0"
        },
        departureDateTime: {
          $date: 1618623300000
        },
        arrivalDateTime: {
          $date: 1618631880000
        }
      }
    ],
    geoLocation: {
      latitude: 33.68333435,
      longitude: -117.8666687
    },
    language: [
      {
        type: "en_us",
        name: "John Wayne",
        fullName: "Santa Ana CA, USA",
        locations: [
          {
            city: "Santa Ana",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "CA"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc7365e4b05395d65d546c"
    },
    code: "SJC",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613453712466
      }
    },
    travelers: [
      {
        _id: {
          $oid: "605d1f47640b730e27205024"
        },
        departureDateTime: {
          $date: 1618619700000
        },
        arrivalDateTime: {
          $date: 1618625100000
        }
      }
    ],
    geoLocation: {
      latitude: 37.36666489,
      longitude: -121.9333344
    },
    language: [
      {
        type: "en_us",
        name: "Mineta San Jose International Airport",
        fullName: "San Jose CA, USA",
        locations: [
          {
            city: "San Jose",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "CA"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc7359e4b05395d65d53d8"
    },
    code: "SGF",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613453712601
      }
    },
    travelers: [
      {
        _id: {
          $oid: "6042d4fa50f3e4851444fbf8"
        },
        departureDateTime: {
          $date: 1618606800000
        },
        arrivalDateTime: {
          $date: 1618612620000
        }
      },
      {
        _id: {
          $oid: "6046ca0250f3e4851480f55a"
        },
        departureDateTime: {
          $date: 1618606800000
        },
        arrivalDateTime: {
          $date: 1618612620000
        }
      }
    ],
    geoLocation: {
      latitude: 37.25,
      longitude: -93.3833313
    },
    language: [
      {
        type: "en_us",
        name: "Springfield-Branson Rg",
        fullName: "Springfield MO, USA",
        locations: [
          {
            city: "Springfield",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "MO"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc7356e4b05395d65d53b8"
    },
    code: "SFO",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1617663010604
      }
    },
    travelers: [
      {
        _id: {
          $oid: "5fc6964c1aeefc682956f1f0"
        },
        departureDateTime: {
          $date: 1618598640000
        },
        arrivalDateTime: {
          $date: 1618607340000
        }
      },
      {
        _id: {
          $oid: "6067738e48c73cf513b94080"
        },
        departureDateTime: {
          $date: 1618623900000
        },
        arrivalDateTime: {
          $date: 1618630080000
        }
      },
      {
        _id: {
          $oid: "5f14ee05a95be8b3031c3321"
        },
        departureDateTime: {
          $date: 1618595220000
        },
        arrivalDateTime: {
          $date: 1618599840000
        }
      }
    ],
    geoLocation: {
      latitude: 37.61666489,
      longitude: -122.3833313
    },
    language: [
      {
        type: "en_us",
        name: "San Francisco International",
        fullName: "San Francisco CA, USA",
        locations: [
          {
            city: "San Francisco",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "CA"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc7350e4b05395d65d536e"
    },
    code: "SEA",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1616961010867
      }
    },
    travelers: [
      {
        _id: {
          $oid: "5fc6964c1aeefc682956f1f0"
        },
        departureDateTime: {
          $date: 1618598640000
        },
        arrivalDateTime: {
          $date: 1618607340000
        }
      },
      {
        _id: {
          $oid: "5fc6964c1aeefc682956f1f0"
        },
        departureDateTime: {
          $date: 1618581600000
        },
        arrivalDateTime: {
          $date: 1618594740000
        }
      }
    ],
    geoLocation: {
      latitude: 47.45000076,
      longitude: -122.3000031
    },
    language: [
      {
        type: "en_us",
        name: "Seattle-Tacoma International",
        fullName: "Seattle/Tacoma WA, USA",
        locations: [
          {
            city: "Seattle",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "WA"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc734fe4b05395d65d535c"
    },
    code: "SDQ",
    status: {
      reason: "No known delays for this airport.",
      closureBegin: "",
      endTime: "",
      minDelay: "",
      avgDelay: "",
      maxDelay: "",
      closureEnd: "",
      trend: "",
      type: "",
      color: "green",
      createdDateTime: {
        $date: 1459778081023
      }
    },
    travelers: [
      {
        _id: {
          $oid: "606d054148c73cf513153b8e"
        },
        departureDateTime: {
          $date: 1618682220000
        },
        arrivalDateTime: {
          $date: 1618691580000
        }
      }
    ],
    geoLocation: {
      latitude: 18.42944527,
      longitude: -69.66860962
    },
    language: [
      {
        type: "en_us",
        name: "Las Americas",
        fullName: "Santo Domingo, Dominican Repub",
        locations: [
          {
            city: "Santo Domingo",
            countryCode: "DO",
            countryFull: "Dominican Republic"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc732fe4b05395d65d51ce"
    },
    code: "ROC",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613453714419
      }
    },
    travelers: [
      {
        _id: {
          $oid: "6075939848c73cf513b0c53b"
        },
        departureDateTime: {
          $date: 1618585800000
        },
        arrivalDateTime: {
          $date: 1618590300000
        }
      },
      {
        _id: {
          $oid: "6070eb7148c73cf5135c5e79"
        },
        departureDateTime: {
          $date: 1618585500000
        },
        arrivalDateTime: {
          $date: 1618590000000
        }
      },
      {
        _id: {
          $oid: "606244ac48c73cf513609b02"
        },
        departureDateTime: {
          $date: 1618585800000
        },
        arrivalDateTime: {
          $date: 1618590300000
        }
      },
      {
        _id: {
          $oid: "6061eff348c73cf51359b927"
        },
        departureDateTime: {
          $date: 1618585800000
        },
        arrivalDateTime: {
          $date: 1618590300000
        }
      }
    ],
    geoLocation: {
      latitude: 43.11666489,
      longitude: -77.66666412
    },
    language: [
      {
        type: "en_us",
        name: "Greater Rochester International",
        fullName: "Rochester NY, USA",
        locations: [
          {
            city: "Rochester",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "NY"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc731be4b05395d65d50d6"
    },
    code: "RDU",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613453715365
      }
    },
    travelers: [
      {
        _id: {
          $oid: "6075939848c73cf513b0c53b"
        },
        departureDateTime: {
          $date: 1618577700000
        },
        arrivalDateTime: {
          $date: 1618581900000
        }
      },
      {
        _id: {
          $oid: "6070eb7148c73cf5135c5e79"
        },
        departureDateTime: {
          $date: 1618577400000
        },
        arrivalDateTime: {
          $date: 1618581600000
        }
      },
      {
        _id: {
          $oid: "606244ac48c73cf513609b02"
        },
        departureDateTime: {
          $date: 1618577700000
        },
        arrivalDateTime: {
          $date: 1618581900000
        }
      },
      {
        _id: {
          $oid: "6061eff348c73cf51359b927"
        },
        departureDateTime: {
          $date: 1618577700000
        },
        arrivalDateTime: {
          $date: 1618581900000
        }
      },
      {
        _id: {
          $oid: "6053beb9ec6298625511bab4"
        },
        departureDateTime: {
          $date: 1618660800000
        },
        arrivalDateTime: {
          $date: 1618664700000
        }
      },
      {
        _id: {
          $oid: "6053beb9ec6298625511bab4"
        },
        departureDateTime: {
          $date: 1618599600000
        },
        arrivalDateTime: {
          $date: 1618604040000
        }
      }
    ],
    geoLocation: {
      latitude: 35.88333511,
      longitude: -78.78333282
    },
    language: [
      {
        type: "en_us",
        name: "Raleigh-durham International Airport",
        fullName: "Raleigh/Durham NC, USA",
        locations: [
          {
            city: "Raleigh/Durham",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "NC"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc72ece4b05395d65d4e6e"
    },
    code: "PNS",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613453716791
      }
    },
    travelers: [
      {
        _id: {
          $oid: "6063ca3d48c73cf5137b3460"
        },
        departureDateTime: {
          $date: 1618678500000
        },
        arrivalDateTime: {
          $date: 1618684200000
        }
      },
      {
        _id: {
          $oid: "6042d4fa50f3e4851444fbf8"
        },
        departureDateTime: {
          $date: 1618588500000
        },
        arrivalDateTime: {
          $date: 1618593600000
        }
      },
      {
        _id: {
          $oid: "5f57e1a3a95be8b303a13dc8"
        },
        departureDateTime: {
          $date: 1618608120000
        },
        arrivalDateTime: {
          $date: 1618617240000
        }
      }
    ],
    geoLocation: {
      latitude: 30.46666718,
      longitude: -87.18333435
    },
    language: [
      {
        type: "en_us",
        name: "Regional",
        fullName: "Pensacola FL, USA",
        locations: [
          {
            city: "Pensacola",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "FL"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc72dae4b05395d65d4d80"
    },
    code: "PHX",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1618529713533
      }
    },
    travelers: [
      {
        _id: {
          $oid: "606e50c248c73cf5132c4bff"
        },
        departureDateTime: {
          $date: 1618677720000
        },
        arrivalDateTime: {
          $date: 1618688340000
        }
      },
      {
        _id: {
          $oid: "6047648150f3e4851489731b"
        },
        departureDateTime: {
          $date: 1618589100000
        },
        arrivalDateTime: {
          $date: 1618596000000
        }
      },
      {
        _id: {
          $oid: "5f2090e1a95be8b303581e0d"
        },
        departureDateTime: {
          $date: 1618647300000
        },
        arrivalDateTime: {
          $date: 1618669140000
        }
      },
      {
        _id: {
          $oid: "5fc7bc601aeefc6829e08989"
        },
        departureDateTime: {
          $date: 1618647300000
        },
        arrivalDateTime: {
          $date: 1618669140000
        }
      }
    ],
    geoLocation: {
      latitude: 33.43333435,
      longitude: -112.0166702
    },
    language: [
      {
        type: "en_us",
        name: "Sky Harbor Intl",
        fullName: "Phoenix AZ, USA",
        locations: [
          {
            city: "Phoenix",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "AZ"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc72d8e4b05395d65d4d6e"
    },
    code: "PHL",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1618052712539
      }
    },
    travelers: [
      {
        _id: {
          $oid: "5f21e5e2a95be8b3035fe088"
        },
        departureDateTime: {
          $date: 1618687800000
        },
        arrivalDateTime: {
          $date: 1618695900000
        }
      },
      {
        _id: {
          $oid: "5f810d1ba95be8b303c48a21"
        },
        departureDateTime: {
          $date: 1618588080000
        },
        arrivalDateTime: {
          $date: 1618594800000
        }
      },
      {
        _id: {
          $oid: "5f57e1a3a95be8b303a13dc8"
        },
        departureDateTime: {
          $date: 1618608120000
        },
        arrivalDateTime: {
          $date: 1618617240000
        }
      },
      {
        _id: {
          $oid: "6052a85eec62986255ffd6d7"
        },
        departureDateTime: {
          $date: 1618595700000
        },
        arrivalDateTime: {
          $date: 1618603560000
        }
      }
    ],
    geoLocation: {
      latitude: 39.88333511,
      longitude: -75.23332977
    },
    language: [
      {
        type: "en_us",
        name: "Philadelphia International",
        fullName: "Philadelphia PA, USA",
        locations: [
          {
            city: "Philadelphia",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "PA"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc72cee4b05395d65d4cee"
    },
    code: "PDX",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613453718440
      }
    },
    travelers: [
      {
        _id: {
          $oid: "6067738e48c73cf513b94080"
        },
        departureDateTime: {
          $date: 1618623900000
        },
        arrivalDateTime: {
          $date: 1618630080000
        }
      },
      {
        _id: {
          $oid: "6046ca0350f3e4851480f582"
        },
        departureDateTime: {
          $date: 1618594200000
        },
        arrivalDateTime: {
          $date: 1618603980000
        }
      },
      {
        _id: {
          $oid: "604fc593ec62986255cc4763"
        },
        departureDateTime: {
          $date: 1618594200000
        },
        arrivalDateTime: {
          $date: 1618603980000
        }
      }
    ],
    geoLocation: {
      latitude: 45.58333206,
      longitude: -122.5999985
    },
    language: [
      {
        type: "en_us",
        name: "Portland International",
        fullName: "Portland OR, USA",
        locations: [
          {
            city: "Portland",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "OR"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc72b0e4b05395d65d4b70"
    },
    code: "ORD",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1618326314206
      }
    },
    travelers: [
      {
        _id: {
          $oid: "5eea3efba95be8b3036cd1f4"
        },
        departureDateTime: {
          $date: 1618609800000
        },
        arrivalDateTime: {
          $date: 1618618860000
        }
      },
      {
        _id: {
          $oid: "5f591374a95be8b303a9dccf"
        },
        departureDateTime: {
          $date: 1618611600000
        },
        arrivalDateTime: {
          $date: 1618615260000
        }
      },
      {
        _id: {
          $oid: "5f591374a95be8b303a9dccf"
        },
        departureDateTime: {
          $date: 1618599420000
        },
        arrivalDateTime: {
          $date: 1618608000000
        }
      },
      {
        _id: {
          $oid: "5f21e5e2a95be8b3035fe088"
        },
        departureDateTime: {
          $date: 1618687800000
        },
        arrivalDateTime: {
          $date: 1618695900000
        }
      },
      {
        _id: {
          $oid: "5f21e5e2a95be8b3035fe088"
        },
        departureDateTime: {
          $date: 1618668000000
        },
        arrivalDateTime: {
          $date: 1618680300000
        }
      },
      {
        _id: {
          $oid: "5ef3b531a95be8b303963188"
        },
        departureDateTime: {
          $date: 1618581000000
        },
        arrivalDateTime: {
          $date: 1618589760000
        }
      },
      {
        _id: {
          $oid: "5ef3b531a95be8b303963188"
        },
        departureDateTime: {
          $date: 1618569000000
        },
        arrivalDateTime: {
          $date: 1618577400000
        }
      },
      {
        _id: {
          $oid: "6052a85eec62986255ffd6d7"
        },
        departureDateTime: {
          $date: 1618595700000
        },
        arrivalDateTime: {
          $date: 1618603560000
        }
      },
      {
        _id: {
          $oid: "6052a85eec62986255ffd6d7"
        },
        departureDateTime: {
          $date: 1618586880000
        },
        arrivalDateTime: {
          $date: 1618592700000
        }
      }
    ],
    geoLocation: {
      latitude: 41.98333359,
      longitude: -87.90000153
    },
    language: [
      {
        type: "en_us",
        name: "Chicago O'hare International",
        fullName: "Chicago O'Hare IL, USA",
        locations: [
          {
            city: "Chicago",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "IL"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc72a2e4b05395d65d4aba"
    },
    code: "OKC",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1618357812746
      }
    },
    travelers: [
      {
        _id: {
          $oid: "5f184880a95be8b3032cc6a6"
        },
        departureDateTime: {
          $date: 1618617720000
        },
        arrivalDateTime: {
          $date: 1618624020000
        }
      },
      {
        _id: {
          $oid: "6064ae8748c73cf5138a3c19"
        },
        departureDateTime: {
          $date: 1618617720000
        },
        arrivalDateTime: {
          $date: 1618624020000
        }
      },
      {
        _id: {
          $oid: "5ef3b531a95be8b303963188"
        },
        departureDateTime: {
          $date: 1618581000000
        },
        arrivalDateTime: {
          $date: 1618589760000
        }
      }
    ],
    geoLocation: {
      latitude: 35.40000153,
      longitude: -97.59999847
    },
    language: [
      {
        type: "en_us",
        name: "Will Rogers World Airport",
        fullName: "Oklahoma City OK, USA",
        locations: [
          {
            city: "Oklahoma City",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "OK"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc729ee4b05395d65d4a8e"
    },
    code: "OGG",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613453719818
      }
    },
    travelers: [
      {
        _id: {
          $oid: "5fc7bc601aeefc6829e08989"
        },
        departureDateTime: {
          $date: 1618554900000
        },
        arrivalDateTime: {
          $date: 1618557600000
        }
      },
      {
        _id: {
          $oid: "60393a3250f3e48514b4c836"
        },
        departureDateTime: {
          $date: 1618554900000
        },
        arrivalDateTime: {
          $date: 1618557600000
        }
      },
      {
        _id: {
          $oid: "5ee8f84ba95be8b303681ca5"
        },
        departureDateTime: {
          $date: 1618554900000
        },
        arrivalDateTime: {
          $date: 1618557600000
        }
      },
      {
        _id: {
          $oid: "5f2090e1a95be8b303581e0d"
        },
        departureDateTime: {
          $date: 1618554900000
        },
        arrivalDateTime: {
          $date: 1618557600000
        }
      }
    ],
    geoLocation: {
      latitude: 20.89861107,
      longitude: -156.4305573
    },
    language: [
      {
        type: "en_us",
        name: "Kahului",
        fullName: "Kahului Maui HI, USA",
        locations: [
          {
            city: "Maui",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "HI"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc7295e4b05395d65d4a1a"
    },
    code: "OAK",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613453719876
      }
    },
    travelers: [
      {
        _id: {
          $oid: "6047648150f3e4851489731b"
        },
        departureDateTime: {
          $date: 1618589100000
        },
        arrivalDateTime: {
          $date: 1618596000000
        }
      }
    ],
    geoLocation: {
      latitude: 37.73333359,
      longitude: -122.2166672
    },
    language: [
      {
        type: "en_us",
        name: "Oakland International Airport",
        fullName: "Oakland CA, USA",
        locations: [
          {
            city: "Oakland",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "CA"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc7239e4b05395d65d46d4"
    },
    code: "MTY",
    status: {
      reason: "No known delays for this airport.",
      closureBegin: "",
      endTime: "",
      minDelay: "",
      avgDelay: "",
      maxDelay: "",
      closureEnd: "",
      trend: "",
      type: "",
      color: "green",
      createdDateTime: {
        $date: 1459778081023
      }
    },
    travelers: [
      {
        _id: {
          $oid: "5f21e5e2a95be8b3035fe088"
        },
        departureDateTime: {
          $date: 1618668000000
        },
        arrivalDateTime: {
          $date: 1618680300000
        }
      }
    ],
    geoLocation: {
      latitude: 25.77833366,
      longitude: -100.1066666
    },
    language: [
      {
        type: "en_us",
        name: "Gen Mariano Escobedo",
        fullName: "Monterrey Mex MTY NL, Mexico",
        locations: [
          {
            city: "Monterrey",
            countryCode: "MX",
            countryFull: "Mexico"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc7234e4b05395d65d4692"
    },
    code: "MSP",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1617069914587
      }
    },
    travelers: [
      {
        _id: {
          $oid: "6053f905ec62986255155f68"
        },
        departureDateTime: {
          $date: 1618619100000
        },
        arrivalDateTime: {
          $date: 1618623300000
        }
      },
      {
        _id: {
          $oid: "6053f905ec62986255155f68"
        },
        departureDateTime: {
          $date: 1618601400000
        },
        arrivalDateTime: {
          $date: 1618611480000
        }
      },
      {
        _id: {
          $oid: "6042d4fa50f3e4851444fbf8"
        },
        departureDateTime: {
          $date: 1618572000000
        },
        arrivalDateTime: {
          $date: 1618582800000
        }
      }
    ],
    geoLocation: {
      latitude: 44.88333511,
      longitude: -93.21666718
    },
    language: [
      {
        type: "en_us",
        name: "Minneapolis - St. Paul Intl",
        fullName: "Minneapolis St Pl MN, USA",
        locations: [
          {
            city: "Minneapolis",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "MN"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc7214e4b05395d65d44f2"
    },
    code: "MKE",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613453722864
      }
    },
    travelers: [
      {
        _id: {
          $oid: "5f3a9b6ea95be8b303e481c7"
        },
        departureDateTime: {
          $date: 1618574700000
        },
        arrivalDateTime: {
          $date: 1618581300000
        }
      }
    ],
    geoLocation: {
      latitude: 42.95000076,
      longitude: -87.90000153
    },
    language: [
      {
        type: "en_us",
        name: "General Mitchell International",
        fullName: "Milwaukee WI, USA",
        locations: [
          {
            city: "Milwaukee",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "WI"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc720be4b05395d65d448a"
    },
    code: "MIA",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1618242615132
      }
    },
    travelers: [
      {
        _id: {
          $oid: "606d054148c73cf513153b8e"
        },
        departureDateTime: {
          $date: 1618682220000
        },
        arrivalDateTime: {
          $date: 1618691580000
        }
      }
    ],
    geoLocation: {
      latitude: 25.79999924,
      longitude: -80.28333282
    },
    language: [
      {
        type: "en_us",
        name: "Miami International Airport",
        fullName: "Miami FL, USA",
        locations: [
          {
            city: "Miami",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "FL"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc71fae4b05395d65d43be"
    },
    code: "MDW",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613453723611
      }
    },
    travelers: [
      {
        _id: {
          $oid: "5ec7d8f0a95be8b303112a63"
        },
        departureDateTime: {
          $date: 1618608900000
        },
        arrivalDateTime: {
          $date: 1618613100000
        }
      }
    ],
    geoLocation: {
      latitude: 41.78333282,
      longitude: -87.75
    },
    language: [
      {
        type: "en_us",
        name: "Midway Airport",
        fullName: "Chicago Midway IL, USA",
        locations: [
          {
            city: "Chicago",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "IL"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc71f5e4b05395d65d437c"
    },
    code: "MCO",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1618162512648
      }
    },
    travelers: [
      {
        _id: {
          $oid: "58a4b9f5afc9db454045f695"
        },
        departureDateTime: {
          $date: 1618579500000
        },
        arrivalDateTime: {
          $date: 1618592700000
        }
      },
      {
        _id: {
          $oid: "60553b3dec62986255285cd9"
        },
        departureDateTime: {
          $date: 1618671600000
        },
        arrivalDateTime: {
          $date: 1618679580000
        }
      }
    ],
    geoLocation: {
      latitude: 28.43333244,
      longitude: -81.31666565
    },
    language: [
      {
        type: "en_us",
        name: "Orlando International",
        fullName: "Orlando FL, USA",
        locations: [
          {
            city: "Orlando",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "FL"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc71f4e4b05395d65d4372"
    },
    code: "MCI",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613453724567
      }
    },
    travelers: [
      {
        _id: {
          $oid: "6046775550f3e485147b5a92"
        },
        departureDateTime: {
          $date: 1618583100000
        },
        arrivalDateTime: {
          $date: 1618588500000
        }
      }
    ],
    geoLocation: {
      latitude: 39.29999924,
      longitude: -94.71666718
    },
    language: [
      {
        type: "en_us",
        name: "Kansas City International Airport",
        fullName: "Kansas City Intl MO, USA",
        locations: [
          {
            city: "Kansas City",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "MO"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc71b8e4b05395d65d4080"
    },
    code: "LGB",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613453725661
      }
    },
    travelers: [
      {
        _id: {
          $oid: "5ee8f84ba95be8b303681ca5"
        },
        departureDateTime: {
          $date: 1618696500000
        },
        arrivalDateTime: {
          $date: 1618716300000
        }
      },
      {
        _id: {
          $oid: "60393a3250f3e48514b4c836"
        },
        departureDateTime: {
          $date: 1618696500000
        },
        arrivalDateTime: {
          $date: 1618716300000
        }
      }
    ],
    geoLocation: {
      latitude: 33.81666565,
      longitude: -118.1500015
    },
    language: [
      {
        type: "en_us",
        name: "Long Beach Municipal",
        fullName: "Long Beach CA, USA",
        locations: [
          {
            city: "Long Beach",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "CA"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc71a9e4b05395d65d3fc0"
    },
    code: "LBB",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613453726754
      }
    },
    travelers: [
      {
        _id: {
          $oid: "5ee3f943a95be8b3035b2100"
        },
        departureDateTime: {
          $date: 1618611600000
        },
        arrivalDateTime: {
          $date: 1618615800000
        }
      }
    ],
    geoLocation: {
      latitude: 33.66666794,
      longitude: -101.8166656
    },
    language: [
      {
        type: "en_us",
        name: "International",
        fullName: "Lubbock TX, USA",
        locations: [
          {
            city: "Lubbock",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "TX"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc71a9e4b05395d65d3fb8"
    },
    code: "LAX",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1617639617435
      }
    },
    travelers: [
      {
        _id: {
          $oid: "605d1f47640b730e27205024"
        },
        departureDateTime: {
          $date: 1618619700000
        },
        arrivalDateTime: {
          $date: 1618625100000
        }
      },
      {
        _id: {
          $oid: "5f14ee05a95be8b3031c3321"
        },
        departureDateTime: {
          $date: 1618595220000
        },
        arrivalDateTime: {
          $date: 1618599840000
        }
      }
    ],
    geoLocation: {
      latitude: 33.95000076,
      longitude: -118.4000015
    },
    language: [
      {
        type: "en_us",
        name: "Los Angeles International",
        fullName: "Los Angeles CA, USA",
        locations: [
          {
            city: "Los Angeles",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "CA"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc711de4b05395d65d38fe"
    },
    code: "ICN",
    status: {
      reason: "No known delays for this airport.",
      closureBegin: "",
      endTime: "",
      minDelay: "",
      avgDelay: "",
      maxDelay: "",
      closureEnd: "",
      trend: "",
      type: "",
      color: "green",
      createdDateTime: {
        $date: 1459778081023
      }
    },
    travelers: [
      {
        _id: {
          $oid: "5e9763f7a95be8b303d5a2cf"
        },
        departureDateTime: {
          $date: 1618618800000
        },
        arrivalDateTime: {
          $date: 1618668600000
        }
      }
    ],
    geoLocation: {
      latitude: 37.5,
      longitude: 126.5
    },
    language: [
      {
        type: "en_us",
        name: "Incheon International",
        fullName: "Incheon Intl Seoul, S Korea",
        locations: [
          {
            city: "Seoul",
            countryCode: "KR",
            countryFull: "Republic of Korea"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc711be4b05395d65d38dc"
    },
    code: "IAH",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1618416317075
      }
    },
    travelers: [
      {
        _id: {
          $oid: "6051a438ec62986255ee7ca5"
        },
        departureDateTime: {
          $date: 1618673100000
        },
        arrivalDateTime: {
          $date: 1618677720000
        }
      },
      {
        _id: {
          $oid: "604a711a50f3e48514bab111"
        },
        departureDateTime: {
          $date: 1618612020000
        },
        arrivalDateTime: {
          $date: 1618616940000
        }
      },
      {
        _id: {
          $oid: "606bc92348c73cf513ff0e2e"
        },
        departureDateTime: {
          $date: 1618571700000
        },
        arrivalDateTime: {
          $date: 1618580700000
        }
      },
      {
        _id: {
          $oid: "6042d4fa50f3e4851444fbf8"
        },
        departureDateTime: {
          $date: 1618616700000
        },
        arrivalDateTime: {
          $date: 1618621380000
        }
      },
      {
        _id: {
          $oid: "6046ca0250f3e4851480f55a"
        },
        departureDateTime: {
          $date: 1618616700000
        },
        arrivalDateTime: {
          $date: 1618621380000
        }
      }
    ],
    geoLocation: {
      latitude: 29.98333359,
      longitude: -95.33333588
    },
    language: [
      {
        type: "en_us",
        name: "George Bush Intercontinental",
        fullName: "Houston TX, USA",
        locations: [
          {
            city: "Houston",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "TX"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc711be4b05395d65d38d8"
    },
    code: "IAD",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613453729559
      }
    },
    travelers: [
      {
        _id: {
          $oid: "6053beb9ec6298625511bab4"
        },
        departureDateTime: {
          $date: 1618660800000
        },
        arrivalDateTime: {
          $date: 1618664700000
        }
      },
      {
        _id: {
          $oid: "60553b3dec62986255285cd9"
        },
        departureDateTime: {
          $date: 1618671600000
        },
        arrivalDateTime: {
          $date: 1618679580000
        }
      },
      {
        _id: {
          $oid: "6053beb9ec6298625511bab4"
        },
        departureDateTime: {
          $date: 1618599600000
        },
        arrivalDateTime: {
          $date: 1618604040000
        }
      }
    ],
    geoLocation: {
      latitude: 38.95000076,
      longitude: -77.44999695
    },
    language: [
      {
        type: "en_us",
        name: "Washington Dulles International",
        fullName: "Washington Dulles DC, USA",
        locations: [
          {
            city: "Washington",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "DC"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc70d7e4b05395d65d3800"
    },
    code: "HOU",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1617728717479
      }
    },
    travelers: [
      {
        _id: {
          $oid: "6042d4fa50f3e4851444fbf8"
        },
        departureDateTime: {
          $date: 1618578300000
        },
        arrivalDateTime: {
          $date: 1618584900000
        }
      },
      {
        _id: {
          $oid: "5ee3f943a95be8b3035b2100"
        },
        departureDateTime: {
          $date: 1618623000000
        },
        arrivalDateTime: {
          $date: 1618627200000
        }
      },
      {
        _id: {
          $oid: "6042d4fa50f3e4851444fbf8"
        },
        departureDateTime: {
          $date: 1618588500000
        },
        arrivalDateTime: {
          $date: 1618593600000
        }
      },
      {
        _id: {
          $oid: "6042d4fa50f3e4851444fbf8"
        },
        departureDateTime: {
          $date: 1618572000000
        },
        arrivalDateTime: {
          $date: 1618582800000
        }
      }
    ],
    geoLocation: {
      latitude: 29.64999962,
      longitude: -95.28333282
    },
    language: [
      {
        type: "en_us",
        name: "William P Hobby",
        fullName: "Houston Hobby TX, USA",
        locations: [
          {
            city: "Houston",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "TX"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc70d3e4b05395d65d37ce"
    },
    code: "HNL",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613453730172
      }
    },
    travelers: [
      {
        _id: {
          $oid: "5fc7bc601aeefc6829e08989"
        },
        departureDateTime: {
          $date: 1618554900000
        },
        arrivalDateTime: {
          $date: 1618557600000
        }
      },
      {
        _id: {
          $oid: "60393a3250f3e48514b4c836"
        },
        departureDateTime: {
          $date: 1618554900000
        },
        arrivalDateTime: {
          $date: 1618557600000
        }
      },
      {
        _id: {
          $oid: "5ee8f84ba95be8b303681ca5"
        },
        departureDateTime: {
          $date: 1618554900000
        },
        arrivalDateTime: {
          $date: 1618557600000
        }
      },
      {
        _id: {
          $oid: "5f2090e1a95be8b303581e0d"
        },
        departureDateTime: {
          $date: 1618554900000
        },
        arrivalDateTime: {
          $date: 1618557600000
        }
      },
      {
        _id: {
          $oid: "5f2090e1a95be8b303581e0d"
        },
        departureDateTime: {
          $date: 1618647300000
        },
        arrivalDateTime: {
          $date: 1618669140000
        }
      },
      {
        _id: {
          $oid: "5ee8f84ba95be8b303681ca5"
        },
        departureDateTime: {
          $date: 1618696500000
        },
        arrivalDateTime: {
          $date: 1618716300000
        }
      },
      {
        _id: {
          $oid: "60393a3250f3e48514b4c836"
        },
        departureDateTime: {
          $date: 1618696500000
        },
        arrivalDateTime: {
          $date: 1618716300000
        }
      },
      {
        _id: {
          $oid: "5fc7bc601aeefc6829e08989"
        },
        departureDateTime: {
          $date: 1618647300000
        },
        arrivalDateTime: {
          $date: 1618669140000
        }
      }
    ],
    geoLocation: {
      latitude: 21.31583405,
      longitude: -157.9266663
    },
    language: [
      {
        type: "en_us",
        name: "Honolulu International",
        fullName: "Honolulu HI, USA",
        locations: [
          {
            city: "Honolulu",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "HI"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc70a8e4b05395d65d359c"
    },
    code: "GPT",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613453731331
      }
    },
    travelers: [
      {
        _id: {
          $oid: "60745a5a48c73cf51396dcf0"
        },
        departureDateTime: {
          $date: 1618611900000
        },
        arrivalDateTime: {
          $date: 1618618860000
        }
      }
    ],
    geoLocation: {
      latitude: 30.39999962,
      longitude: -89.06666565
    },
    language: [
      {
        type: "en_us",
        name: "Gulfport-Biloxi International",
        fullName: "Gulfport MS, USA",
        locations: [
          {
            city: "Gulfport",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "MS"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc707ee4b05395d65d337a"
    },
    code: "FSD",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613453733171
      }
    },
    travelers: [
      {
        _id: {
          $oid: "5fa970891aeefc68292f27d2"
        },
        departureDateTime: {
          $date: 1618611840000
        },
        arrivalDateTime: {
          $date: 1618620300000
        }
      },
      {
        _id: {
          $oid: "6052a85eec62986255ffd6d7"
        },
        departureDateTime: {
          $date: 1618586880000
        },
        arrivalDateTime: {
          $date: 1618592700000
        }
      },
      {
        _id: {
          $oid: "6046ca0350f3e4851480f582"
        },
        departureDateTime: {
          $date: 1618583700000
        },
        arrivalDateTime: {
          $date: 1618590240000
        }
      },
      {
        _id: {
          $oid: "604fc593ec62986255cc4763"
        },
        departureDateTime: {
          $date: 1618583700000
        },
        arrivalDateTime: {
          $date: 1618590240000
        }
      }
    ],
    geoLocation: {
      latitude: 43.58333206,
      longitude: -96.75
    },
    language: [
      {
        type: "en_us",
        name: "Joe Foss Field Airport",
        fullName: "Sioux Falls SD, USA",
        locations: [
          {
            city: "Sioux Falls",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "SD"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc7066e4b05395d65d3252"
    },
    code: "FAR",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613453734063
      }
    },
    travelers: [
      {
        _id: {
          $oid: "6053f905ec62986255155f68"
        },
        departureDateTime: {
          $date: 1618619100000
        },
        arrivalDateTime: {
          $date: 1618623300000
        }
      }
    ],
    geoLocation: {
      latitude: 46.91666794,
      longitude: -96.81666565
    },
    language: [
      {
        type: "en_us",
        name: "Hector Field",
        fullName: "Fargo ND, USA",
        locations: [
          {
            city: "Fargo",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "ND"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc704de4b05395d65d311e"
    },
    code: "ELP",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613453735207
      }
    },
    travelers: [
      {
        _id: {
          $oid: "6042d4fa50f3e4851444fbf8"
        },
        departureDateTime: {
          $date: 1618578300000
        },
        arrivalDateTime: {
          $date: 1618584900000
        }
      },
      {
        _id: {
          $oid: "6046d0fb50f3e48514819679"
        },
        departureDateTime: {
          $date: 1618591800000
        },
        arrivalDateTime: {
          $date: 1618597800000
        }
      }
    ],
    geoLocation: {
      latitude: 31.79999924,
      longitude: -106.3833313
    },
    language: [
      {
        type: "en_us",
        name: "El Paso International Airport",
        fullName: "El Paso TX, USA",
        locations: [
          {
            city: "El Paso",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "TX"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc7036e4b05395d65d3002"
    },
    code: "DUB",
    status: {
      reason: "No known delays for this airport.",
      closureBegin: "",
      endTime: "",
      minDelay: "",
      avgDelay: "",
      maxDelay: "",
      closureEnd: "",
      trend: "",
      type: "",
      color: "green",
      createdDateTime: {
        $date: 1459778081023
      }
    },
    travelers: [
      {
        _id: {
          $oid: "5ed80740a95be8b303387755"
        },
        departureDateTime: {
          $date: 1618577400000
        },
        arrivalDateTime: {
          $date: 1618603200000
        }
      }
    ],
    geoLocation: {
      latitude: 53.42111206,
      longitude: -6.269999981
    },
    language: [
      {
        type: "en_us",
        name: "Dublin",
        fullName: "Dublin, Ireland",
        locations: [
          {
            city: "Dublin",
            countryCode: "IE",
            countryFull: "Ireland"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc7034e4b05395d65d2fe8"
    },
    code: "DSM",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613453735618
      }
    },
    travelers: [
      {
        _id: {
          $oid: "606e50c248c73cf5132c4bff"
        },
        departureDateTime: {
          $date: 1618677720000
        },
        arrivalDateTime: {
          $date: 1618688340000
        }
      }
    ],
    geoLocation: {
      latitude: 41.53333282,
      longitude: -93.66666412
    },
    language: [
      {
        type: "en_us",
        name: "Des Moines International",
        fullName: "Des Moines IA, USA",
        locations: [
          {
            city: "Des Moines",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "IA"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc701be4b05395d65d2eb2"
    },
    code: "DFW",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1618528819829
      }
    },
    travelers: [
      {
        _id: {
          $oid: "6051a438ec62986255ee7ca5"
        },
        departureDateTime: {
          $date: 1618673100000
        },
        arrivalDateTime: {
          $date: 1618677720000
        }
      },
      {
        _id: {
          $oid: "6051a438ec62986255ee7ca5"
        },
        departureDateTime: {
          $date: 1618661700000
        },
        arrivalDateTime: {
          $date: 1618668900000
        }
      },
      {
        _id: {
          $oid: "60745a5a48c73cf51396dcf0"
        },
        departureDateTime: {
          $date: 1618611900000
        },
        arrivalDateTime: {
          $date: 1618618860000
        }
      },
      {
        _id: {
          $oid: "5eea3efba95be8b3036cd1f4"
        },
        departureDateTime: {
          $date: 1618609800000
        },
        arrivalDateTime: {
          $date: 1618618860000
        }
      },
      {
        _id: {
          $oid: "5fa970891aeefc68292f27d2"
        },
        departureDateTime: {
          $date: 1618623300000
        },
        arrivalDateTime: {
          $date: 1618629720000
        }
      },
      {
        _id: {
          $oid: "5fa970891aeefc68292f27d2"
        },
        departureDateTime: {
          $date: 1618611840000
        },
        arrivalDateTime: {
          $date: 1618620300000
        }
      },
      {
        _id: {
          $oid: "604a711a50f3e48514bab111"
        },
        departureDateTime: {
          $date: 1618624140000
        },
        arrivalDateTime: {
          $date: 1618627920000
        }
      },
      {
        _id: {
          $oid: "604a711a50f3e48514bab111"
        },
        departureDateTime: {
          $date: 1618612020000
        },
        arrivalDateTime: {
          $date: 1618616940000
        }
      },
      {
        _id: {
          $oid: "6042d4fa50f3e4851444fbf8"
        },
        departureDateTime: {
          $date: 1618616700000
        },
        arrivalDateTime: {
          $date: 1618621380000
        }
      },
      {
        _id: {
          $oid: "6042d4fa50f3e4851444fbf8"
        },
        departureDateTime: {
          $date: 1618606800000
        },
        arrivalDateTime: {
          $date: 1618612620000
        }
      },
      {
        _id: {
          $oid: "6046ca0250f3e4851480f55a"
        },
        departureDateTime: {
          $date: 1618616700000
        },
        arrivalDateTime: {
          $date: 1618621380000
        }
      },
      {
        _id: {
          $oid: "6046ca0250f3e4851480f55a"
        },
        departureDateTime: {
          $date: 1618606800000
        },
        arrivalDateTime: {
          $date: 1618612620000
        }
      }
    ],
    geoLocation: {
      latitude: 32.90000153,
      longitude: -97.05000305
    },
    language: [
      {
        type: "en_us",
        name: "Dallas/Fort Worth International",
        fullName: "Dallas/Ft Worth TX, USA",
        locations: [
          {
            city: "Dallas",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "TX"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc701ae4b05395d65d2ea2"
    },
    code: "DEN",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1618541413815
      }
    },
    travelers: [
      {
        _id: {
          $oid: "58a4b9f5afc9db454045f695"
        },
        departureDateTime: {
          $date: 1618579500000
        },
        arrivalDateTime: {
          $date: 1618592700000
        }
      },
      {
        _id: {
          $oid: "5eea3efba95be8b3036cd1f0"
        },
        departureDateTime: {
          $date: 1618623300000
        },
        arrivalDateTime: {
          $date: 1618631880000
        }
      },
      {
        _id: {
          $oid: "5f0f6397a95be8b30304f9a1"
        },
        departureDateTime: {
          $date: 1618589400000
        },
        arrivalDateTime: {
          $date: 1618597800000
        }
      },
      {
        _id: {
          $oid: "5f184880a95be8b3032cc6a6"
        },
        departureDateTime: {
          $date: 1618617720000
        },
        arrivalDateTime: {
          $date: 1618624020000
        }
      },
      {
        _id: {
          $oid: "5f184880a95be8b3032cc6a6"
        },
        departureDateTime: {
          $date: 1618609020000
        },
        arrivalDateTime: {
          $date: 1618613700000
        }
      },
      {
        _id: {
          $oid: "6064ae8748c73cf5138a3c19"
        },
        departureDateTime: {
          $date: 1618617720000
        },
        arrivalDateTime: {
          $date: 1618624020000
        }
      },
      {
        _id: {
          $oid: "6064ae8748c73cf5138a3c19"
        },
        departureDateTime: {
          $date: 1618609020000
        },
        arrivalDateTime: {
          $date: 1618613700000
        }
      },
      {
        _id: {
          $oid: "6046ca0350f3e4851480f582"
        },
        departureDateTime: {
          $date: 1618594200000
        },
        arrivalDateTime: {
          $date: 1618603980000
        }
      },
      {
        _id: {
          $oid: "6046ca0350f3e4851480f582"
        },
        departureDateTime: {
          $date: 1618583700000
        },
        arrivalDateTime: {
          $date: 1618590240000
        }
      },
      {
        _id: {
          $oid: "604fc593ec62986255cc4763"
        },
        departureDateTime: {
          $date: 1618594200000
        },
        arrivalDateTime: {
          $date: 1618603980000
        }
      },
      {
        _id: {
          $oid: "604fc593ec62986255cc4763"
        },
        departureDateTime: {
          $date: 1618583700000
        },
        arrivalDateTime: {
          $date: 1618590240000
        }
      }
    ],
    geoLocation: {
      latitude: 39.86666489,
      longitude: -104.6666641
    },
    language: [
      {
        type: "en_us",
        name: "Denver International",
        fullName: "Denver CO, USA",
        locations: [
          {
            city: "Denver",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "CO"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc7016e4b05395d65d2e74"
    },
    code: "DCA",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613453736881
      }
    },
    travelers: [
      {
        _id: {
          $oid: "5ef3b716a95be8b3039641d1"
        },
        departureDateTime: {
          $date: 1618572600000
        },
        arrivalDateTime: {
          $date: 1618576680000
        }
      }
    ],
    geoLocation: {
      latitude: 38.84999847,
      longitude: -77.03333282
    },
    language: [
      {
        type: "en_us",
        name: "Ronald Reagan Washington National Airport",
        fullName: "Washington Natl DC, USA",
        locations: [
          {
            city: "Washington",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "DC"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc7012e4b05395d65d2e44"
    },
    code: "DAL",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1618526118769
      }
    },
    travelers: [
      {
        _id: {
          $oid: "6046d0fb50f3e48514819679"
        },
        departureDateTime: {
          $date: 1618591800000
        },
        arrivalDateTime: {
          $date: 1618597800000
        }
      },
      {
        _id: {
          $oid: "6046775550f3e485147b5a92"
        },
        departureDateTime: {
          $date: 1618583100000
        },
        arrivalDateTime: {
          $date: 1618588500000
        }
      },
      {
        _id: {
          $oid: "6063ca3d48c73cf5137b3460"
        },
        departureDateTime: {
          $date: 1618678500000
        },
        arrivalDateTime: {
          $date: 1618684200000
        }
      },
      {
        _id: {
          $oid: "5ee3f943a95be8b3035b2100"
        },
        departureDateTime: {
          $date: 1618623000000
        },
        arrivalDateTime: {
          $date: 1618627200000
        }
      },
      {
        _id: {
          $oid: "5ee3f943a95be8b3035b2100"
        },
        departureDateTime: {
          $date: 1618611600000
        },
        arrivalDateTime: {
          $date: 1618615800000
        }
      }
    ],
    geoLocation: {
      latitude: 32.84999847,
      longitude: -96.84999847
    },
    language: [
      {
        type: "en_us",
        name: "Love Field",
        fullName: "Dallas Love Field TX, USA",
        locations: [
          {
            city: "Dallas",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "TX"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc6ff1e4b05395d65d2cb8"
    },
    code: "CPR",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613453738315
      }
    },
    travelers: [
      {
        _id: {
          $oid: "5f184880a95be8b3032cc6a6"
        },
        departureDateTime: {
          $date: 1618609020000
        },
        arrivalDateTime: {
          $date: 1618613700000
        }
      },
      {
        _id: {
          $oid: "6064ae8748c73cf5138a3c19"
        },
        departureDateTime: {
          $date: 1618609020000
        },
        arrivalDateTime: {
          $date: 1618613700000
        }
      }
    ],
    geoLocation: {
      latitude: 42.90000153,
      longitude: -106.4666672
    },
    language: [
      {
        type: "en_us",
        name: "Natrona County International Airport",
        fullName: "Casper WY, USA",
        locations: [
          {
            city: "Casper",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "WY"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc6fede4b05395d65d2c8c"
    },
    code: "COS",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613453738532
      }
    },
    travelers: [
      {
        _id: {
          $oid: "6051a438ec62986255ee7ca5"
        },
        departureDateTime: {
          $date: 1618661700000
        },
        arrivalDateTime: {
          $date: 1618668900000
        }
      }
    ],
    geoLocation: {
      latitude: 38.79999924,
      longitude: -104.6999969
    },
    language: [
      {
        type: "en_us",
        name: "Colorado Springs",
        fullName: "Colorado Springs CO, USA",
        locations: [
          {
            city: "Colorado Springs",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "CO"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc6fe2e4b05395d65d2c12"
    },
    code: "CMH",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613110830890
      }
    },
    travelers: [
      {
        _id: {
          $oid: "5f3a9b6ea95be8b303e481c7"
        },
        departureDateTime: {
          $date: 1618585200000
        },
        arrivalDateTime: {
          $date: 1618590300000
        }
      }
    ],
    geoLocation: {
      latitude: 40,
      longitude: -82.8833313
    },
    language: [
      {
        type: "en_us",
        name: "Port Columbus Intl",
        fullName: "Columbus OH, USA",
        locations: [
          {
            city: "Columbus",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "OH"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc6fe0e4b05395d65d2bf6"
    },
    code: "CLT",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1618498219357
      }
    },
    travelers: [
      {
        _id: {
          $oid: "5f591374a95be8b303a9dccf"
        },
        departureDateTime: {
          $date: 1618599420000
        },
        arrivalDateTime: {
          $date: 1618608000000
        }
      }
    ],
    geoLocation: {
      latitude: 35.21666718,
      longitude: -80.94999695
    },
    language: [
      {
        type: "en_us",
        name: "Charlotte Douglas",
        fullName: "Charlotte NC, USA",
        locations: [
          {
            city: "Charlotte",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "NC"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc6fd1e4b05395d65d2b4a"
    },
    code: "CHS",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613453739044
      }
    },
    travelers: [
      {
        _id: {
          $oid: "5f810d1ba95be8b303c48a21"
        },
        departureDateTime: {
          $date: 1618588080000
        },
        arrivalDateTime: {
          $date: 1618594800000
        }
      }
    ],
    geoLocation: {
      latitude: 32.884354,
      longitude: -80.037155
    },
    language: [
      {
        type: "en_us",
        name: "Charleston,  AFB Municipal",
        fullName: "Charleston SC, USA",
        locations: [
          {
            city: "Charleston",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "SC"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc6fa5e4b05395d65d294e"
    },
    code: "BWI",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1616950220107
      }
    },
    travelers: [
      {
        _id: {
          $oid: "6075939848c73cf513b0c53b"
        },
        departureDateTime: {
          $date: 1618585800000
        },
        arrivalDateTime: {
          $date: 1618590300000
        }
      },
      {
        _id: {
          $oid: "6075939848c73cf513b0c53b"
        },
        departureDateTime: {
          $date: 1618577700000
        },
        arrivalDateTime: {
          $date: 1618581900000
        }
      },
      {
        _id: {
          $oid: "6070eb7148c73cf5135c5e79"
        },
        departureDateTime: {
          $date: 1618585500000
        },
        arrivalDateTime: {
          $date: 1618590000000
        }
      },
      {
        _id: {
          $oid: "6070eb7148c73cf5135c5e79"
        },
        departureDateTime: {
          $date: 1618577400000
        },
        arrivalDateTime: {
          $date: 1618581600000
        }
      },
      {
        _id: {
          $oid: "606244ac48c73cf513609b02"
        },
        departureDateTime: {
          $date: 1618585800000
        },
        arrivalDateTime: {
          $date: 1618590300000
        }
      },
      {
        _id: {
          $oid: "606244ac48c73cf513609b02"
        },
        departureDateTime: {
          $date: 1618577700000
        },
        arrivalDateTime: {
          $date: 1618581900000
        }
      },
      {
        _id: {
          $oid: "6061eff348c73cf51359b927"
        },
        departureDateTime: {
          $date: 1618585800000
        },
        arrivalDateTime: {
          $date: 1618590300000
        }
      },
      {
        _id: {
          $oid: "6061eff348c73cf51359b927"
        },
        departureDateTime: {
          $date: 1618577700000
        },
        arrivalDateTime: {
          $date: 1618581900000
        }
      },
      {
        _id: {
          $oid: "5f3a9b6ea95be8b303e481c7"
        },
        departureDateTime: {
          $date: 1618585200000
        },
        arrivalDateTime: {
          $date: 1618590300000
        }
      },
      {
        _id: {
          $oid: "5f3a9b6ea95be8b303e481c7"
        },
        departureDateTime: {
          $date: 1618574700000
        },
        arrivalDateTime: {
          $date: 1618581300000
        }
      }
    ],
    geoLocation: {
      latitude: 39.18333435,
      longitude: -76.66666412
    },
    language: [
      {
        type: "en_us",
        name: "Baltimore/Washington International Thurgood Marshall",
        fullName: "Baltimore Wash MD, USA",
        locations: [
          {
            city: "Baltimore",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "MD"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc6f6ae4b05395d65d27fe"
    },
    code: "BOS",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1612848951540
      }
    },
    travelers: [
      {
        _id: {
          $oid: "5ed80740a95be8b303387755"
        },
        departureDateTime: {
          $date: 1618577400000
        },
        arrivalDateTime: {
          $date: 1618603200000
        }
      }
    ],
    geoLocation: {
      latitude: 42.36666489,
      longitude: -71
    },
    language: [
      {
        type: "en_us",
        name: "Logan International",
        fullName: "Boston MA, USA",
        locations: [
          {
            city: "Boston",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "MA"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc6f25e4b05395d65d24c4"
    },
    code: "AVP",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613453742539
      }
    },
    travelers: [
      {
        _id: {
          $oid: "5ef3b531a95be8b303963188"
        },
        departureDateTime: {
          $date: 1618569000000
        },
        arrivalDateTime: {
          $date: 1618577400000
        }
      }
    ],
    geoLocation: {
      latitude: 41.33333206,
      longitude: -75.71666718
    },
    language: [
      {
        type: "en_us",
        name: "Wilkes-Barre/Scranton International Airport",
        fullName: "Wilkes-Barre/Scranton PA, USA",
        locations: [
          {
            city: "Wilkes-Barre/Scranton",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "PA"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc6f22e4b05395d65d24a4"
    },
    code: "AUS",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1617630622478
      }
    },
    travelers: [
      {
        _id: {
          $oid: "5f0f6397a95be8b30304f9a1"
        },
        departureDateTime: {
          $date: 1618589400000
        },
        arrivalDateTime: {
          $date: 1618597800000
        }
      }
    ],
    geoLocation: {
      latitude: 30.28333282,
      longitude: -97.69999695
    },
    language: [
      {
        type: "en_us",
        name: "Austin-Bergstrom International",
        fullName: "Austin TX, USA",
        locations: [
          {
            city: "Austin",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "TX"
          }
        ]
      }
    ]
  },
  {
    _id: {
      $oid: "56cc6f07e4b05395d65d235a"
    },
    code: "ANC",
    status: {
      reason: "No known delays for this airport",
      message: "General Arrival/Departure delays are 15 minutes or less.",
      color: "green",
      createdDateTime: {
        $date: 1613453743126
      }
    },
    travelers: [
      {
        _id: {
          $oid: "5fc6964c1aeefc682956f1f0"
        },
        departureDateTime: {
          $date: 1618581600000
        },
        arrivalDateTime: {
          $date: 1618594740000
        }
      }
    ],
    geoLocation: {
      latitude: 61.17416763,
      longitude: -149.996109
    },
    language: [
      {
        type: "en_us",
        name: "Ted Stevens Anchorage International Airport",
        fullName: "Anchorage AK, USA",
        locations: [
          {
            city: "Anchorage",
            countryCode: "US",
            countryFull: "United States",
            stateProvinceCode: "AK"
          }
        ]
      }
    ]
  }
];
