import PropTypes from 'prop-types';
import React from 'react';
import InputText from '../../components/forms/inputText';
import Close from '../../styles/images/Close.svg';

export class CompanyCostCenterBlock extends React.Component {
  static contextTypes = {
    i18n: PropTypes.object
  };

  static propTypes = {
    contact: PropTypes.object,
    index: PropTypes.number,
    onRemoveContact: PropTypes.func,
    onUpdateContact: PropTypes.func,
    costCentersValidationErrors: PropTypes.array
  };

  constructor(props) {
    super(props);
    this.nameRef = React.createRef();
    this.sortIdRef = React.createRef();
  }

  onChange = (e) => {
    const contact = {
      ...this.props.contact,
      name:  this.nameRef.current.value,
      sort1Id: this.sortIdRef.current.value
    };
    if (Object.prototype.hasOwnProperty.call(this.props, 'onUpdateContact')) {
      this.props.onUpdateContact(this.props.index, contact);
    }
  };

  onDelete = () => {
    if (Object.prototype.hasOwnProperty.call(this.props, 'onRemoveContact')) {
      this.props.onRemoveContact(this.props.index);
    }
  };

  render () {
    const { l } = this.context.i18n;
    const { contact, costCentersValidationErrors, index } = this.props;

    const isExisted = Object.prototype.hasOwnProperty.call(contact, 'existed');
    const errors = [];
    if (
      costCentersValidationErrors &&
      costCentersValidationErrors instanceof Array &&
      costCentersValidationErrors.length > 0
    ) {
      costCentersValidationErrors.forEach((elem) => {
        if (elem.ref === index) {
          if (!errors[elem.field]) {
            errors[elem.field] = [];
          }
          errors[elem.field].push(elem.message);
        }
      });
    }

    return (
      <div className='agency-contact' style={{ paddingLeft: '12px',display:"flex", alignItems:"baseline" }}>
        <div className='agency-input-block'>
          <InputText
          ref={this.nameRef}
          name='name'
          placeholder={l('Enter Sort Level Name')}
          errorText={errors && errors.name ? errors.name[0] : ''}
          defaultValue={contact.name}
          onChangeHandler={this.onChange}
          required={isExisted}
          maxLength={32}
        />
        <InputText
          ref={this.sortIdRef}
          name='sort1Id'
          placeholder={l('Enter Sort Level ID')}
          errorText={errors && errors.sort1Id ? errors.sort1Id[0] : ''}
          defaultValue={contact.sort1Id}
          onChangeHandler={this.onChange}
          required={isExisted}
          maxLength={32}
        />
        </div>
        {isExisted && !contact._id
          ? <img src={Close} onClick={this.onDelete} style={{height:'21px'}} className={"pointer"}/>
          : ''
        }
      </div>
    );
  }
}
export default CompanyCostCenterBlock;
