import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { format as formatDate, startOfMonth,endOfMonth, eachDayOfInterval,isSameMonth,isSameDay,isToday, lastDayOfMonth,addMonths,getDay,addDays,setDate } from 'date-fns'
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import Embassy from '../../styles/images/contact/Embassy.svg';
import Email from '../../styles/images/contact/Email.svg';
import StickyNote from '../../styles/images/contact/stickynote.svg';

import serverApi from '../../helpers/serverApi';





// import '../../../src/styles/vendor/slick.scss';




const useStyles = makeStyles((theme) => ({
    root: {
      marginLeft: '15px',
      marginTop: '15px',
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,

    },
    contactCard: {
      display: 'flex',
      paddingTop:'0.8rem',
      paddingLeft:'1rem'

    },
    temp: {
      borderRadius: '12px',
      backgroundColor: '#FFFFFF',
      boxShadow: '0 12px 18px 0 rgba(41,94,255,0.05)',
      padding: '10px',
      // paddingRight: '5rem',
      paddingBottom:'0px',
      paddingTop: '0px',
    },
    detailbox: {
      borderRadius: '12px',
      backgroundColor: '#FFFFFF',
      boxShadow: '0 12px 18px 0 rgba(41,94,255,0.05)'
    },

    title: {
      color: '#B9B9B9',
      fontFamily: 'Open Sans',
      fontSize: '12px',
      letterSpacing: '0',
      lineHeight: '17px',
      width: '60px'
    },
    contactData: {
      color: '#595B60',
      fontFamily: 'Open Sans',
      fontSize: '18px',
      letterSpacing: '0',
      lineHeight: '24px'
    }

  }))
;



function ContactView (props) {


  const classes = useStyles();
  const [currentDate, setCurrentDate] = React.useState(new Date());


  const [traveler,setTraveler] = React.useState(null);

  useEffect(() => {
    serverApi.getTraveler({ subscriberId: props.uid }).then(function (result) {
      if (result.data && result.data.data) {
        setTraveler(result.data.data[0]);
      }

    });
  }, []);

  let isedit = true;
  if (["TA","CU"].indexOf(props.userRole) !== -1){
    isedit = false;
  }



  if (traveler) {
    let orgName = traveler.hasOwnProperty('organization') ? traveler.organization.name : null;

    orgName = traveler.hasOwnProperty('costCenter') && traveler.costCenter ? traveler.costCenter.name : orgName;
    let pre, mid, last;
    if (traveler.phoneNumbers[0] && traveler.phoneNumbers[0].number != 'undefined') {
      pre = (traveler.phoneNumbers[0].number).slice(0, 3);
      mid = (traveler.phoneNumbers[0].number).slice(3, 6);
      last = (traveler.phoneNumbers[0].number).slice(6);
    }

    return (<div className="row" >
      <div className="col-8" style={{paddingLeft:'40px', paddingTop:'30px'}}>
        <div className={classes.contactCard}>
          <div className={classes.temp}>
            <div className={'row'} style={{padding:'10px'}}>
              <div> <img src={Embassy} alt='' style={{height : "2rem"}}/></div>
              <div className={'col-7'}>

                <div className={`${classes.title} c-name`}>
                  Sort Name
                </div>
                <div className={classes.contactData} style={{width:'20rem'}}>
                  {orgName || "-"}
                </div>

              </div>
            </div>
          </div>



        </div>
        <div className={classes.contactCard}>
          <div className={classes.temp}>
            <div className={'row'} style={{padding:'10px'}}>
              <div><i className={'fas fa-phone-alt'} style={{
                fontSize: '24px',
                padding: '5px',
                color:'#595B60'
              }}></i></div>
              <div className={'col-7'}>
              <span>
            <div className={classes.title}>
              Primary
            </div>
            <div className={classes.contactData} style={{width:"18rem"}}>
              {traveler.phoneNumbers.length ? `(${pre}) ${mid}-${last}` :  "-"}
            </div>
          </span>
              </div>
            </div>
          </div>


        </div>
        <div className={classes.contactCard}>
          <div className={classes.temp}>
            <div className={'row'} style={{padding:'10px'}}>
              <div><img src={Email} alt='' style={{height : "2rem"}}/></div>
              <div className={'col-7'}>
              <span>
            <div className={classes.title}>
              Email
            </div>
            <div className={classes.contactData}>
              {traveler.emails[0].email}
            </div>
          </span>
              </div>
            </div>
          </div>


        </div>
        <div className={classes.contactCard}>
          <div className={classes.temp}>
            <div className={'row'} style={{padding:'16px',flexWrap:"initial"}}>
              <div><img src={StickyNote} alt='' style={{height : "1.5rem",paddingRight:'4px'}}/></div>
              <div className={'col-11'}>
              <span>
            <div className={classes.title}>
              Notes
            </div>
            <div className={classes.contactData}>
              {traveler.notes ||  "-"}
            </div>
          </span>
              </div>
            </div>
          </div>


        </div>
      </div>
      <div className="col-3">
        { isedit ? (<div style={{padding:'1rem',fontWeight:'bold',textDecoration:"underline",textAlign:"center",marginLeft:"200px",marginTop:"30px"}}><Link to={'/traveler/' + traveler["_id"]["$oid"] + '/edit'} >Edit</Link></div>):""}
      </div>
    </div>);
  }
  else {
    return (<div style={{padding:'10px 50px'}}>Loading...</div>);
  }





}

export default ContactView;
