import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { DashboardLayout, AuthLayout, NoLayout } from './src/layouts';
import Home from './src/views/DashoardView/Home';
import Onboarding from './src/Onboarding';
import TravelersListView from './src/views/TravelersListView/TravelersListView';
import Covid19View from './src/views/Covid19View/Covid19View';
import Covid19TravelAdvisoryView from './src/views/Covid19TravelAdvisoryView/Covid19TravelAdvisoryView';
import TravelAdvisoryView from './src/views/TravelAdvisoryView/TravelAdvisoryView';
import Terms from './src/terms';
import HealthView from './src/views/Health/HealthView';
import WorldHub from './src/views/WorldhubView/WorldHub';
import ActiveTravelersView from './src/views/ActiveTravelersView/ActiveTravelersView';
import FlightsStatusView from './src/views/FlightsStatusView/FlightsStatusView';
import ImportTravelersView from './src/views/ImportTravelersView/ImportTravelersView';
import AddTravelerFormView from './src/views/AddTravelerFormView/AddTravelerFormView';
import EditTravelerFormView from './src/views/EditTravelerFormView/EditTravelerFormView';
import AddUserFormView from './src/views/AddUserFormView/AddUserFormView';
import AccountFormView from './src/views/AccountFormView/AccountFormView';
import EditUserFormView from './src/views/EditUserFormView/EditUserFormView';
import TravelerView from './src/views/TravelerView/TravelerView';
import CompaniesListView from './src/views/CompaniesListView/CompaniesListView';
import CompanyUsersView from './src/views/CompanyUsersView/CompanyUsersView';
import UsersView from './src/views/UsersView/UsersView';
import NotificationSettings from './src/containers/Settings/NotificationSettings';
import GlobalFilter from './src/containers/Settings/GlobalFilter';
import UserLayoutComponent from './src/components/activeTravelers/activeTravallersUserDetailsLayout';
import SetNewPasswordView from './src/views/SetNewPasswordView/SetNewPasswordView';
import AccountsListView from './src/views/AccountsListView/AccountsListView';
import AccountUsersView from './src/views/AccountUsersView/AccountUsersView';
import ROLES from './src/acl/roles';
import LoginView from './src/views/LoginView/LoginView';


const getDashboardRoutes = (userRole ) => {
  let routes = [
    // {
    //   exact: true,
    //   path: '/home',
    //   component: Home
    // },

    {
      exact: true,
      path: '/travelers-list',
      component: TravelersListView
    },
    {
      exact: true,
      path: '/covid19',
      component: Covid19View
    },
    {
      exact: true,
      path: '/covid19-travel-advisories',
      component: Covid19TravelAdvisoryView
    },
    {
      exact: true,
      path: '/travel-advisories/',
      component: TravelAdvisoryView
    },
    {
      exact: true,
      path: '/travel-advisories/:country',
      component: TravelAdvisoryView
    },

    {
      exact: true,
      path: '/health',
      component: HealthView
    },
    {
      exact: true,
      path: '/worldhub',
      component: WorldHub
    },
    {
      exact: true,
      path: '/flights-status',
      component: FlightsStatusView
    },
    {
      exact: true,
      path: '/active-travelers/:mapView',
      component: ActiveTravelersView
    },
    {
      exact: true,
      path: '/import/traveler',
      component: ImportTravelersView
    },
    {
      exact: true,
      path: '/add/traveler',
      component: AddTravelerFormView
    },
    {
      exact: true,
      path: '/traveler/:id/edit',
      component: EditTravelerFormView
    },
    {
      exact: true,
      path: '/traveler/:id',
      component: ActiveTravelersView
    },
    {
      exact: true,
      path: '/traveler/:id/reservations',
      component: ActiveTravelersView
    },
    {
      exact: true,
      path: '/traveler/:id/maps',
      component: ActiveTravelersView
    },
    {
      exact: true,
      path: '/traveler/:id/flight-status',
      component: ActiveTravelersView
    },
    {
      exact: true,
      path: '/traveler/:id/travel-advisories',
      component: ActiveTravelersView
    },
    {
      exact: true,
      path: '/traveler/:id/case-history',
      component: ActiveTravelersView
    },
    {
      exact:true,
      path: "/traveler/:id/contact",
      component: ActiveTravelersView
    },

    {
      exact: true,
      path: '/travelerad/:id',
      component: TravelAdvisoryView
    },
    {
      exact: true,
      path: '/travelerad/:id/reservations',
      component: TravelAdvisoryView
    },
    {
      exact: true,
      path: '/travelerad/:id/maps',
      component: TravelAdvisoryView
    },
    {
      exact: true,
      path: '/travelerad/:id/flight-status',
      component: TravelAdvisoryView
    },
    {
      exact: true,
      path: '/travelerad/:id/travel-advisories',
      component: TravelAdvisoryView
    },
    {
      exact: true,
      path: '/travelerad/:id/case-history',
      component: TravelAdvisoryView
    },
    {
      exact:true,
      path: "/travelerad/:id/contact",
      component: TravelAdvisoryView
    },

    {
      exact: true,
      path: '/settings/notifications',
      component: NotificationSettings
    },
    {
      exact: true,
      path: '/settings/global-filter',
      component: GlobalFilter
    },
  ]
  if ( [ROLES.GLOBAL_ADMIN].includes(userRole)) {
    routes.push(...getAgencyManagementRoutes());
  }

  if ( [ROLES.TRAVEL_AGENCY_ADMIN, ROLES.COMPANY_ADMIN, ROLES.TRAVEL_AGENCY_OPS_READONLY].includes(userRole)) {
    routes.push(...getCompanyManagementRoutes());
  }

  if ( [ROLES.GLOBAL_ADMIN, ROLES.TRAVEL_AGENCY_ADMIN, ROLES.COMPANY_ADMIN, ROLES.TRAVEL_AGENCY_OPS_READONLY].includes(userRole)) {
    routes.push(...getUserManagementRoutes());
    // routes.push(...getCompanyManagementRoutes());
  }

  return routes;
}


const getCompanyManagementRoutes = () => {
  let routes = [
    {
      exact: true,
      path: '/companies',
      component: CompaniesListView
    },
    {
      exact: true,
      path: '/companies/:id',
      component: CompanyUsersView
    },
    {
      exact: true,
      path: '/companies/:organizationId/users/edit/:id',
      component: EditUserFormView
    },
    {
      exact: true,
      path: '/companies/:organizationId/add/user',
      component: AddUserFormView
    },
    {
      exact: true,
      path: '/companies/:organizationId/edit',
      component: AccountFormView
    },
    {
      exact: true,
      path: '/companies/add',
      component: AccountFormView
    },
  ];
  return routes;
}

const getUserManagementRoutes = () => {
  let routes = [
    {
      exact: true,
      path: '/users',
      component: UsersView
    },
    {
      exact: true,
      path: '/users/add',
      component: AddUserFormView
    },
    {
      exact: true,
      path: '/users/edit/:id',
      component: EditUserFormView
    },

  ];
  return routes;
}

const getAgencyManagementRoutes = () => {
  // Below are for Global Admin onl
  let routes = [
      {
        exact:true,
        path: '/accounts',
        component: AccountsListView
      },
      {
        exact:true,
        path: '/accounts/add',
        component: AccountFormView
      },
      {
        exact:true,
        path: '/accounts/:id',
        component: AccountUsersView
      },
      {
        exact:true,
        path: '/accounts/:organizationId/users/edit/:id',
        component: EditUserFormView
      },
      {
        exact:true,
        path: '/accounts/:organizationId/add/user',
        component: AddUserFormView
      },
      {
        exact:true,
        path: '/accounts/:organizationId/edit',
        component: AccountFormView
      }
  ];
  return routes;
}

export const getRoutes = (userRole) => {

  let routes = [
    {
      layout: DashboardLayout,
      subRoutes: getDashboardRoutes(userRole),
    },
    {
      layout: NoLayout,
      subRoutes: [
        {
          exact: true,
          path: '/',
          component: () => {
            if (userRole === ROLES.GLOBAL_ADMIN) {
              return <Redirect to="/accounts"/>
            }
            return <Redirect to="/active-travelers/locations"/>
          }
        },
        {
          exact: true,
          path: '/onboarding',
          component: Onboarding
        },
        {
          exact: true,
          path: '/terms',
          component: Terms
        },
        {
          exact: true,
          path: '/set-new-password/:code',
          component: SetNewPasswordView
        },
        {
          exact: true,
          path: '/login',
          component: LoginView
        },

      ]
    }

  ];

  return routes;
}
