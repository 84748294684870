import React from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { bindActionCreators } from "redux";

import { createSelector } from "reselect";
import {
  stateActiveTravelers,
  stateFilter
} from "../../redux/modules/activeTravelers";
import {
  actions as airportStatusesActions,
  stateAirportStatuses,
  stateFilterAirportStatus,
  stateFilterAirportTimeframe
} from "../../redux/modules/airportStatuses";
import {
  actions as mapActions,
  stateAtMapView,
  stateOnTheMap,
  stateAreaShown
} from "../../redux/modules/atMap";
import {
  actions as flightStatusActions,
  stateFlightsStatuses,
  stateFilterFlightStatusTimeframe
} from "../../redux/modules/flightsStatusMap";
import { stateUserRole } from "../../redux/modules/user";
import { transform } from 'lodash';

// import './activeTabs.css';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    minHeight:'43px',

  },
  threeTabs:{
    backgroundColor:'white',
    width:'420px',
    minHeight:'43px',
    '& .Mui-selected':{
      backgroundColor:'#0F71CF',
      color: "#FFFFFF"
    },
    '& .PrivateTabIndicator-colorSecondary-30': {
      backgroundColor: '#0F71CF'
  },
  '& .PrivateTabIndicator-root-28': {
    width: '0',
    bottom: '0',
    height: '0'
},
  },
  Tab:
  {
    textTransform:'none',
    minWidth: "140px",
    fontFamily: "Open Sans",
    fontSize: "14px",
    letterSpacing: '0.35px',
    lineHeight: '16px',
    textAlign: 'center',
    minHeight: "43px",
    padding: "0px",
  },
});

const mapStateToProps = createSelector(
  stateActiveTravelers,
  stateFilter,
  stateAtMapView,
  stateOnTheMap,
  stateAirportStatuses,
  stateFilterAirportStatus,
  stateFilterAirportTimeframe,
  stateFlightsStatuses,
  stateFilterFlightStatusTimeframe,
  stateUserRole,
  stateAreaShown,
  (
    activeTravelers,
    filter,
    atMapView,
    onTheMap,
    airports,
    filterAirportStatus,
    filterAirportTimeframe,
    flights,
    filterFlightStatusTimeframe,
    userRole,
    areaShown
  ) => {
    return {
      mapView: atMapView
    };
  }
);

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(mapActions, dispatch)
  };
};



function SimpleTabs (props) {

  const switchMapView = mapView => e => {
    e.preventDefault();
    console.log(e.label);
    let newLocation = '/active-travelers/' + mapView;
    props.history.push(newLocation);
  };


  const { mapView } = props;
  console.log(props.match.params.mapView);
  let defaultValue = 3;
  if (props.match.params.mapView === 'locations') {
    defaultValue = 0;
  } else if (props.match.params.mapView === 'airportStatus') {
    defaultValue = 1;
  }else if (props.match.params.mapView === 'flightsStatus'){
    defaultValue = 2;
  }
  const [value, setValue] = React.useState(defaultValue || 0);
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper square className={classes.root}>
      <Tabs
        value={value}
        className={classes.threeTabs}
        indicatorColor=''
        // textColor="primary"
        onChange={handleChange}
      >
        <Tab label="Locations" className={classes.Tab} onClick={switchMapView("locations")} />
        <Tab label="Airports" className={classes.Tab}  onClick={switchMapView("airportStatus")}/>
        <Tab label="Flights" className={classes.Tab}  onClick={switchMapView("flightsStatus")}/>
      </Tabs>
    </Paper>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SimpleTabs));
