import moment from 'moment-timezone';

/* eslint-disable new-cap */
import PropTypes from 'prop-types';

import React from 'react';
import { Link } from 'react-router-dom';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { isCompanyAdminOrUser } from '../../helpers/user';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import VendorImage from '../traveler/vendorImage';
import {  toast } from 'react-toastify';
import i18nTools from '../../helpers/i18nTools';

export class EmissionsTable extends React.Component {

  static contextTypes = {
    i18n: PropTypes.object
  };

  static propTypes = {
    getEntireTravelersFlights: PropTypes.func,
    travelersFlights: PropTypes.array.isRequired,
    sortBy: PropTypes.string.isRequired,
    userRole: PropTypes.string,
    sortByDirect: PropTypes.bool.isRequired,
    compact: PropTypes.bool,
    flightsSorter: PropTypes.func.isRequired,
    setExportFlightDetailsFlag: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
  }

  sortBy = (sortBy) => () => {
    return this.props.flightsSorter(sortBy);
  }

  sortClass(name) {
    let className = this.props.sortBy === name ? 'sortedBy' : '';
    if (className) {
      className = this.props.sortByDirect ? 'className' : 'up ' + className;
    }
    return 'sortable ' + className;
  }

  exportToFile = (fileType) => {
    this.props.getEntireTravelersFlights().then((flights) => {
      if (flights) {
        const flightData = this.formatFlights(flights);
        if (flightData && flightData.length > 0) {
          try {
            if (fileType === 'xlsx') {
              const worksheet = XLSX.utils.json_to_sheet(flightData);
              const workbook = { Sheets: { 'CO2 FlightBoard': worksheet }, SheetNames: ['CO2 FlightBoard'] };
              const excelData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
              const blob = new Blob([excelData], {
                type:
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
              });
  
              FileSaver.saveAs(blob, 'CO2FlightBoard.xlsx');
            } else if (fileType === 'pdf') {
              const doc = this.generatePdf(flightData);
              doc.save('CO2FlightBoard.pdf');
            }
            this.props.setExportFlightDetailsFlag(false);
          } catch (error) {
            console.error('error :: ', error.message);
            toast.error(i18nTools.l('Export failed.'));
            this.props.setExportFlightDetailsFlag(false);
          }
        }
      }
    }).catch((e) => {
      toast.error(i18nTools.l('Server error, please try again later'));
      this.props.setExportFlightDetailsFlag(false);
    });
  }

   generatePdf(data) {
    const doc = new jsPDF('l', 'pt');
    const width = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    const font = doc.getFont();

    doc.setTextColor('#18498F');
    doc.setFont(font.fontName, 'bold');
    doc.setFontSize(18);
    doc.text('CO2 FlightBoard', width / 2, 40, 'center');
    doc.setFont(font.fontName, '');

    doc.setFontSize(14);
    doc.text('WorldWatch', 40, 70);

    doc.setFontSize(12);
    doc.setFont(font.fontName, 'italic');
    doc.text('CO2 FlightBoard Report', 40, 90);
    doc.setFont(font.fontName, '');

    doc.setTextColor(0);

    const pdfData = data.map((flight, i) => {
      return {
        ...flight,
        'Traveler': flight.Traveler + (flight.VIP ? ' (VIP)' : '')
      }
    });

    doc.autoTable({
      startY: 110,
      rowPageBreak: 'avoid',
      headStyles: {
        fillColor: [230, 230, 230],
        textColor: 0
      },
      alternateRowStyles: {
        fillColor: [255, 255, 255]
      },
      columns: [
        { dataKey: 'Traveler', header: 'Traveler' },
        { dataKey: 'Flight', header: 'Flight' },
        { dataKey: 'O/D', header: 'O/D' },
        { dataKey: 'Distance (mi)', header: 'Distance (mi)' },
        { dataKey: 'Emission (lbs)', header: 'Emission (lbs)' },
        { dataKey: 'Aircraft', header: 'Aircraft' },
        { dataKey: 'Class', header: 'Class' }
      ],
      body: pdfData,
      didDrawCell: function (data) {
        if (data.row.section === 'body') {
          if (data.column.dataKey === 'Traveler') {
            doc.setFillColor('#FFFFFF');
            const cellpos = data.cell.getTextPos();
            doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height , 'F');
            const font = doc.getFont();

            doc.setFont(font.fontName, 'bold');
            doc.text(data.row.raw.Traveler, cellpos.x, cellpos.y + 8);

            doc.setFont(font.fontName, '');
            const companyName = typeof data.row.raw.Company === "string"? data.row.raw.Company: "";
            doc.text(companyName, cellpos.x, cellpos.y + 20);
            data.row.height = data.row.height + 20;
          }
        }
      }
    });
    return doc;
  }



  formatFlights(flights) {
    let data = flights.map( (flight) => {
      let company = flight.organization.name;
      if (isCompanyAdminOrUser(this.props.userRole) && flight.costCenter) {
        company = flight.costCenter.name;
      }
      const carriers = this.props.travelersFlightsFilterEmbedded.carriers;
      const carrier = carriers.find(carrier => flight.carrierName === carrier.label);
      return  {
          'Traveler': flight.travelerFullName,
          'VIP': flight.isVip,
          'Company': company,
          'Flight': (carrier ? carrier.value + ' ' : '') + flight.flightNumber,
          'Carrier': (flight.operatedByMarketingCarrier ? 'Operated by ' : '') + flight.carrierName,
          'O/D': flight.departureLocation.code + ' - ' + flight.arrivalLocation.code,
          'Distance (mi)': flight.carbon && flight.carbon.distance ? Math.round(flight.carbon.distance): 'N/A',
          'Emission (lbs)': flight.carbon && flight.carbon.emission ? Math.round(flight.carbon.emission) : 'N/A',
          'Aircraft' : flight.aircraft ? flight.aircraft : 'N/A',
          'Class' : flight.classOfService ? flight.classOfService : 'N/A'
      }
    });
    return data;
  }

  rows() {
    const { l } = this.context.i18n;

    const rows = this.props.travelersFlights.map((flight) => {
      const vip = flight.isVip ? (<span className='vip'>VIP</span>) : '';
      const blockedStatus = flight.blockedStatus === 'blocked' ? (
        <span className='icon blocked'>{l('blocked')}</span>
      ) : null;
      let nameTd;
      if (!this.props.compact) {
        let orgName = flight.organization.name;
        if (isCompanyAdminOrUser(this.props.userRole) && flight.costCenter) {
          orgName = flight.costCenter.name;
        }
        nameTd = (
          <td >
            {blockedStatus}
            <div className='traveler-info'>
              <div className='name'>
                <Link to={`/traveler/${flight.subscriberId.$oid}`}>
                  {flight.travelerFullName}
                </Link>
                {vip}
              </div>
              <span className='organization'>{orgName}</span>
            </div>
          </td>
        );
      }

      const sidebarState = this.props.sidebar;
      const carriers = this.props.travelersFlightsFilterEmbedded.carriers;
      const carrier = carriers.find(carrier => flight.carrierName === carrier.label);

      return (
        <tr key={flight._id.$oid} className='normal-bg'>
          {nameTd}
          <td >
            <div>
              <p><VendorImage type='flight' code={carrier ? carrier.value : 'DEFAULT'} /> {carrier ? carrier.value : ''} {flight.flightNumber}</p>
            </div>
          </td>
          <td >
            <p className='origin'>
              <div>
                O: {(sidebarState.isOpened || sidebarState.isSidebarPinned) ? flight.departureLocation.code : this.getAirportName(flight.departureLocation.code)}
              </div>
              <div>
                D: {(sidebarState.isOpened || sidebarState.isSidebarPinned) ? flight.arrivalLocation.code : this.getAirportName(flight.arrivalLocation.code)}
              </div>
            </p>
          </td>
          <td className='distance'>
            <p className='distance'>{flight.carbon && flight.carbon.distance ? Math.round(flight.carbon.distance) : 'N/A'}</p>
          </td>
          <td className='emission'>
            <p className='emission'>{flight.carbon && flight.carbon.emission ? Math.round(flight.carbon.emission) : 'N/A'}</p>
          </td>
          <td className='aircraft'>
            <p>{flight.aircraft ? flight.aircraft : 'N/A'}</p>
          </td>
          <td className='travel-class'>
            <p>{flight.classOfService ? flight.classOfService : 'N/A'}</p>
          </td>
        </tr>
      );
    });

    return rows;
  }

  getAirportName(code) {
    let airport = this.props.travelersFlightsFilterEmbedded.airports.find(airport => airport.value === code);
    return airport ? airport.label : '';
  }

  render() {
    const { l } = this.context.i18n;
    const sidebarState = this.props.sidebar;
    let nameTh;
    if (!this.props.compact) {
      nameTh = (
        <th className={this.sortClass('travelerFullName')}
        style={{paddingLeft:'10px'}}>
          <span onClick={this.sortBy('travelerFullName')}>{l('Traveler')}</span>
        </th>
      );
    }

    return (
      <table className='detail'>
        <thead>
          <tr>
            {nameTh}
            <th style={{paddingLeft:'10px'}}>
              <span>{l('Flight')}</span>
            </th>
            <th className={( (sidebarState.isOpened || sidebarState.isSidebarPinned) ? this.sortClass('startStation.code') :'')}
                style={{paddingLeft:'10px'}}>
              <span onClick={( (sidebarState.isOpened || sidebarState.isSidebarPinned) ? this.sortBy('startStation.code') : undefined )}>{(sidebarState.isOpened || sidebarState.isSidebarPinned) ? l('O/D') : l('Origin/Destination')}</span>
            </th>
            <th className={this.sortClass('carbon.distance')}
              style={{paddingLeft:'10px'}}>
              <span 
              onClick={this.sortBy('carbon.distance')}>{l('Distance (mi)')}</span>
            </th>
            <th className={this.sortClass('carbon.emission')}
              style={{paddingLeft:'10px'}}>
              <span 
              onClick={this.sortBy('carbon.emission')}>{l('Emissions (lbs)')}</span>
            </th>
            <th style={{paddingLeft:'10px'}}>
             <span>{l('Aircraft')}</span>
            </th>
            <th style={{paddingLeft:'10px'}}>
              <span>{l('Class')}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {this.rows()}
        </tbody>
      </table>
    );
  }
}

export default EmissionsTable;
