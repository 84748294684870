import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Cross from '../../styles/images/Icon_ Cancellation.png';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Alarm from '../../styles/images/Status-Red.svg';
import Warning from '../../styles/images/Status-Yellow.svg';
import Ok from '../../styles/images/Status-Green.svg';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom"
import config from '../../config/index';
import { connect } from 'react-redux';

import ContactView from '../../containers/Traveler/Contact';
import React, {  useEffect } from 'react';

import { stateAppSettingsConstants, stateAppSettingsDimensions } from '../../redux/modules/appSettings';
import { stateUserRole } from '../../redux/modules/user';
// import TravelerView from '../../views/TravelerView/TravelerView';

import { withRouter } from 'react-router-dom';
import { createSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import Cancellation from '../../styles/images/Cancellation-Icon_Delay.png';
import Close from '../../styles/images/Close.svg';
import isEqual from 'lodash/isEqual';

import Details from '../../containers/Traveler/Details';
import Chat from '../../containers/Common/Chat';
import ChatInvitation from '../../components/chat/chatInvitation';
import HelpedBlock from '../../containers/Traveler/HelpedBlock';
import AddCaseActionModal from '../../components/traveler/modals/addCaseActionModal';
import LoadingIcon from '../../components/common/loadingIcon';
import TimelineNotifications from '../../containers/Traveler/TimelineNotifications';

import Timeline from '../../containers/Traveler/Timeline';
import Reservations from '../../containers/Traveler/Reservations';
import Maps from '../../containers/Traveler/Maps';
import FlightStatus from '../../containers/Traveler/FlightStatus';
import TravelAdvisory from '../../containers/Traveler/TravelAdvisory';
import CaseHistory from '../../containers/Traveler/CaseHistory';
import { isCompanyAdminOrUser } from '../../helpers/user';



import {
  actions as travelerActions,
  stateTraveler,
  stateTravelAdvisoryLevel
} from '../../redux/modules/travelerDetails';
import { actions as timelineActions } from '../../redux/modules/timeline';
import { actions as reservationsActions } from '../../redux/modules/reservations';
import { actions as pusherActions } from '../../redux/modules/pusher';
const { travelerDetailsTopBarHeight, travelerDetailsNavBarHeight, headerHeight } = config.layout;


const styles = theme => ({
  root: {
    background: '#F1F2F4',
    border:0,

  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '1rem'
  },

  userName: {
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '0.4px',
    lineHeight: '16px'
  },

  tabs:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  tabSelected: {
      color:"#33323C",
      fontWeight:900
  },
  indicator: {
      height:'3px'
  }

});

const CustomTab = withStyles({

  selected: {
    fontWeight: 'bold',
  },
})(Tab);



const mapStateToProps = createSelector(
  stateTraveler,
  stateAppSettingsConstants,
  stateAppSettingsDimensions,
  stateUserRole,
  stateTravelAdvisoryLevel,
  (traveler, appSettingsConstants, appSettingsDimensions, userRole, advisoryLevel) => {
    appSettingsConstants = appSettingsConstants || {};
    return {
      traveler,
      appSettingsConstants,
      appSettingsDimensions,
      userRole,
      advisoryLevel
    };
  }
);

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(travelerActions, dispatch),
    ...bindActionCreators(pusherActions, dispatch),
    ...bindActionCreators(reservationsActions, dispatch),
    ...bindActionCreators(timelineActions, dispatch)
  };
};

export class UserLayoutComponent extends React.Component {
  static contextTypes = {
    i18n: PropTypes.object
  };

  static propTypes = {
    location: PropTypes.object,
    match: PropTypes.object,
    advisoryLevel: PropTypes.number,
    traveler: PropTypes.object.isRequired,
    appSettingsConstants: PropTypes.object.isRequired,
    appSettingsDimensions: PropTypes.object.isRequired,
    requestTraveler: PropTypes.func.isRequired,
    addCaseAction: PropTypes.func.isRequired,
    sendInvitation: PropTypes.func.isRequired,
    editCaseAction: PropTypes.func.isRequired,
    resetTimeline: PropTypes.func.isRequired,
    resetReservations: PropTypes.func.isRequired,
    loadTravelerAdvisory: PropTypes.func.isRequired,
    userRole: PropTypes.string
  };



  constructor (props) {
    super(props);
    this._addCaseActionModalRef = React.createRef();


    const tabActive = {
      '/traveler/:id':0,
      '/traveler/:id/reservations':1,
      '/traveler/:id/maps':2,
      '/traveler/:id/flight-status':3,
      '/traveler/:id/travel-advisories':4,
      '/traveler/:id/contact':5,
    };

    this.state = {
      hasMessages: false,
      value: tabActive[this.props.match.path]
    };
  }

  getTraveler (nextProps) {
    const props = this.props || nextProps;
    if (
      (props.match.params.id && !props.traveler._id) ||
      (props.traveler._id && props.traveler._id.$oid !== props.match.params.id)
    ) {
      this.props.resetReservations();
      this.props.resetTimeline();
      this.props.requestTraveler(props.match.params.id);
      this.props.loadTravelerAdvisory(props.match.params.id);
    }
  }
  //
  // handleChange = (event, newValue) => {
  //   this.setState({value:newValue});
  // };

  requestTravelere (id){
    this.props.requestTraveler(id);
  }


  shouldComponentUpdate (nextProps, nextState) {
    return (
      !isEqual(this.props.match, nextProps.match) ||
      !isEqual(this.props.traveler, nextProps.traveler) ||
      !isEqual(this.props.appSettingsConstants, nextProps.appSettingsConstants) ||
      !isEqual(this.props.appSettingsDimensions, nextProps.appSettingsDimensions) ||
      !isEqual(this.props.advisoryLevel, nextProps.advisoryLevel) ||
      !isEqual(this.state, nextState)
    );
  }

  UNSAFE_componentWillMount () {
    this.getTraveler();
  }

  componentDidUpdate (nextProps) {
    if (this.props.traveler && this.props.traveler._id) {
      this.getTraveler(nextProps);
    }
  }

  showAddActionModal = (actionId = '', subActionId = '', description, caseActionId, eTag) => {
    if (this._addCaseActionModalRef && this._addCaseActionModalRef.current) {
      this._addCaseActionModalRef.current.showAddCaseActionModal(
        actionId, subActionId, description, caseActionId, eTag);
    }
  }

  addCaseAction = (actionId, subActionId, description, caseActionId, eTag) => {
    // if we have caseId then we need to edit case
    if (caseActionId) {
      this.props.editCaseAction(this.props.traveler, actionId, subActionId, description, caseActionId, eTag);
    } else {
      // else we need to post new case
      this.props.addCaseAction(this.props.traveler, actionId, subActionId, description);
    }
  }

  chatMessages = (messages) => {
    const { id } = this.props.match.params;
    this.setState({
      hasMessages:
        (messages && messages.length && this.state.hasMessages === id) ||
        (this.state.hasMessages === false && id)
          ? id
          : false
    });
  }

  render () {
    console.log(this.props)
    const {traveler} = this.props;
    const {currentJourney} = traveler;

    let statusIco=null;

     if(traveler.hasOwnProperty('currentJourney')){
      let temp = traveler.currentJourney. statusCode;

    if(temp === 'alarm'){
      statusIco = <img src={Alarm} style={{marginRight: '8px'}} alt='status'/>
    }else
    if(temp === 'warning'){
      statusIco = <img src={Warning} style={{marginRight: '8px'}} alt='status'/>
    }else{
      statusIco = <img src={Ok} style={{marginRight: '8px'}} alt='status'/>
    }}
    const { classes } = this.props;
    const { l } = this.context.i18n;
    const { id } = this.props.match.params;
    const {
      location,  appSettingsConstants, appSettingsDimensions: { height },
      userRole, advisoryLevel
    } = this.props;
    console.log(this.props);
    const contentHeight = height - travelerDetailsTopBarHeight - headerHeight - travelerDetailsNavBarHeight;
    const hasApp = traveler.app_4site && traveler.app_4site.status;

    let loading = false;
    if (!traveler._id || !appSettingsConstants.caseActions) {
      loading = true;
    };

    const helpedBlock = this.props.traveler.status === 'active' ? (
      <HelpedBlock
        travelerId={id}
        helpedBy={traveler.helpedBy}
        extend='true'
        addTravelerAction={this.showAddActionModal} />
    ) : null;

    const timelimeNotifications = this.props.traveler.status === 'active' ? (
      <TimelineNotifications uid={id} />
    ) : null;

    const chat = hasApp ? <Chat travelerId={id} height={contentHeight} newMessages={this.chatMessages} /> : null;
    const inv = !hasApp
      ? <ChatInvitation traveler={traveler} userRole={userRole} sendInvitation={this.props.sendInvitation} />
      : null;
    const actions = appSettingsConstants.caseActions ? appSettingsConstants.caseActions.actions : [];
    const childProps = {
      traveler: traveler,
      addTravelerAction: this.showAddActionModal,
      ...this.props
    };
    // const flightDate = new Date(this.props.traveler.geoLocation.date["$date"]);
    // // let {name} = this.props.traveler.organization;
    // console.log(traveler.organiza);


    let name;
    let orgName = traveler.hasOwnProperty('organization') ? traveler.organization.name : null;

    if ( isCompanyAdminOrUser(userRole) ) {
      orgName = traveler.hasOwnProperty('costCenter') && traveler.costCenter ? traveler.costCenter.name : orgName;
    }
    name = orgName ? <div style={{paddingLeft:'30px',fontSize:'16px'}}>{orgName}</div>:<div></div>
    let advLevel = <span className={'ta-level level-' + advisoryLevel}>{advisoryLevel}</span>;
    const vip = this.props.traveler.isVIP ? (<span className='vip'>VIP</span>) : '';
    const content = (<>
      <div style={{ width: '100%' }}>
        <div className={classes.header}>
          <div style={{minWidth:'200px',paddingTop:'4px'}}>
          <span>{statusIco}
          </span>

            <span className={classes.userName}>{this.props.traveler.firstName}  {this.props.traveler.lastName} {vip}</span>
            {name}



          </div>

          {timelimeNotifications}
          <div>
            <Link to="/active-travelers/locations">{<img className='t-close' src={Close} alt=''/>}</Link>
          </div>
        </div>



        <Tabs
          value={location.pathname}
          indicatorColor="primary"
          // textColor="primary"
          variant="fullWidth"
          classes={{
            root: classes.root, // class name, e.g. `classes-nesting-root-x`
            label: classes.label,
            indicator:classes.indicator// class name, e.g. `classes-nesting-label-x`
          }}
        >
          <Tab classes={{ selected: classes.tabSelected}} label="Timeline" style={{textTransform: 'none'}} to={'/traveler/'+this.props.uid} component={Link} value={'/traveler/'+this.props.uid} />
          <Tab classes={{ selected: classes.tabSelected}} label="Reservations" style={{textTransform: 'none'}} to={'/traveler/'+this.props.uid+'/reservations'} component={Link} value={'/traveler/'+this.props.uid+'/reservations'}/>
          <Tab classes={{ selected: classes.tabSelected}} label="Maps" style={{textTransform: 'none'}} to={'/traveler/'+this.props.uid+'/maps'} component={Link} value={'/traveler/'+this.props.uid+'/maps'}  />
          <Tab classes={{ selected: classes.tabSelected}} label="Flight Status" style={{textTransform: 'none'}} to={'/traveler/'+this.props.uid+'/flight-status'}  component={Link}  value={'/traveler/'+this.props.uid+'/flight-status'} />
          <Tab classes={{ selected: classes.tabSelected}} label={
            <React.Fragment>
              <span style={{paddingBottom:'5px'}}>Travel Advisories <span className={'ta-level level-' + advisoryLevel}>{advisoryLevel}</span></span>
            </React.Fragment>
          }
               style={{textTransform: 'none'}} to={'/traveler/'+this.props.uid+'/travel-advisories'}  component={Link} value={'/traveler/'+this.props.uid+'/travel-advisories'} />
          <Tab label="Contact" style={{textTransform: 'none'}} to={'/traveler/'+this.props.uid+'/contact'} component={Link} value={'/traveler/'+this.props.uid+'/contact'} />
        </Tabs>

      <div>
        <Route exact path='/traveler/:id' render={(props) => <Timeline {...childProps} />} />
        <Route path='/traveler/:id/reservations' render={(props) => <Reservations {...childProps} /> } />
        <Route path='/traveler/:id/maps' render={(props) => <Maps {...childProps} /> } />
        <Route path='/traveler/:id/flight-status' render={(props) => <FlightStatus {...childProps} /> } />
        <Route path='/traveler/:id/travel-advisories' render={(props) => <TravelAdvisory {...childProps} /> } />
        <Route path='/traveler/:id/contact' render={(props) => <ContactView uid={this.props.uid} reqUser={this.requestTravelere} {...childProps}/> } />
      </div>
      </div>

    </>);

    return (
      <div style={{width: '100%'}}>
        <LoadingIcon loading={loading} />
        {!loading ? content : <br />}
      </div>
    );
  }
}





UserLayoutComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserLayoutComponent)));
