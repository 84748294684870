import React from "react";
import { connect } from "react-redux";

import { bindActionCreators } from "redux";

import { createSelector } from "reselect";
import {
  stateActiveTravelers,
  stateFilter
} from "../../redux/modules/activeTravelers";
import {
  actions as airportStatusesActions,
  stateAirportStatuses,
  stateFilterAirportStatus,
  stateFilterAirportTimeframe
} from "../../redux/modules/airportStatuses";
import {
  actions as mapActions,
  stateAtMapView,
  stateOnTheMap,
  stateAreaShown
} from "../../redux/modules/atMap";
import {
  actions as flightStatusActions,
  stateFlightsStatuses,
  stateFilterFlightStatusTimeframe
} from "../../redux/modules/flightsStatusMap";
import { stateUserRole } from "../../redux/modules/user";

const mapStateToProps = createSelector(
  stateActiveTravelers,
  stateFilter,
  stateAtMapView,
  stateOnTheMap,
  stateAirportStatuses,
  stateFilterAirportStatus,
  stateFilterAirportTimeframe,
  stateFlightsStatuses,
  stateFilterFlightStatusTimeframe,
  stateUserRole,
  stateAreaShown,
  (
    activeTravelers,
    filter,
    atMapView,
    onTheMap,
    airports,
    filterAirportStatus,
    filterAirportTimeframe,
    flights,
    filterFlightStatusTimeframe,
    userRole,
    areaShown
  ) => {
    return {
      mapView: atMapView
    };
  }
);

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(mapActions, dispatch)
  };
};
class MapControlsLeftMenu extends React.Component {
  switchMapView = mapView => e => {
    e.preventDefault();
    this.props.atSwitchMapView(mapView);
  };
  render() {
    const { mapView } = this.props;
    const locationsActive = mapView === "locations" ? "active" : "";
    const airportStatusActive = mapView === "airportStatus" ? "active" : "";
    const flightsStatusActive = mapView === "flightsStatus" ? "active" : "";
    return (
      <div className="map-filters">
        <ul className="tabset">
          <li
            id="map-locations"
            style={{ float: "left", marginRight: 20 }}
            className={locationsActive}
          >
            <a href="" onClick={this.switchMapView("locations")}>
              {"Locations"}
            </a>
          </li>
          <li
            id="map-airports"
            style={{ float: "left", marginRight: 20 }}
            className={airportStatusActive}
          >
            <a href="" onClick={this.switchMapView("airportStatus")}>
              {"Airports"}
            </a>
          </li>
          <li
            id="map-flights"
            style={{ float: "left", marginRight: 20 }}
            className={flightsStatusActive}
          >
            <a href="" onClick={this.switchMapView("flightsStatus")}>
              {"Flights"}
            </a>
          </li>
        </ul>
        {/* {this.filters} */}
      </div>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapControlsLeftMenu);
