import PropTypes from "prop-types";

import React from "react";

import { connect } from "react-redux";
import includes from "lodash/includes";
import { createSelector } from "reselect";

import { bindActionCreators } from "redux";
import '../../styles/_map.scss';
import { airport } from "../../redux/modules/sampleairport";
import FilterCOmponent from "../../components/common/filterComponent";

import MapboxCompare from "mapbox-gl-compare";
import $ from "jquery";

import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";
import lodashFilter from "lodash/filter";

import config from "../../config";
import activeTravelersFilter from "../../helpers/activeTravelersFilter";
import {
  airportStatusesStatusFilter,
  airportStatusesTimeframeFilter,
  airportStatusesByTravelers,
  availableStatusesFilter,
  availableAirportTimeframes,
  getAvailableAirportStatuses
} from "../../helpers/airportStatusesFilters";
import {
  flightsStatusesByTravelers,
  flightsStatusesTimeframeFilter,
  flightStatusesByTravelerStatus,
} from "../../helpers/flightsStatusesFilters";
import { sessionStorage } from "../../helpers/localStorage";
import {
  drawAirportStatusesHelper,
  drawLocationsHelper,
  drawFlightStatusesArcsHelper,
  drawFlightStatusesArcsForEmission,
  popupTabsInit,
  clearMap,
  clearMap1,
  createMap,
  getBBoxForContinent
} from "../../helpers/map";
import MapControls from "../../components/common/mapControls";
import {
  stateActiveTravelers,
  stateFilter
} from "../../redux/modules/activeTravelers";
import {
  actions as airportStatusesActions,
  stateAirportStatuses,
  stateFilterAirportStatus,
  stateFilterAirportTimeframe
} from "../../redux/modules/airportStatuses";
import {
  actions as mapActions,
  stateAtMapView,
  stateOnTheMap,
  stateAreaShown
} from "../../redux/modules/atMap";
import {
  actions as flightStatusActions,
  stateFlightsStatuses,
  stateFilterFlightStatusTimeframe
} from "../../redux/modules/flightsStatusMap";
import { stateUserRole, stateUserDefaultContintent } from "../../redux/modules/user";
import { stateUserSettings } from "../../redux/modules/userSettings";
import { stateSidebar } from "../../redux/modules/sidebar";
import mapboxgl from "mapbox-gl/dist/mapbox-gl-csp";

const timeframes = config.map.timeframes;

const getFilterTimeFrame = (timeframe) => {
  return (timeframe === 30 ? 0.5 : timeframe === 90 ? 1.5 : timeframe / 60)
}

const mapStateToProps = createSelector(
  stateActiveTravelers,
  stateFilter,
  stateAtMapView,
  stateOnTheMap,
  stateAirportStatuses,
  stateFilterAirportStatus,
  stateFilterAirportTimeframe,
  stateFlightsStatuses,
  stateFilterFlightStatusTimeframe,
  stateUserRole,
  stateAreaShown,
  stateSidebar,
  stateUserSettings,
  stateUserDefaultContintent,
  (
    activeTravelers,
    filter,
    atMapView,
    onTheMap,
    airports,
    filterAirportStatus,
    filterAirportTimeframe,
    flights,
    filterFlightStatusTimeframe,
    userRole,
    areaShown,
    sidebar,
    userSettings,
    userDefaultContinent
  ) => {

    // set filterAirportTimeframe and filterFlightStatusTimeframe from userSettings if they are null
    if (!filterAirportTimeframe) {
      let newTimeFrameInMinutes = userSettings.airportsWithin;
      filterAirportTimeframe = getFilterTimeFrame(newTimeFrameInMinutes);
    }
    if (!filterFlightStatusTimeframe) {
      let newTimeFrameInMinutes = userSettings.flightsWithin;
      filterFlightStatusTimeframe = getFilterTimeFrame(newTimeFrameInMinutes);
    }
    let filteredActiveTravelers = activeTravelers;
    if (activeTravelers.length) {
      filteredActiveTravelers = activeTravelersFilter(activeTravelers, {
        ...filter,
        areaShown
      });
      filteredActiveTravelers = lodashFilter(filteredActiveTravelers, {
        status: "active"
      });
    }
    // airports
    // get available airport statuses for select field
    let availableAirportStatuses = getAvailableAirportStatuses(airports);
    // filter by airport status
    let availableAirportTimeframesOptions = timeframes;
    if (atMapView === "airportStatus") {
      // filter by airport presence timeframe
      if (filterAirportTimeframe && airports.length) {
        airports = airportStatusesTimeframeFilter(
          airports,
          filterAirportTimeframe
        );
        availableAirportStatuses = availableStatusesFilter(
          availableAirportStatuses,
          airports
        );
      }

      if (filterAirportStatus && airports.length) {
        airports = airports.filter( (airport) => filterAirportStatus.includes(airport.status.color) );
      }

      // display airports only with filtered active travelers
      if (
        activeTravelers &&
        activeTravelers.length !== filteredActiveTravelers.length &&
        airports.length
      ) {
        airports = airportStatusesByTravelers(
          airports,
          filteredActiveTravelers
        );
      }
    }
    // flights
    // display flight statuses only with filtered active travelers
    const availableFlightStatusTimeframesOptions = timeframes;
    // filter by flight presence timeframe
    // console.log(
    //   atMapView,
    //   "flightsStatus",
    //   flights,
    //   filterFlightStatusTimeframe
    // );
    if (atMapView === "flightsStatus") {
      if (filterFlightStatusTimeframe && flights.length) {
        // console.log("FIRST CASAE");
        flights = flightsStatusesTimeframeFilter(
          flights,
          filterFlightStatusTimeframe
        );
      }
      if (
        activeTravelers &&
        activeTravelers.length !== filteredActiveTravelers.length &&
        flights.length
      ) {
        // console.log("SECOND CASAE");
        flights = flightsStatusesByTravelers(flights, filteredActiveTravelers);
        console.log(flights, "returned value");
      }
    }

    // Filter flightStatuses further if mapView is flightStatus
    // based on the travelersFilter status
    // If red is chosen filter out any thing that is not red in flightStatuses
    if (atMapView === 'flightsStatus') {
      flights = flightStatusesByTravelerStatus(flights, filter.status)
    }

    return {
      filteredActiveTravelers: filteredActiveTravelers,
      airports: airports,
      flights: flights,
      zoom: sessionStorage.get("AtZoom"),
      lat: sessionStorage.get("AtLat"),
      lng: sessionStorage.get("AtLng"),
      mapView: atMapView,
      onTheMap: onTheMap,
      availableAirportStatuses: availableAirportStatuses,
      availableAirportTimeframes: availableAirportTimeframesOptions,
      availableFlightStatusTimeframes: availableFlightStatusTimeframesOptions,
      filterAirportStatus: filterAirportStatus,
      filterAirportTimeframe: filterAirportTimeframe,
      filterFlightStatusTimeframe: filterFlightStatusTimeframe,
      userRole,
      sidebar,
      userDefaultContinent
    };
  }
);

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(mapActions, dispatch),
    ...bindActionCreators(airportStatusesActions, dispatch),
    ...bindActionCreators(flightStatusActions, dispatch)
  };
};

const LegendRow = props => {
  return (
    <div  className='legend-row'>
      {props.from === "airports" && (
        <input  type="checkbox" />
      )}
      <span style={{backgroundColor:props.data.color}}></span>
      {props.data.text}
    </div>
  );
};

export class AtMapContainer extends React.Component {
  static propTypes = {
    filteredActiveTravelers: PropTypes.array.isRequired,
    airports: PropTypes.array.isRequired,
    flights: PropTypes.array.isRequired,
    availableAirportStatuses: PropTypes.array.isRequired,
    availableAirportTimeframes: PropTypes.array.isRequired,
    availableFlightStatusTimeframes: PropTypes.array.isRequired,
    zoom: PropTypes.number.isRequired,
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    filterFlightStatusTimeframe: PropTypes.number.isRequired,
    filterAirportTimeframe: PropTypes.number.isRequired,
    filterAirportStatus: PropTypes.string.isRequired,
    userRole: PropTypes.string.isRequired,
    mapView: PropTypes.string.isRequired,
    onTheMap: PropTypes.bool.isRequired,
    atSwitchMapView: PropTypes.func.isRequired,
    atChangeOnTheMapStatus: PropTypes.func.isRequired,
    atChangeMarkersOnMap: PropTypes.func.isRequired,
    changeAirportStatusFilter: PropTypes.func.isRequired,
    changeFlightStatusTimeframeFilter: PropTypes.func.isRequired,
    changeAirportTimeframeFilter: PropTypes.func.isRequired,
    areaShown: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  };

  static contextTypes = {
    i18n: PropTypes.object
  };

  constructor(props) {
    super(props);
    this._mapcontainer = React.createRef();
    this._mapcontainer1 = React.createRef();
    this._markerClusterGroup = [];
    this._airportMarkers = [];
    this._flightsArcs = null;
    this._inBounds = [];
    this.comparemap = null;
    this.state = {
      pinnedFlightPopup: false,
      activeLegend: "locations",
      legend: true,
      status:true,
      showsecond: false,
      locations: [
        {
          id: 'location1',
          text: "Multiple Travelers",
          color: "#1D4A93"
        },
        {
          id: 'location2',
          text: "Canceled / Delayed > 45 mins",
          color: "#F25050"
        },
        {
          id: 'location3',
          text: "Delayed 15 - 45 mins",
          color: "#FFB803"
        },
        {
          id: 'location4',
          text: "On Time / Delayed < 15 mins",
          color: "#4BC07A"
        }
      ],
      airports: [
        {
          id: 'airport1',
          text: "Airport Closed",
          color: "#000000",

        },
        {
          id: 'airport2',
          text: "Canceled / Delayed > 45 mins",
          color: "#F25050",

        },
        {
          id: 'airport3',
          text: "Delayed 15 - 45 mins",
          color: "#FFB803",
          // fliter: false
        },
        {
          id: 'airport4',
          text: "On Time / Delayed < 15 mins",
          color: "#4BC07A",
          // fliter: false
        }
      ],
      flightArcs: [
        {
          id: 'flightarc1',
          text: "Canceled / Delayed > 45 mins",
          color: "#F25050"
        },
        {
          id: 'flightarc2',
          text: "Delayed 15 - 45 mins",
          color: "#FFB803"
        },
        {
          id: 'flightarc3',
          text: "On Time / Delayed < 15 mins",
          color: "#4BC07A"
        }
      ]
    };
    // Popup for showing emission or flight data
    this._popup = new mapboxgl.Popup({
      closeButton: true,
      closeOnClick: false,
      offset: 25,
      maxWidth:"600px"
    });
  }

  clearActiveTravelerMap = from => {
    const result = clearMap(
      this._map,
      this._markerClusterGroup,
      this._airportMarkers,
      this._flightsArcs,
      !!this.state.pinnedFlightPopup,
      from
    );
    clearMap1(this._map1, this._popup);
    if (from !== "flightarcs" && this.comparemap) {
      this.comparemap.remove();
      this.comparemap = null;
    }

    this._markerClusterGroup = result.markerClusterGroup;
    this._airportMarkers = result.airportMarkers;
    this._flightsArcs = result.flightsArcs;
    this._popup.remove();
  };

  drawLocations =  () => {
    this.clearActiveTravelerMap("locations");
    this._markerClusterGroup = drawLocationsHelper(
      this._markerClusterGroup,
      this.props.filteredActiveTravelers,
      this._map,
      this.props.userRole,
      this._popup
    );
  };

  drawAirportStatuses = () => {
      // replace airport with this.props.airports
    this.clearActiveTravelerMap("airport");
    this._airportMarkers = drawAirportStatusesHelper(
      this._airportMarkers,
      this.props.airports,
      this._map,
      this.props.filterAirportTimeframe,
      "active-travelers",
      this._popup
    );


  };

  drawFlightsStatuses = () => {

    this.clearActiveTravelerMap("flightarcs");
    //put this.props.flights instead of sampleflights
    this._flightsArcs = drawFlightStatusesArcsHelper(
      this.props.flights,
      this.props.filteredActiveTravelers,
      this._map,
      this.props.userRole,
      this._popup
    );
    this._flightsArcs1 = drawFlightStatusesArcsForEmission(
      this.props.flights,
      this.props.filteredActiveTravelers,
      this.props.filterFlightStatusTimeframe,
      this._map1,
      this._popup
    );
    var container = "#comparison-container";
    console.log(this.comparemap);
    if (!this.comparemap) {
      console.log(
        "****************************************************** DJ",
        this.comparemap
      );
      this.comparemap = new MapboxCompare(
        this._map,
        this._map1,
        container,
        {
          // Set this to enable comparing two maps by mouse movement:
          // mousemove: true
        }
      );
      this.comparemap.on('slideend', (e) => {
        console.log(e.currentPosition);
        if(e.currentPosition < 5){
          this.props.onSlideEnd(true);
          this.setState({
            status:false
          })
        }else{
          this.props.onSlideEnd(false);
          this.setState({
            status:true
          })
        }
        let container_bounds = this._map.getContainer().getBoundingClientRect();
        this._comparemap_container_width = container_bounds.width;
      });
      let container_bounds = this._map.getContainer().getBoundingClientRect();
      let comparemap_x = container_bounds.x + container_bounds.width;
      this.comparemap.setSlider(comparemap_x);
    }
  };

  updateEmissionsMapLocationAndZoom = () => {
    this._map1.setCenter(this._map.getCenter());
    this._map1.setZoom(this._map.getZoom());
  }

  populateMap = () => {
    if (this.props.mapView === "locations") {
      this.drawLocations();
    } else if (this.props.mapView === "airportStatus") {
      if (this._airportMarkers !== null) {
        for (var i = this._airportMarkers.length - 1; i >= 0; i--) {
          this._airportMarkers[i].remove();
        }
        this._airportMarkers = [];
      }
      this.drawAirportStatuses();
    } else if (this.props.mapView === "flightsStatus") {
      // removes locations layer
      this.updateEmissionsMapLocationAndZoom();
      if (this._map.getLayer("flight-path")) {
        this._map.removeLayer("flight-path");
      }
      if (this._map.getSource("flight-path")) {
        this._map.removeSource("flight-path");
      }

      // removes locations layer
      if (this._map.getLayer("multiple-lines-layer")) {
        this._map.removeLayer("multiple-lines-layer");
      }
      if (this._map.getLayer("multiple-lines-invisible-layer")) {
        this._map.removeLayer("multiple-lines-invisible-layer");
      }
      if (this._map.getSource("multiple-lines-source")) {
        this._map.removeSource("multiple-lines-source");
      }

      this.drawFlightsStatuses();
    }
  };

  setMaxBounds = (mapView, mapsList) => {
    let maxBounds = [
      [-180, -85], // [west, south]
      [180, 85]  // [east, north]
    ];
    if (mapView === "flightsStatus") {
      // For flightStatus dont set maxbounds so we can scroll the map all the way.
      maxBounds = null;
    }
    mapsList.forEach( (map) => map.setMaxBounds(maxBounds));
  }

  debouncedPopulateMap = debounce((prevProps) => {
    this.populateMap();
    // Set MaxBounds for each of the views
    this.setMaxBounds(this.props.mapView, [this._map, this._map1]);

    if (!isEqual(this.props.mapView, prevProps.mapView)) {
      let bbox = getBBoxForContinent(this.props.userDefaultContinent);

      // Now set the map to user's default continent
      if (bbox && bbox.length) {
        // This happens when the defaultContinent is anything but global.
        // global needs to be addressed separately.
        if(this._map) this._map.fitBounds(bbox);
        if(this._map1) this._map1.fitBounds(bbox);
      } else {
        // This is for ppl who dont have a default continent.
        // So set this to default values available from config.
        [this._map, this._map1].forEach( (map) => {
          map.setCenter([config.map.defaultLon, config.map.defaultLat]);
          map.setZoom(config.map.minZoom);
        })
      }
    }

    setTimeout(() => {
      this.updateTravelersShown();
    }, 1000);

  }, 300);

  componentDidUpdate(prevProps) {
    if (this._map.loaded()) {
      console.log('Map is loaded, calling popuplateMap');
      this.debouncedPopulateMap(prevProps);
    } else {
      this._map.on("load", () => {
        console.log('Map on load is being called, calling popuplateMap');
        this.debouncedPopulateMap(prevProps);
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    //console.log('Inside shouldComponentUpdate of atMap');
    let shouldUpdate = (
      !isEqual(
        this.props.filteredActiveTravelers,
        nextProps.filteredActiveTravelers
      ) ||
      !isEqual(this.props.airports, nextProps.airports) ||
      !isEqual(this.props.flights, nextProps.flights) ||
      !isEqual(
        this.props.availableAirportStatuses,
        nextProps.availableAirportStatuses
      ) ||
      !isEqual(
        this.props.availableAirportTimeframes,
        nextProps.availableAirportTimeframes
      ) ||
      !isEqual(
        this.props.availableFlightStatusTimeframes,
        nextProps.availableFlightStatusTimeframes
      ) ||
      !isEqual(
        this.props.filterAirportTimeframe,
        nextProps.filterAirportTimeframe
      ) ||
      !isEqual(
        this.props.filterFlightStatusTimeframe,
        nextProps.filterFlightStatusTimeframe
      ) ||
      // Below Commented due to it triggering map view load twice
      // !isEqual(nextState.activeLegend, this.state.activeLegend) ||
      !isEqual(nextState.legend, this.state.legend) ||
      !isEqual(nextState.status, this.state.status) ||
      !isEqual(this.props.filterAirportStatus, nextProps.filterAirportStatus) ||
      !isEqual(this.props.mapView, nextProps.mapView) ||
      !isEqual(this.props.onTheMap, nextProps.onTheMap) ||
      !isEqual(this.props.areaShown, nextProps.areaShown)
    );

    console.log(this.comparemap);

    console.log(shouldUpdate, 'shouldUpdate AtMap');
    if (!shouldUpdate) {
      // When the side nav bar expands or contracts the map need to be resized
      // and the slider needs to be set accordingly.

      //console.log(this.props.sidebar);
      //console.log(nextProps.sidebar);
      if (!isEqual(this.props.sidebar, nextProps.sidebar)) {
          if (this._map) {
            // console.log('Now Resizing Two Maps');
            setTimeout( () => {
                this._map.resize();
                this._map1.resize();
              },
              250
            );
          }
      }
    };
    return shouldUpdate;
  }

  componentDidMount() {

    const {userDefaultContinent} = this.props;

    this._map = createMap(
      this._mapcontainer.current,
      this.props.lng,
      this.props.lat,
      this.props.zoom,
      userDefaultContinent
    );

    this._map1 = createMap(
      this._mapcontainer1.current,
      this.props.lng,
      this.props.lat,
      this.props.zoom,
      userDefaultContinent
    );

    // this.populateMap();

    // add debounce for updates to prevent memory leak
    const setCoords = debounce(() => {
      const center = this._map.getCenter();
      if (this.props.mapView !== "flightsStatus") {
        this._map1.setCenter(center);
        this._map1.setZoom(this._map.getZoom());
      }
    }, 300);


    this._map.on('moveend', () => {
      setCoords();
      this.updateTravelersShown();
    });

    this._map.on("click", () => {

      this.setState({
        pinnedFlightPopup: false
      });
    });

    this._map.on("resize", () => {

      const setSliderPosition = (newPosition) => {
        setTimeout( () => {
          this.comparemap.setSlider(newPosition);
        }, 50);
      };

      if (this.comparemap) {
        let container_bounds = this._map.getContainer().getBoundingClientRect();
        if (this._comparemap_container_width) {
          let oldWidth =  this._comparemap_container_width;
          let newWidth = container_bounds.width;
          let widthDifference = this._comparemap_container_width - container_bounds.width;
          if (  Math.abs(widthDifference) > 5 ) {
            let comparemap_x = this.comparemap.currentPosition * (newWidth/oldWidth);
            setSliderPosition(comparemap_x);
          }
          this._comparemap_container_width = container_bounds.width;
        } else {
          setSliderPosition(container_bounds.width);
        }
      }

    });


    const setZoom = debounce(() => {
      // TODO: mapbox-gl POPUP
      // this._map.closePopup();
      this.setState({
        pinnedFlightPopup: false
      });

      let nextZoom = this._map.getZoom();
      if (nextZoom > config.map.maxZoom) {
        nextZoom = config.map.maxZoom;
      }

      if (nextZoom < config.map.minZoom) {
        nextZoom = config.map.minZoom;
      }

      sessionStorage.set("AtZoom", nextZoom);
    }, 200);

    this._map.on("zoomend", setZoom);

    popupTabsInit();

    if (this._map.invalidateSize) {
      $(window).on("layout-changes", () => {
        this._map.invalidateSize(false);
        setTimeout(this._map.invalidateSize, 50);
      });
    }
  }

  panMapTo = coords => {
    this._map.panTo(coords);
    if (this._map.getZoom() > config.map.minZoom) {
      this._map.setZoom(config.map.minZoom);
    }
  };

  updateTravelersShown() {
    let ids = [];

    if (this.props.mapView === 'locations') {
      this.props.onSlideEnd(false);
      this.setState({
        status:true
      })
      let bounds = this._map.getBounds();
      this.props.filteredActiveTravelers.forEach( (traveler) => {
        if ( traveler.geoLocation && traveler.geoLocation.longitude && traveler.geoLocation.latitude) {
          let travelerLngLat = new mapboxgl.LngLat(traveler.geoLocation.longitude,traveler.geoLocation.latitude);
          if(bounds.contains(travelerLngLat)) {
            ids.push(traveler._id.$oid)
          }
        }
      });
    } else if (this.props.mapView === "flightsStatus") {
      if (this._map.getLayer('multiple-lines-layer')) {
        let features = this._map.queryRenderedFeatures({ layers: ['multiple-lines-layer'] });
        if (features) {
          let uniqueCodes = [];
          features.forEach(feature => {
            let routeCode = feature.properties.routeCode;
            if (!uniqueCodes.includes(routeCode)) {
              let routeIds = feature.properties.ids;
              ids.push(...routeIds.split(','));
            }
          });
        }
      }
    } else if (this.props.mapView === "airportStatus") {
      this.props.onSlideEnd(false);
      this.setState({
        status:true
      })
      let bounds = this._map.getBounds();
      let uniqueCodes = [];
      this._airportMarkers.forEach((marker) => {
        if (bounds.contains(marker.getLngLat())) {
          let markerEl = marker.getElement();
          let airportCode = marker.getElement().code;
          if (!uniqueCodes.includes(airportCode)) {
            uniqueCodes.push(airportCode);
            ids.push(...markerEl.ids);
          }
        }
      });
    }
    ids = ids.filter((x, i, a) => a.indexOf(x) == i);
    this.props.atChangeMarkersOnMap(ids);
  }

  componentWillUnmount() {
    this.props.onSlideEnd(false);
    this._popup.remove();
    this._map.remove();
    this._map = null;
    this._map1.remove()
    this._map1 = null;
    if (this.comparemap) {
      this.comparemap.remove();
      this.comparemap = null;
    }
    this._markerClusterGroup = [];
    this._airportMarkers = [];
    this._flightsArcs = null;

    this.props.atSwitchMapView(null);
  }

  pineFlightPopup = (line1, line2) => {
    this.setState({
      pinnedFlightPopup: true
    });
  };

  shouldShowAnotherPopup = () => {
    return !this.state.pinnedFlightPopup;
  };

  render() {
    let statusesAirport = [];
    if (this.props.availableAirportStatuses) {
      // const { l } = this.context.i18n;
      const statusLabels = [
        { value: "green", label: "On Time / Delayed < 15 mins", color: "#4BC07A" },
        { value: "yellow", label: "Delayed 15 - 45 mins", color: "#FFB803" },
        { value: "red", label: "Canceled / Delayed > 45 mins", color: "#F25050" },
        { value: "black", label: "Airport Closed", color: "#000000" }
      ];
      // const statuses = statusLabels.filter(status => {
      //   return includes(this.props.availableAirportStatuses, status.value);
      // });
      // statuses.unshift({ value: "", label: "All Statuses" });
      statusesAirport = statusLabels;
    }
    let clslegend = "legend";
    if(this.state.status === false){
      clslegend = "legend bottom11rem"
    }
    return (
      <div className="activeTravelersMap mainmap_control">
        <MapControls
          airportStatuses={this.props.availableAirportStatuses}
          airportTimeframes={this.props.availableAirportTimeframes}
          flightStatusTimeframes={this.props.availableFlightStatusTimeframes}
          filterAirportTimeframe={this.props.filterAirportTimeframe}
          filterFlightStatusTimeframe={this.props.filterFlightStatusTimeframe}
          filterAirportStatus={this.props.filterAirportStatus}
          mapView={this.props.mapView}
          onTheMap={this.props.onTheMap}
          switchMapView={this.props.atSwitchMapView}
          changeOnTheMapStatus={this.props.atChangeOnTheMapStatus}
          changeMarkersOnMap={this.props.atChangeMarkersOnMap}
          changeAirportStatusFilter={this.props.changeAirportStatusFilter}
          changeAirportTimeframeFilter={this.props.changeAirportTimeframeFilter}
          changeFlightStatusTimeframeFilter={
            this.props.changeFlightStatusTimeframeFilter
          }
          updateTravelersShown={this.updateTravelersShown}
          panMapTo={this.panMapTo}
        />
        <div
          id="comparison-container"
          className='compare-container'
        >
          <div
            id="atMap"
            ref={this._mapcontainer}
            className="map"
            style={{ zIndex: 1 }}
          />
          <div
            id="atMap1"
            ref={this._mapcontainer1}
            className="map"
            style={{
              zIndex: 0
            }}
          />
        </div>
        {this.state.legend ? (
          <div
            id="county-legend"
            className={clslegend}>
            <h4 className='legend-heading'>Legend</h4>
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD8AAAA/CAYAAABXXxDfAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAuIwAALiMBeKU/dgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAATKSURBVGiB7ZtNbBtFGIbfb9Y2LghEWiApPwWp7sZhc4uobZRCLxxIW1BSpeodxAEkjqhpRS+hKiCuRQIuPRNAoJLSA4eIJnESAUJq3diJ01gl0BbapFS0ib0/H4cklUk8G8fdHzvOe/N83+y8j3e8MzueIdiotTXWWCjQASLeB1AzgEYAW+3q+KxZANcBzjBTfyjEZy5eHL0uS6ZShZqW2GoY/B6AdwFsccmoFyow02nTpPenpob/WhlcBb9rVzwmBL4F0OSJPU9EVy2LOycnR0aLS0XxB1VNdAqBAWwocADg7UJgQFUTncWl9+58NBprY6afADzouTfvtGBZ2LvcAwi49xtPYcPd8ZL6MxQKtF64MDgnAEDX+SjqAxwAniwUjCMAQK2tsUZdpxyAsDyfk0R0UlFoKJVKznrjcf1a6sHtzOghQtwmdcEwxLOkqvE3ifCFPI8/y2R2vAP0mY67dU3diqrOfErEb8lz6A0hBDrkCZysPXAA6DMnJp5+mxkj8hzuEMyIysJEdLL2wJfVZxLxh7IoEVoEbB50ikJDrvjySEQ8KIsxo0kAeFSWkEol51xx5ZHS6TG7h3ODgGR+vyR22I/XsvNPwia44bUJX6/ahK9XbcLXqzbh61Wb8PWqgN8GACASefUBRbl1jMh6mZnOm2ZDbzb7Q97tdn2HXwSf+wZABzMBwEuKcssCcNzttn3t9sXg/4/wHi/a9w1eDg4wY8ALDxXDa1oi0tLStr2SunbgAM5aVoN0+clJVQIvVDX+tWHwpGUFZ1Q1dmQ9ldcCN82GLi8edkAF8NFo4hUidC3XJ6IPNC1R1t/W1QQOVABvWbxjRZGi6+Yza9WrNnCggqFOCKalIalsaZoWMoy5PpQGP5fPhw/mct6CAx487RfBH/4KwIES4XP5fLgzlxtYcNtHKbkKX83ggIvw1Q4OuARfC+CAC/C1Ag44DF9L4ICD8LUGDjgGH6g5cMCh93khrNPMeL5E6KxpNnT5MYEpR47AS8B9m7mVK7fG+art6sVyYxkrTUS94fC8Fo3GbBMVRUz7ubvLDfgoMw/Z73lYlGGwpaqxYxMTo54sXqyU30vXgohOaNoLvmyArOB9Hred9pDPBx9y+JrlNbzeCsHglu8BjDvo4ctsdnjKweuVrQAWN+3IfqCEFZt6UqmBfzVt725dn98vBB65n8aJMD0+Pvrj/VxjrSZsYkzNzfGbkBwdCQRoWzXvtV1Lqtr2GFHwb0l4VgCQnkExDG53x5ZXCkn9E+GaAHBJlsCMHqBbccWX6+pWhGDpsjozXRIA98sSiBBvbr5yqva+gMVd18yQzrKIuJ8ikT2PK4qeg+3xEk4C+IiIB9PpsZuOe3VI0ejubczUTkQ9duAA7jDrzxEAqGr8BBGOeuSxCkS9mUzyuAAAw9A/BvC7z4680hXTtD4BliY5ly//8o9lidcA3PHVlvuaB6yD2ezobaBohjc5OfwbER0GcNc3a+7qLhEdymTGfl4uWDUDWjpf9x2Apzy15q5mhMDr4+MjvxYXrhrCbtz442pTU+RzZkMHEAMQ9MqhCyoAOJXPhw9ls4PTK4O2L907d774RCDA+5l5nxCIMqMJVX6KmgjXLAtpIuo3zcCZbPa8bHqL/wC6ovzj0Jr8owAAAABJRU5ErkJggg=="
              className='legend-head-img'
              onClick={() => {
                console.log("CLICKED");
                this.setState({ legend: false }, () => {
                  console.log("SET STATE", this.state.legend);
                });
                // this.clearActiveTravelerMap("flightarcs");
              }}
            />
            <FilterCOmponent
              airportStatuses={this.props.availableAirportStatuses}
              airportTimeframes={this.props.availableAirportTimeframes}
              flightStatusTimeframes={
                this.props.availableFlightStatusTimeframes
              }
              filterAirportTimeframe={this.props.filterAirportTimeframe}
              filterFlightStatusTimeframe={
                this.props.filterFlightStatusTimeframe
              }
              filterAirportStatus={this.props.filterAirportStatus}
              mapView={this.props.mapView}
              onTheMap={this.props.onTheMap}
              switchMapView={this.props.atSwitchMapView}
              changeOnTheMapStatus={this.props.atChangeOnTheMapStatus}
              changeMarkersOnMap={this.props.atChangeMarkersOnMap}
              changeAirportStatusFilter={this.props.changeAirportStatusFilter}
              changeAirportTimeframeFilter={
                this.props.changeAirportTimeframeFilter
              }
              changeFlightStatusTimeframeFilter={
                this.props.changeFlightStatusTimeframeFilter
              }
              updateTravelersShown={this.updateTravelersShown}
              panMapTo={this.panMapTo}
            />
            {this.props.mapView === "locations" && (
              <div style={{paddingTop:'6px'}}>
                {this.state.locations.map(location => {
                  return <LegendRow from="locations" data={location} key={location.id}/>;
                })}
              </div>
            )}
            {this.props.mapView === "airportStatus" && (
              <div>
                <h4 className='legend-subheading'>Status</h4>
                {statusesAirport.slice(0).reverse().map((airport, i) => {
                  return (
                    <div className='legend-row' key={i}>
                      {/*<input*/}
                        {/*name="filterselector"*/}
                        {/*onChange={e => {*/}
                          {/*if (!e.target.checked) {*/}
                            {/*let newFilterStatus = this.props.filterAirportStatus.filter( (airportStatus) => airportStatus != airport.value);*/}
                            {/*this.props.changeAirportStatusFilter(newFilterStatus);*/}
                          {/*} else {*/}
                            {/*let newFilterStatus = [...this.props.filterAirportStatus, airport.value];*/}
                            {/*this.props.changeAirportStatusFilter(newFilterStatus);*/}
                          {/*}*/}
                        {/*}}*/}
                        {/*type="checkbox"*/}
                        {/*checked={this.props.filterAirportStatus.includes(airport.value)}*/}
                      {/*/>*/}
                      <span style={{backgroundColor: airport.color}}></span>
                      {airport.label}
                    </div>
                  );
                })}
              </div>
            )}
            {this.props.mapView === "flightsStatus" && this.state.status==true &&(
              <div>
                <h4 className='legend-subheading'>Status</h4>
                {this.state.flightArcs.map(flightArc => {
                  return <LegendRow from="flightsStatus" data={flightArc} key={flightArc.id}/>;
                })}
              </div>
            )}
          </div>
        ) : (
          <div

            style={{
              backgroundColor: "#fff",
              zIndex: 1,
              position: "absolute",
              left: 20,
              bottom: 20,
              padding: 10,
              height: 40,
              width: 233,
              borderRadius: 4
            }}
          >
            <h4 className='legend-heading'>Legend</h4>
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD8AAAA/CAYAAABXXxDfAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAuIwAALiMBeKU/dgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAATKSURBVGiB7ZtNbBtFGIbfb9Y2LghEWiApPwWp7sZhc4uobZRCLxxIW1BSpeodxAEkjqhpRS+hKiCuRQIuPRNAoJLSA4eIJnESAUJq3diJ01gl0BbapFS0ib0/H4cklUk8G8fdHzvOe/N83+y8j3e8MzueIdiotTXWWCjQASLeB1AzgEYAW+3q+KxZANcBzjBTfyjEZy5eHL0uS6ZShZqW2GoY/B6AdwFsccmoFyow02nTpPenpob/WhlcBb9rVzwmBL4F0OSJPU9EVy2LOycnR0aLS0XxB1VNdAqBAWwocADg7UJgQFUTncWl9+58NBprY6afADzouTfvtGBZ2LvcAwi49xtPYcPd8ZL6MxQKtF64MDgnAEDX+SjqAxwAniwUjCMAQK2tsUZdpxyAsDyfk0R0UlFoKJVKznrjcf1a6sHtzOghQtwmdcEwxLOkqvE3ifCFPI8/y2R2vAP0mY67dU3diqrOfErEb8lz6A0hBDrkCZysPXAA6DMnJp5+mxkj8hzuEMyIysJEdLL2wJfVZxLxh7IoEVoEbB50ikJDrvjySEQ8KIsxo0kAeFSWkEol51xx5ZHS6TG7h3ODgGR+vyR22I/XsvNPwia44bUJX6/ahK9XbcLXqzbh61Wb8PWqgN8GACASefUBRbl1jMh6mZnOm2ZDbzb7Q97tdn2HXwSf+wZABzMBwEuKcssCcNzttn3t9sXg/4/wHi/a9w1eDg4wY8ALDxXDa1oi0tLStr2SunbgAM5aVoN0+clJVQIvVDX+tWHwpGUFZ1Q1dmQ9ldcCN82GLi8edkAF8NFo4hUidC3XJ6IPNC1R1t/W1QQOVABvWbxjRZGi6+Yza9WrNnCggqFOCKalIalsaZoWMoy5PpQGP5fPhw/mct6CAx487RfBH/4KwIES4XP5fLgzlxtYcNtHKbkKX83ggIvw1Q4OuARfC+CAC/C1Ag44DF9L4ICD8LUGDjgGH6g5cMCh93khrNPMeL5E6KxpNnT5MYEpR47AS8B9m7mVK7fG+art6sVyYxkrTUS94fC8Fo3GbBMVRUz7ubvLDfgoMw/Z73lYlGGwpaqxYxMTo54sXqyU30vXgohOaNoLvmyArOB9Hred9pDPBx9y+JrlNbzeCsHglu8BjDvo4ctsdnjKweuVrQAWN+3IfqCEFZt6UqmBfzVt725dn98vBB65n8aJMD0+Pvrj/VxjrSZsYkzNzfGbkBwdCQRoWzXvtV1Lqtr2GFHwb0l4VgCQnkExDG53x5ZXCkn9E+GaAHBJlsCMHqBbccWX6+pWhGDpsjozXRIA98sSiBBvbr5yqva+gMVd18yQzrKIuJ8ikT2PK4qeg+3xEk4C+IiIB9PpsZuOe3VI0ejubczUTkQ9duAA7jDrzxEAqGr8BBGOeuSxCkS9mUzyuAAAw9A/BvC7z4680hXTtD4BliY5ly//8o9lidcA3PHVlvuaB6yD2ezobaBohjc5OfwbER0GcNc3a+7qLhEdymTGfl4uWDUDWjpf9x2Apzy15q5mhMDr4+MjvxYXrhrCbtz442pTU+RzZkMHEAMQ9MqhCyoAOJXPhw9ls4PTK4O2L907d774RCDA+5l5nxCIMqMJVX6KmgjXLAtpIuo3zcCZbPa8bHqL/wC6ovzj0Jr8owAAAABJRU5ErkJggg=="
              className='legend-head-img2'
              onClick={() => {
                console.log("CLICKED");
                this.setState({ legend: true }, () => {
                  console.log("SET STATE", this.state.legend);
                });
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AtMapContainer);
