import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { stateUser } from '../../redux/modules/user';

const mapStateToProps = createSelector(
  stateUser,
  (user) => {
    return {
      user
    };
  }
);

class WorldHub extends React.Component {

  render () {
    return (
    <div style={{ display: 'flex' }}>
    <div style={{ width: '100%', height: '100vh' }} >
    <iframe src={this.props.user.worldHubUrl?this.props.user.worldHubUrl:'https://worldhub.wti.global/worldtravel/login'} width='100%' height='100%' />
    </div>
    </div>
    );
  }
}

export default connect(mapStateToProps)(WorldHub);
