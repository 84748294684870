import filter from 'lodash/filter';
import { computeSpotBelonging } from './getSpotBelonging';

export default (travelers, filterObj) => {

  if (travelers.length && filterObj.continents.length && filterObj.usersByContinent) {
    let continentTravelerIds = [];
    // Since the usersByContinent is triggered after travelers are set
    // there is a chance that usersByContinent is not available in filter yet
    // In such cases dont filter
    let shouldFilter = true;
    filterObj.continents.forEach( (continent) => {
      let usersByContinent = filterObj.usersByContinent[continent];
      if (usersByContinent) {
        continentTravelerIds.push(...usersByContinent);
      } else {
        shouldFilter = false;
      }

    });
    if (shouldFilter) travelers = travelers.filter( (traveler) => continentTravelerIds.includes(traveler._id.$oid));
    // console.log('continentTravelerIds.length', continentTravelerIds.length);
  }

  if (
    filterObj.status.ok ||
    filterObj.status.alarm ||
    filterObj.status.warning
  ) {
    travelers = filter(travelers, (traveler) => {
      const status = traveler.currentJourneyStatus;
      if (
        (status === 'ok' && filterObj.status.ok) ||
        (status === 'alarm' && filterObj.status.alarm) ||
        (status === 'warning' && filterObj.status.warning)
      ) {
        return true;
      }
      return false;
    });
  }
  if (filterObj.vip) {
    travelers = filter(travelers, { isVIP: true });
  }
  if (filterObj.nonHelped) {
    travelers = filter(travelers, (traveler) => {
      if (traveler.helpedBy && traveler.helpedBy.status !== 'opened' && traveler.helpedBy.status !== 'closed') {
        return true;
      }
      return false;
    });
  }

  if (filterObj.company && filterObj.company.length) {
    travelers = travelers.filter( (traveler) => filterObj.company.includes(traveler.organization._id.$oid));
  }

  if (filterObj.costcenter_id && filterObj.costcenter_id.length ) {
    travelers = filter(travelers, (traveler) => {
      if (traveler.costCenter && traveler.costCenter._id) {
        return filterObj.costcenter_id.includes(traveler.costCenter._id.$oid);
      }
      return false;
    });
  }

  if (filterObj.areaShown) {
    travelers = filter(
      travelers,
      traveler => computeSpotBelonging(traveler) === filterObj.areaShown
    );
  }
  
  return travelers;
};
