import PropTypes from 'prop-types';
import React from 'react';
import filter from 'lodash/filter';
import RedIcon from '../../styles/images/Status-Red.svg';
import GreenIcon from '../../styles/images/Status-Green.svg';
import YellowIcon from '../../styles/images/Status-Yellow.svg';
export class ActiveTravelersSummary extends React.Component {
  static contextTypes = {
    i18n: PropTypes.object
  };

  static propTypes = {
    activeTravelers: PropTypes.array.isRequired
  };

  render () {
    const { l } = this.context.i18n;
    const { activeTravelers } = this.props;
    const nonBlockedActiveTravelers = filter(activeTravelers, { status: 'active' });
    let alarm = 0;
    let warning = 0;
    let ok = 0;

    nonBlockedActiveTravelers.forEach((traveler) => {
      switch (traveler.currentJourneyStatus) {
        case 'alarm':
          alarm++;
          break;
        case 'warning':
          warning++;
          break;
        case 'ok':
          ok++;
          break;
      }
    });
    return (
      <ul className='status-list'>
        <li><strong>{l('Total:')} {nonBlockedActiveTravelers.length}</strong>{'   '}</li>
        <li><img className='status-list-icon' src={RedIcon} alt='status'/>{alarm}{'   '}</li>
        <li><img className='status-list-icon' src={YellowIcon} alt='status'/>{warning}{'   '}</li>
        <li><img className='status-list-icon' src={GreenIcon} alt='status'/>{ok}{'   '}</li>
      </ul>
    );
  }
}
export default ActiveTravelersSummary;
