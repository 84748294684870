import PropTypes from 'prop-types';
import React from 'react';
import { getFightStatus } from '../../../helpers/timeline';

import { CollapseHolder, CollapseOpener, CollapseBlock } from '../../../components/common/collapse';
import FlightDetails from './flightDetails';
import CarDetails from './carDetails';
import HotelDetails from './hotelDetails';
import TrainDetails from './trainDetails';
import Alarm from '../../../styles/images/Status-Red.svg';
import Warning from '../../../styles/images/Status-Yellow.svg';
import Ok from '../../../styles/images/Status-Green.svg';
import Landing from '../../../styles/images/Landing.svg';
import Takeoff from '../../../styles/images/Takeoff.svg';
export class TimelineEvent extends React.Component {
  static contextTypes = {
    i18n: PropTypes.object
  };

  static propTypes = {
    time: PropTypes.string.isRequired,
    day: PropTypes.string,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    eventInfo: PropTypes.object.isRequired,
    timelineEventType: PropTypes.string,
    reservation: PropTypes.object.isRequired,
    reservationId: PropTypes.string.isRequired,
    showReservationModal: PropTypes.func.isRequired
  };

  constructor (props) {
    super(props);

    this.state = {
      detailsOpenedClass: ''
    };
  }

  get details () {
    const { type, eventInfo, reservation, reservationId, showReservationModal } = this.props;

    switch (type.toLowerCase()) {
      case 'flight':
        return (
          <FlightDetails flightInfo={eventInfo} showReservationModal={showReservationModal}
            reservation={reservation} reservationId={reservationId} />
        );
      case 'hotel check-in':
        return (
          <HotelDetails hotelInfo={eventInfo} showReservationModal={showReservationModal}
            reservation={reservation} reservationId={reservationId} type='check-in' />
        );
      case 'hotel check-out':
        return (
          <HotelDetails hotelInfo={eventInfo} showReservationModal={showReservationModal}
            reservation={reservation} reservationId={reservationId} type='check-out' />
        );
      case 'car pick-up':
        return (
          <CarDetails carInfo={eventInfo} showReservationModal={showReservationModal}
            reservation={reservation} reservationId={reservationId} type='pick-up' />
        );
      case 'car drop-off':
        return (
          <CarDetails carInfo={eventInfo} showReservationModal={showReservationModal}
            reservation={reservation} reservationId={reservationId} type='drop-off' />
        );
      case 'rail':
        return (
          <TrainDetails trainInfo={eventInfo} showReservationModal={showReservationModal}
            reservation={reservation} reservationId={reservationId} />
        );
    }
  }

  getFlightStatus () {
    const { type, eventInfo } = this.props;

    if (type.toLowerCase() !== 'flight') {
      return {};
    }
    const flightStatusObj = getFightStatus(eventInfo.latestEvent, eventInfo.startsAt.$date, eventInfo.endsAt.$date);
    let dirrection = null;
    if (flightStatusObj.worstDirection) {
      dirrection = flightStatusObj.worstDirection === 'departure' ? (
        <span><img style={{paddingRight:'10px'}} src={Takeoff} /></span>
      ) : (
        <span><img style={{paddingRight:'10px'}} src={Landing} /></span>
      );
    }
    let statusIco = null;
    if(flightStatusObj.travelerStatus==='alarm'){
      statusIco = <img className='statusIco' src={Alarm} alt='icon'/>
    }else 
    if(flightStatusObj.travelerStatus === 'warning'){
      statusIco = <img className='statusIco' src={Warning} alt='icon'/>
    }else{
      statusIco = <img className='statusIco' src={Ok} alt='icon'/>
    }
    const flightMessage = (
      <span className='flight-message'>
        {dirrection}
        <div className={`${flightStatusObj.travelerStatus}-color`}>
          {/* <span className={`icon ${flightStatusObj.travelerStatus}`} /> */}
          {statusIco}
          <span>{flightStatusObj.statusMessage}</span></div>
      </span>
    );
    const flightClass = `${flightStatusObj.flightStatus} ${flightStatusObj.travelerStatus}-bgcolor`;

    return {
      flightClass,
      flightMessage
    };
  }

  toggleTimelineDetails = (isOpened) => () => {
    this.setState({
      detailsOpenedClass: isOpened ? 'timeline-expanded' : ''
    });
  }

  render () {
    // console.log(this.props)
    //         // Set table rows bacgrounds
    // let rowClass = '';
    // if (this.props.eventInfo.flightStatus === 'canceled' || this.props.eventInfo.flightStatus === 'diverted' ||
    //   (flightDelay && flightDelay > alarmDelayStart)) {
    //   rowClass = 'alarm-bg';
    // } else if (flightDelay && flightDelay >= warningDelayStart && flightDelay <= alarmDelayStart) {
    //   rowClass = 'warning-bg';
    // } else if (this.props.eventInfo.flightStatus === 'landed' || (flightDelay && flightDelay < warningDelayStart)) {
    //   rowClass = 'normal-bg';
    // }
    const { l } = this.context.i18n;
    const { time, day, type, name, timelineEventType, eventInfo: { journeyName } } = this.props;
    const dayEl = day ? (<strong>{day}</strong>) : null;
    const eventType = (type.toLowerCase() === 'rail') ? l('Train') : type;
    const index = eventType.indexOf(' ');
    const eventIconClass = index !== -1 ? eventType.slice(0, index).toLowerCase() : eventType.toLowerCase();

    const flightStatus = this.getFlightStatus();
    const flightStatusClass = flightStatus.flightClass ? flightStatus.flightClass : '';
    const timelineExpandedClass = this.state.detailsOpenedClass;
    const flightStatusMessage = flightStatus.flightMessage ? flightStatus.flightMessage : null;
    const eventName = name ? (<h3>{name}</h3>) : null;
    return (
      <div className='timline-item-holder'>
        <div className='timeline-date'><div className='date-holder'>{dayEl}</div> <span className='date-align'>{time}</span></div>
        <div className={`timline-item ${timelineEventType} ${flightStatusClass} ${timelineExpandedClass}`}>
          <CollapseHolder
            saveState={journeyName + name + type}
            saveBySession
            onCollapseOpen={this.toggleTimelineDetails(true)}
            onCollapseClose={this.toggleTimelineDetails(false)}>
            <CollapseOpener>
              <div className='event-short-info'>
                <span className='dots' />
                <div className='text-holder'>
                  <span className={`${eventIconClass} type-icon`}>{eventType}</span>
                  <span className='type-text'>{eventType}</span>
                  {eventName}
                </div>
                <div className='opener-message'>{flightStatusMessage}</div>
              </div>
            </CollapseOpener>
            <CollapseBlock>
              <div className='tl-content'>
                {this.details}
              </div>
            </CollapseBlock>
          </CollapseHolder>
        </div>
      </div>
    );
  }
}
export default TimelineEvent;
