import React from 'react';
import { Route, Redirect, withRouter,Switch } from 'react-router-dom';
import LoginView from '../../views/LoginView/LoginView';
import ForgotPasswordView from '../../views/ForgotPasswordView/ForgotPasswordView';
// import Terms from '../../terms';
import SetNewPasswordView from '../../../src/views/SetNewPasswordView/SetNewPasswordView';

export default withRouter(class GuestLayout extends React.Component {
  render () {
    return (
      <div>
          {/*<Route exact path="/" component={() => <Redirect to="/login" />}/>*/}
          <Switch>
          <Route exact path='/login' component={LoginView} />
          <Route exact path='/forgot-password' component={ForgotPasswordView} />
         <Route exact path='/set-new-password/:code' component={SetNewPasswordView} />
          {/* <Route path='/terms' component={Terms} /> */}
        {/*<Route exact path="/" render={() => (<Redirect to="/login" />)} />*/}
         <Redirect to="/login"/>
        {/*<Route path="*" component={LoginView} />*/}
          </Switch>
      </div>
    );
  }
});
