import PropTypes from 'prop-types';
import React from 'react';
import Validator from '../../helpers/validator';
import PasswordCriteria from '../../components/common/passwordCriteria';
import Gray from '../../styles/images/Grey-Arrow.svg';
import Blue from '../../styles/images/Blue-Arrow.svg';
import { withRouter } from "react-router-dom";
export class SetNewPasswordForm extends React.Component {
  static contextTypes = {
    i18n: PropTypes.object
  };

  static propTypes = {
    match: PropTypes.any,
    user: PropTypes.any.isRequired,
    saveNewPassword: PropTypes.func.isRequired,
    toggleError: PropTypes.func.isRequired
  };

  constructor (props) {
    super(props);
    // this._newPasswordFieldRef = React.createRef();
    this._passwordCriteriaRef = React.createRef();
    // this._confirmPasswordFieldRef = React.createRef();
    this.state = {
      newPasswordErrors: false,
      confirmPasswordErrors: false,
      newPassword: '',
      confirmPassword: '',
      toggleText: false,
      showError: false,
      errorMsg: '',
      toggleButton: false,
      logInButton:false,
    };
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.setState({ showError: true, errorMsg: nextProps.user.errorMessage, logInButton:nextProps.user.passwordChanged });
    setTimeout(() => {
      if (nextProps.user.errorFlag) {
        console.log(nextProps.user.errorFlag);
        this.props.toggleError();
        console.log(nextProps.user);
        this.setState({ showError: false, errorMsg: '', toggleButton: true });
      }
    }, 5000);
  }

  handleMatch = (e) => {
    this.setState({ confirmPassword: e.target.value });
    if (e.target.value === this.state.newPassword) {
      this.setState({ toggleText: true });
    }
  }

    handleError = () => {
      this.props.user.errorFlag && this.props.toggleError();
      this.setState({ showError: false });
    }

  handleSubmit = (e) => {
    e.preventDefault();

    if(this.state.logInButton){
      this.props.history.push('/login');
      return

    }


    if (this.validateForm() && this.props.user.identityId) {
      this.props.saveNewPassword(
        this.context.i18n.l,
        this.state.newPassword,
        this.props.match.params.code
      );
    }
    if(this.props.user.passwordChanged === true){
          this.setState({
            logInButton:true
          })
    }

  }

  addError (state, errorText) {
    this.setState({
      [state]: (
        <span className='error'>
          {this.context.i18n.l(errorText)}
        </span>
      )
    });
  }

  validateForm () {
    let valid = true;
    if (!Validator.isEmpty(this.state.newPassword)) {
      valid = false;
      this.addError('newPasswordErrors', 'Password is required');
    } else if (this._passwordCriteriaRef.current.isValid()) {
      valid = false;
      this.addError('newPasswordErrors', 'Password is not strong enough');
    } else this.setState({ newPasswordErrors: false });

    if (!Validator.isEmpty(this.state.confirmPassword)) {
      valid = false;
      this.addError('confirmPasswordErrors', 'Confirm is required');
    } else this.setState({ confirmPasswordField: false });

    if (this.state.confirmPassword !== this.state.newPassword) {
      valid = false;
      this.addError('confirmPasswordErrors', 'Confirm password doesn\'t match');
    }

    return valid;
  }

  // pushLogin =(e) => {
  //   console.log('Triggering');
  //   e.preventDefault();
  // }

  render () {
    const { l } = this.context.i18n;
    const { user } = this.props;
    let form = null;
    // const text = this._passwordCriteriaRef.current.value;
    // const confirmText = this._confirmPasswordFieldRef.current.value;
    let arrow = null;
    if(this.state.toggleText){
      arrow = <img src={Blue} alt='' className='arrow-fix'/>
    }else{
      arrow = <img src={Gray} alt='' className='arrow-fix'/>
    }

    form = (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-4 col-md-6 col-xs-12 logoh ">
          <div className="logo new"></div>
        <form className='login-form sec' onSubmit={this.handleSubmit}>
            <p class='password-title'>Set new password</p>
            <fieldset>
            <div className='field new'>
              {this.state.newPasswordErrors}
              <PasswordCriteria ref={this._passwordCriteriaRef}>
              <input type='password'
                     id='newPassword'
                     name='newPassword'
                     value={this.state.newPassword}
                     onChange={(e) => this.setState({ newPassword: e.target.value })}
                     maxLength='30'
                     autoComplete='off'
                     placeholder='Password' />
                </PasswordCriteria>
            </div>
            <div className='field'>
              {this.state.confirmPasswordErrors}
              <input type='password'
                     id='confirmPassword'
                     name='confirmPassword'
                     maxLength='30'
                     value= {this.state.confirmPassword}
                     onChange={this.handleMatch}
                     autoComplete='off'
                     placeholder='Confirm Password' />
            </div>
            <button type='submit' className={`btn btn01 new ${!this.state.toggleText?'set-disable':'set-submit'}`} disabled={!this.state.toggleText}
            style={{ boxShadow: !this.state.toggleText ? 'none' : '' }}>
            {this.state.logInButton ? l('Go to Login') : l('Submit')}
            {arrow}
            </button>
          </fieldset>
          {/* <div className='logo-head'></div> */}
        </form>
        <div className="login-err">
            {this.state.showError && (this.state.errorMsg !== undefined)
                ? (<div className='btn-error bx' style={{ background: '#F25151' }}>
              <span className="loginErrText tx" style={{textAlign:'left'}}>Error:{user.errorMessage.split(':')[1]}</span>
              <span className="fa_cust fx" onClick={this.handleError}>
                <i className="fas fa-times"></i>
              </span>
            {/* <i className="fa fa-arrow-circle-right fa_custom"></i> */}
            </div>) : null}
          </div>
        </div>
       <div className="col-lg-8 col-md-6 col-xs-12 backimage">
<h1>Welcome!</h1>
</div>
       </div>
        </div>
    );
    return form;
  }
}
export default withRouter(SetNewPasswordForm);
