import React, { Component } from 'react';
import AppSettings from '../../containers/Common/AppSettings';
import PusherUpdates from '../../containers/Common/PusherUpdates';
import { Sidebar } from './components';

export default class DashboardLayout extends Component {
  constructor (props) {
    super(props);
  }

  render () {
    console.log('Inside render of DashboardLayout');

    return (
      <div className="wrapper">
        <AppSettings />
        <PusherUpdates />
        <Sidebar>
        {this.props.children}
        </Sidebar>
      </div>
    );

  }

}





