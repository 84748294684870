import PropTypes from "prop-types";
import React from "react";
import TaMap from "../../containers/TravelAdvisory/TaMap";
import CountryFilter from "./countryFilter";
import CountryList from "./countryList";
// import TravelAdvisoryInfo from './travelAdvisoryInfo';
// import TravelAdvisoryWhererTravelInfo from './travelAdvisoryWhereTravelInfo';
import TravelAdvisoryPopup from "./travelAdvisoryPopup";
import LoadingIcon from "../../components/common/loadingIcon";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom"
import config from "../../config/index";
import { withRouter } from 'react-router-dom';
import UserLayoutComponent from '../../../src/components/activeTravelers/TravelAdvisorUserDetailsLayout';
import TravelAdvisoryRoute from './travelAdvisoryRoute';
import isEqual from 'lodash/isEqual';
const headerHeight = config.layout.headerHeight;

export class TravelAdvisory extends React.Component {
  static propTypes = {
    appSettingsDimensions: PropTypes.object.isRequired,
    activeCountry: PropTypes.object,
    countryTravelers: PropTypes.object.isRequired,
    countries: PropTypes.array.isRequired,
    filteredCountries: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    filterCountries: PropTypes.func.isRequired,
    setActiveCountry: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.state = {
      open: false,
      filterString: null,
    };
  }

  // TODO optimize if filtered array is not changing
  filterHandler = event => {
    let value = event ? event.target.value : "";

    const filtered = this.props.countries.filter(country => {
      return country.country.toLowerCase().indexOf( value.toLowerCase() ) !== -1;
    });

    this.setState( (state, props) => {
      this.props.filterCountries(filtered);
      return {filterString: value}
    });

  };

  showCountry = value => {
    if (typeof value === "string") {
      const activeCountry = this.props.countries.find(
        country => country.longCode === value
      );
      this.props.setActiveCountry(activeCountry);
      this.props.history.push("/travel-advisories/"+activeCountry.longCode)
    } else {
      this.props.setActiveCountry(value);
      this.props.history.push("/travel-advisories/"+value)
    }

  };

  // shouldComponentUpdate (nextProps) {
  //
  //   if(nextProps.match.params.country){
  //
  //     if(nextProps.match.params.country !== this.props.activeCountry.longCode){
  //       if(this.props.countries.length){
  //         const activeCountry = this.props.countries.find(
  //           country => country.longCode === nextProps.match.params.country
  //         );
  //         this.props.setActiveCountry(activeCountry);
  //       }
  //
  //     }
  //
  //   }
  //   else{
  //     console.log("No country")
  //   }
  //   return true;
  //
  //
  // }


  componentDidUpdate(prevProps) {

    if(this.props.match.params.country){

      if(this.props.match.params.country !== prevProps.activeCountry.longCode){
        if(prevProps.countries.length){
          const activeCountry = prevProps.countries.find(
            country => country.longCode === this.props.match.params.country
          );
          this.props.setActiveCountry(activeCountry);
        }

      }

    }
    return true
  }


  hideCountry = () => {
    this.props.setActiveCountry(null);
  };

  // componentWillUnmount(){
  //   this.hideCountry();
  // }

  render() {
    // return null in case if we don't have window height
    if (!this.props.appSettingsDimensions.height) return null;
    const childProps = {
      ...this.props
    };
    // set height style for components
    // Commented to remove the dynamic height
    const containerStyle = {
      // height: this.props.appSettingsDimensions.height - headerHeight
      backgroundColor:"white",
      height:'100vh'
    };
    let loadTA = null;

    let userLayout = false;
    console.log(this.props.match);
    if (this.props.match.path.includes("/travelerad/:id")) {
      userLayout = true;
    }

    console.log(this.props.activeCountry);
    if (userLayout){
      loadTA =   (
        <>          <Route exact path='/travel-advisories/:country' render={(props) => <TravelAdvisoryPopup  country={this.props.activeCountry} onClose={this.props.history.push('/travel-advisories')}  />} />

          {/*<UserLayoutComponent uid={this.props.match.params.id} />*/}

        </>)
    }else{
      loadTA = (<>
        <Switch>
        <Route exact path='/travel-advisories/:country' render={(props) => <TravelAdvisoryPopup  country={this.props.activeCountry} onClose={this.props.history.push('/travel-advisories')}  />} />
        <Route  path='/travel-advisories/' render={(props) => <TaMap onCountrySelect={this.showCountry} />} />
        </Switch>

      </>);
    }

    return (
      <div className="main-holder travel-advisory-holder" style={{backgroundColor:"white"}}>
        <LoadingIcon loading={this.props.loading} />
        <div style={containerStyle} className="travel-advisory-col">
          {/* <button onClick={() => this.setState({open: true})} >hello</button> */}
          <div className="travel-advisory-col-cnt">
            {/* <p>T</p> */}
            <p className="advilink">
              <strong className="advi">Travel Advisories</strong> <br />
              Information provided by the{" "}
              <a href="https://travel.state.gov/content/travel.html">
                United States State Department.
              </a>
            </p>
            {/* <TravelAdvisoryInfo /> */}
            {/* <TravelAdvisoryWhererTravelInfo /> */}
            <CountryFilter changeFilter={this.filterHandler} />
            <CountryList
              showCountry={this.showCountry}
              countries={this.state.filterString ? this.props.filteredCountries: this.props.countries}
              countriesLoading={this.props.loading}
              filterString={this.state.filterString}
              setActiveCountry={this.props.setActiveCountry}
            />
          </div>
        </div>
        <div style={containerStyle} className="map-col" >

          <TravelAdvisoryRoute {...this.props} showCountry={this.showCountry} />



        </div>
      </div>
    );
  }
}
export default withRouter(TravelAdvisory);
