import PropTypes from 'prop-types';
import React from 'react';
import { timeInCountry } from '../../helpers/traveler';
import { Link } from 'react-router-dom';

function TravelsBox (props) {
  const { country: { travelers } } = props;
  if (!travelers) return null;
  const travelersIds = Object.keys(travelers);
  let isCompanyUser=false;
  console.log(props.user);
  if(["CU"].indexOf(props.user.roleName) !== -1){
    isCompanyUser=true;
  }

  return (
    <div className='user-list'>
      {
        travelersIds.map(id => (
          <div className='row' key={id}>
            <div className='info'>
              <p className='travelerName'>
                <Link to={`/travelerad/${travelers[id]._id.$oid}`}>
                  {travelers[id].firstName} {travelers[id].lastName}
                </Link>{' '}
                {travelers[id].isVIP && <em>VIP</em>}
              </p>
              <p className='travelerorg'>{travelers[id].costCenter && isCompanyUser?travelers[id].costCenter.name:travelers[id].organization.name}</p>
            </div>
            <div className='dates'>
              <p>In country {timeInCountry(props.country.shortCode, travelers[id].products)}</p>
            </div>
          </div>
        ))
      }
    </div>
  );
}

TravelsBox.propTypes = {
  country: PropTypes.object
};

export default TravelsBox;
