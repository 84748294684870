import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import isEqual from 'lodash/isEqual';
import { getPastEvents, separateEvents } from '../../helpers/timeline';
// import { getPastEvents, separateEvents, _dumpEvent } from '../../helpers/timeline';

import LoadingIcon from '../../components/common/loadingIcon';
import TimelineEventsList from '../../components/traveler/timeline/timelineEventsList';

import {
  stateTimelineData,
  stateLoading,
  actions as timelineActions
} from '../../redux/modules/timeline';

const mapStateToProps = createSelector(
  stateTimelineData,
  stateLoading,
  (timelineData, loading) => {
    const { timelineEvents, reservations, travelerId } = timelineData;
    return {
      timelineEvents,
      travelerId,
      reservations,
      loading
    };
  }
);

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(timelineActions, dispatch)
  };
};

export class TimelineContainer extends React.Component {
  static contextTypes = {
    i18n: PropTypes.object
  };

  static propTypes = {
    match: PropTypes.object,
    travelerId: PropTypes.string.isRequired,
    timelineEvents: PropTypes.array.isRequired,
    reservations: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    loadEvents: PropTypes.func.isRequired
  };

  shouldComponentUpdate (nextProps, nextState) {
    return (
      !isEqual(nextState, this.state) ||
      !isEqual(nextProps.timelineEvents, this.props.timelineEvents) ||
      !isEqual(nextProps.match.params.id, this.props.match.params.id) ||
      !isEqual(nextProps.travelerId, this.props.travelerId) ||
      !isEqual(nextProps.reservations, this.props.reservations) ||
      !isEqual(nextProps.loading, this.props.loading)
    );
  }

  UNSAFE_componentWillMount () {
    const { match: { params: { id } } } = this.props;
    if (id && !this.props.loading) {
      this.props.loadEvents(id);
    }
  }

  setCurrentPosition (cb = () => {}) {
    const events = separateEvents(this.props.timelineEvents);
    // console.log('---------------------');
    // [].concat(events.pastEvents, events.upcomingEvents).map(function (event) { _dumpEvent(event); });
    const { lastDayEvents, pastEvents } = getPastEvents(events.pastEvents);
    const upcomingEvents = [].concat(lastDayEvents, events.upcomingEvents);
    this.setState({
      upcomingEvents,
      pastEvents
    }, cb);
  }

  componentDidMount () {
    this.setCurrentPosition();
    this.timer = setInterval(() => {
      this.setCurrentPosition(() => {
        this.forceUpdate();
      });
    }, 60000);
  }

  componentDidUpdate () {
    this.setCurrentPosition();
  }

  componentWillUnmount () {
    if (this.timer) clearInterval(this.timer);
  }

  render () {
    const { l } = this.context.i18n;
    const { loading, travelerId, match: { params: { id } } } = this.props;
    const upcomingEvents = this.state && this.state.upcomingEvents;
    const pastEvents = this.state && this.state.pastEvents;

    let timelineContainer;
    if (upcomingEvents && upcomingEvents.length || pastEvents && pastEvents.length) {
      timelineContainer = (
        <TimelineEventsList {...this.props} {...this.state} />
      );
    } else if (upcomingEvents && !upcomingEvents.length && !loading) {
      timelineContainer = (<div className='load-events'><span>{l('No timeline events found')}</span></div>);
    }

    return (
      <div>
        <LoadingIcon loading={loading} />
        {travelerId === id && timelineContainer}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimelineContainer);
