import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import TravelAdvisory from '../../components/travelAdvisory/travelAdvisory';
import './TravelAdvisoryView.scss';
import { stateAppSettingsDimensions } from '../../redux/modules/appSettings';
import {
  actions as travelAdvisoryActions,
  stateCountries,
  stateFilteredCountries,
  stateLoading,
  stateActiveCountry,
  stateCountryTravelers
} from '../../redux/modules/travelAdvisory';
import {
  stateErrorMessage, stateFormLoading, stateOrganization, stateSortLevelIds,
  stateUser
} from '../../redux/modules/userManager';


import { withRouter } from 'react-router-dom';
import { stateUser as stateCurrentUser } from '../../redux/modules/user';

const mapStateToProps = createSelector(
  stateAppSettingsDimensions,
  stateCountries,
  stateFilteredCountries,
  stateLoading,
  stateActiveCountry,
  stateCountryTravelers,
  stateCurrentUser,
  (appSettingsDimensions, countries, filteredCountries, countriesLoading, activeCountry, countryTravelers,currentUser) => {
    return {
      appSettingsDimensions,
      countries,
      countryTravelers,
      filteredCountries: filteredCountries,
      countriesLoading,
      activeCountry,
      currentUser,
    };
  }
);

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(travelAdvisoryActions, dispatch)
  };
};

class TravelAdvisoryView extends React.Component {
  static propTypes = {
    appSettingsDimensions: PropTypes.object.isRequired,
    countries: PropTypes.array.isRequired,
    filteredCountries: PropTypes.array.isRequired,
    activeCountry: PropTypes.object,
    loadCountries: PropTypes.func.isRequired,
    filterCountries: PropTypes.func.isRequired,
    setActiveCountry: PropTypes.func.isRequired
  };

  state = {
    loading: true
  }

  componentDidMount () {
    this.props.loadCountries().then(() => {
      this.setState( (state,props) => {
        let country = props.match.params.country;
        if (country) {
          console.log(props.countries);
          let activeCountry = props.countries.find( (filteredCountry) => filteredCountry.longCode === country);
          console.log(activeCountry);
          props.setActiveCountry(activeCountry);
        }
        return { loading: false };
      });
    }).catch(() => {
      this.setState({ loading: false });
    });
  }

  render () {
    return (
         <div>
        <TravelAdvisory {...this.state} {...this.props} />
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TravelAdvisoryView));
