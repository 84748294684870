/* eslint-disable max-len */
import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export default function CompanyIcon (props) {
  return (
    <SvgIcon {...props}>
        <path d="M15.4952727,12.1462088 L15.1671919,12.1462088 L0.832808081,12.1462088 L0.504727273,12.1462088 C0.225993266,12.1462088 0,12.372202 0,12.650936 C0,12.92967 0.225993266,13.1556633 0.504727273,13.1556633 L1.33753535,13.1556633 L14.6624646,13.1556633 L15.4952727,13.1556633 C15.7740067,13.1556633 16,12.92967 16,12.650936 C16,12.372202 15.7740067,12.1462088 15.4952727,12.1462088 Z" id="Path"></path>
        <path d="M1.77082828,5.48374411 L1.77082828,10.4944916 L1.44824242,10.4944916 C1.17414141,10.4944916 0.951919192,10.7167138 0.951919192,10.9908148 L0.951919192,11.3134007 L15.0472189,11.3134007 L15.0472189,10.9908148 C15.0472189,10.7167138 14.8249966,10.4944916 14.5508956,10.4944916 L14.2283636,10.4944916 L14.2283636,5.48374411 L11.5978451,5.48374411 L11.5978451,10.4944916 L9.31485522,10.4944916 L9.31485522,5.48374411 L6.68428283,5.48374411 L6.68428283,10.4944916 L4.40129293,10.4944916 L4.40129293,5.48374411 L1.77082828,5.48374411 Z" id="Path"></path>
        <path d="M1.00110438,4.65093603 L14.9979798,4.65093603 C14.998303,4.65088215 14.9985724,4.65088215 14.9989495,4.65093603 C15.2731044,4.65093603 15.4952727,4.4287138 15.4952727,4.15461279 C15.4952727,3.95415488 15.376431,3.78138721 15.2053333,3.70305724 L8.22739394,0.0983164983 C8.08452525,0.0245656566 7.91466667,0.0245656566 7.77179798,0.0983164983 L0.773279461,3.71367003 C0.569858586,3.81877441 0.464754209,4.0493468 0.518841751,4.27183838 C0.572875421,4.49432997 0.772094276,4.65093603 1.00110438,4.65093603 Z" id="Path"></path>
    </SvgIcon>
  );
};
