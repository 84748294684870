import PropTypes from 'prop-types';
import React from 'react';


const toggleHide =  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD8AAAA/CAYAAABXXxDfAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAuIwAALiMBeKU/dgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAATKSURBVGiB7ZtNbBtFGIbfb9Y2LghEWiApPwWp7sZhc4uobZRCLxxIW1BSpeodxAEkjqhpRS+hKiCuRQIuPRNAoJLSA4eIJnESAUJq3diJ01gl0BbapFS0ib0/H4cklUk8G8fdHzvOe/N83+y8j3e8MzueIdiotTXWWCjQASLeB1AzgEYAW+3q+KxZANcBzjBTfyjEZy5eHL0uS6ZShZqW2GoY/B6AdwFsccmoFyow02nTpPenpob/WhlcBb9rVzwmBL4F0OSJPU9EVy2LOycnR0aLS0XxB1VNdAqBAWwocADg7UJgQFUTncWl9+58NBprY6afADzouTfvtGBZ2LvcAwi49xtPYcPd8ZL6MxQKtF64MDgnAEDX+SjqAxwAniwUjCMAQK2tsUZdpxyAsDyfk0R0UlFoKJVKznrjcf1a6sHtzOghQtwmdcEwxLOkqvE3ifCFPI8/y2R2vAP0mY67dU3diqrOfErEb8lz6A0hBDrkCZysPXAA6DMnJp5+mxkj8hzuEMyIysJEdLL2wJfVZxLxh7IoEVoEbB50ikJDrvjySEQ8KIsxo0kAeFSWkEol51xx5ZHS6TG7h3ODgGR+vyR22I/XsvNPwia44bUJX6/ahK9XbcLXqzbh61Wb8PWqgN8GACASefUBRbl1jMh6mZnOm2ZDbzb7Q97tdn2HXwSf+wZABzMBwEuKcssCcNzttn3t9sXg/4/wHi/a9w1eDg4wY8ALDxXDa1oi0tLStr2SunbgAM5aVoN0+clJVQIvVDX+tWHwpGUFZ1Q1dmQ9ldcCN82GLi8edkAF8NFo4hUidC3XJ6IPNC1R1t/W1QQOVABvWbxjRZGi6+Yza9WrNnCggqFOCKalIalsaZoWMoy5PpQGP5fPhw/mct6CAx487RfBH/4KwIES4XP5fLgzlxtYcNtHKbkKX83ggIvw1Q4OuARfC+CAC/C1Ag44DF9L4ICD8LUGDjgGH6g5cMCh93khrNPMeL5E6KxpNnT5MYEpR47AS8B9m7mVK7fG+art6sVyYxkrTUS94fC8Fo3GbBMVRUz7ubvLDfgoMw/Z73lYlGGwpaqxYxMTo54sXqyU30vXgohOaNoLvmyArOB9Hred9pDPBx9y+JrlNbzeCsHglu8BjDvo4ctsdnjKweuVrQAWN+3IfqCEFZt6UqmBfzVt725dn98vBB65n8aJMD0+Pvrj/VxjrSZsYkzNzfGbkBwdCQRoWzXvtV1Lqtr2GFHwb0l4VgCQnkExDG53x5ZXCkn9E+GaAHBJlsCMHqBbccWX6+pWhGDpsjozXRIA98sSiBBvbr5yqva+gMVd18yQzrKIuJ8ikT2PK4qeg+3xEk4C+IiIB9PpsZuOe3VI0ejubczUTkQ9duAA7jDrzxEAqGr8BBGOeuSxCkS9mUzyuAAAw9A/BvC7z4680hXTtD4BliY5ly//8o9lidcA3PHVlvuaB6yD2ezobaBohjc5OfwbER0GcNc3a+7qLhEdymTGfl4uWDUDWjpf9x2Apzy15q5mhMDr4+MjvxYXrhrCbtz442pTU+RzZkMHEAMQ9MqhCyoAOJXPhw9ls4PTK4O2L907d774RCDA+5l5nxCIMqMJVX6KmgjXLAtpIuo3zcCZbPa8bHqL/wC6ovzj0Jr8owAAAABJRU5ErkJggg==";


const LegendRow = props => {
  return (
    <div  className='legend-row'>
      {props.from === "airports" && (
        <input  type="checkbox" />
      )}
      <span style={{backgroundColor:props.data.color}}></span>
      {props.data.text}
    </div>
  );
};

const ta = [
  {
    id: 'ta1',
    text: "Do Not Travel",
    color: "#D1482F",
    fliter: false
  },
  {
    id: 'ta2',
    text: "Reconsider Travel",
    color: "#DD8109",
    fliter: false
  },
  {
    id: 'ta3',
    text: "Exercise Increased Caution",
    color: "#EDD21D",
    fliter: false
  },
  {
    id: 'ta4',
    text: "Exercise Normal Precautions",
    color: "#154690",
    fliter: false
  }
];

const locations = [
    // {
    //   id: 'location1',
    //   text: "Multiple Travelers",
    //   color: "#1D4A93"
    // },
    {
      id: 'location2',
      text: "Canceled / Delayed > 45 mins",
      color: "#F25050"
    },
    {
      id: 'location3',
      text: "Delayed 15 - 45 mins",
      color: "#FFB803"
    },
    {
      id: 'location4',
      text: "On Time / Delayed < 15 mins",
      color: "#4BC07A"
    }
  ];

const airports = [
  {
    id: 'airport1',
    text: "Airport Closed",
    color: "#000000",

  },
  {
    id: 'airport2',
    text: "Canceled / Delayed > 45 mins",
    color: "#F25050",

  },
  {
    id: 'airport3',
    text: "Delayed 15 - 45 mins",
    color: "#FFB803",
    // fliter: false
  },
  {
    id: 'airport4',
    text: "On Time / Delayed < 15 mins",
    color: "#4BC07A",
    // fliter: false
  }
]

const flightArcs = [
  {
    id: 'flightarc1',
    text: "Canceled / Delayed > 45 mins",
    color: "#F25050"
  },
  {
    id: 'flightarc2',
    text: "Delayed 15 - 45 mins",
    color: "#FFB803"
  },
  {
    id: 'flightarc3',
    text: "On Time / Delayed < 15 mins",
    color: "#4BC07A"
  }
]



export default class MapLevels extends React.Component {
  static contextTypes = {
    i18n: PropTypes.object
  };

  constructor (props) {
    super(props);
    this.state = {
      legend: true,

    };
  }


  render () {
    return (
      <>
          <div
            id="county-legend"
            className="legend">
            <h4 className='legend-heading'>Legend</h4>
            <img
              src={toggleHide}
              className={this.state.legend ? "legend-head-img" : "legend-head-img2" }
              onClick={() => {
                this.setState({
                  legend:!this.state.legend
                });
                // this.clearActiveTravelerMap("flightarcs");
              }}
            />
              {(this.props.from == "flightsStatus" || this.props.from == "airportStatus") && this.state.legend?<h4 className='legend-subheading pdgt'>Status</h4>:''}
            <div >
              {this.props.from === "taMap" && (this.state.legend ? (ta.map(ta => (<LegendRow from="ta" data={ta} key={ta.id}/>))) : "")}
              {this.props.from === "flightsStatus" && (this.state.legend ? (flightArcs.map(ta => (<LegendRow from="flightsStatus" data={ta} key={ta.id}/>))) : "")}

              {this.props.from === "airportStatus" && (this.state.legend ? (airports.map(ta => (<LegendRow from="airportStatus" data={ta} key={ta.id}/>))) : "")}

              {this.props.from === "locations" && (this.state.legend ? (locations.map(ta => (<LegendRow from="locations" data={ta} key={ta.id}/>))) : "")}

            </div>

          </div>
      </>
    );
  }
}

